<template>
    <div class="uppercase font-semibold tracking-wider text-grey-500 ml-8 mb-2" v-text="content" />
</template>

<script>
    export default {
        props: {
            content: {
                type: String,
                required: true,
            },
        },
    };
</script>
