<template>
    <button
        class="flex items-stretch gap-3 border border-gray-200 shadow-md rounded-sm | transition"
        :class="{ 'hover:shadow-xl' : !disabled }"
        :disabled="disabled"
        @click="$emit('click')"
    >
        <div class="flex items-center px-3 rounded-l-sm bg-gray-200">
            <activix-radio :disabled="disabled" :checked="value" />
        </div>

        <div class="flex-1 flex flex-col py-6 pl-3 pr-6">
            <div class="flex items-center gap-1.5 font-semibold text-grey-650 text-xl mb-4">
                <slot name="title" />
            </div>
            <slot />
        </div>
    </button>
</template>

<script>
    export default {
        name: 'ActivixRichRadio',

        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            value: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
