export default {
    all: 'Tous',
    finance: 'Crédit',
    followUp: 'Suivi',
    general: 'Général',
    new: 'Neuf',
    none: 'Aucune',
    parts: 'Pièces',
    reception: 'Réception',
    revival: 'Relance',
    service: 'Service',
    used: 'Occasion',
    bdc: 'BDC',
};
