<template>
    <div class="w-full h-full flex flex-col justify-between relative">
        <div class="overflow-y-auto overscroll-contain px-6 pt-8 pb-6">
            <div class="flex relative">
                <transition
                    enter-active-class="transition duration-300"
                    enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition duration-300"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <div class="flex items-center absolute left-0 h-full" v-if="originalLead.id">
                        <activix-tooltip :content="$t('addLead.returnInitialLead', { lead: originalLead.name })">
                            <button class="flex items-center link-grey-dark" @click="navigateToLead(originalLead.id)">
                                <icon class="text-sm flip-x" name="regular/arrow-right" />
                            </button>
                        </activix-tooltip>
                    </div>
                </transition>

                <div class="flex items-center mx-auto relative">
                    <h3 class="m-0 font-semibold text-grey-650 text-xl w-full text-center | md:text-base | xl:text-lg | 2xl:text-xl" v-text="$t('addLead.leadsInterDealer')" />
                    <activix-tooltip :content="$t('addLead.leadsInterDealerTooltip')">
                        <icon class="absolute -right-5 text-grey-500 text-sm block | md:hidden | xl:block" name="regular/information-circle" />
                    </activix-tooltip>
                </div>
                <div class="absolute right-0 flex items-center h-full">
                    <activix-tooltip :content="$t('addLead.resetSearch')">
                        <icon
                            class="link-grey text-sm"
                            :name="$icons.loading"
                            :class="{ 'spin-inverse': $wait.is('reset.search') }"
                            @click="resetLocalFilters"
                        />
                    </activix-tooltip>
                    <icon class="link-grey text-xs ml-2" name="bold/remove" @click="hideRightSidebar()" />
                </div>
            </div>

            <inter-account-lead-form
                class="mt-8"
                :account-options="childAccounts"
                ref="interAccountLeadForm"
                @search="searchLeads"
                @update-filters="updateFilters"
            />

            <inter-account-leads-result-list
                :leads="filteredLeads"
                :loading="loading"
                @navigate-to-lead="navigateToLead($event)"
            />

            <activix-button
                class="mt-6 py-3 w-full"
                type="white"
                size="medium"
                :loading="loading"
                @click="nextPage"
                v-if="showMoreButton"
            >
                {{ $t('addLead.showMore') }}
            </activix-button>
            <div
                class="text-center mt-6 text-gray-600"
                v-text="$t('addLead.noMoreResults')"
                v-else-if="noMoreResults"
            />
        </div>
    </div>
</template>

<script>
    import InterAccountLeadForm from '@/components/inter_account_leads/InterAccountLeadsForm.vue';
    import InterAccountLeadsResultList from '@/components/inter_account_leads/InterAccountLeadsResultList.vue';
    import { mapActions, mapState } from 'pinia';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useInterAccountLeadsStore } from '../../store/modules/interAccountLeads/store.js';

    export default {
        components: {
            InterAccountLeadForm,
            InterAccountLeadsResultList,
        },

        data() {
            return {
                filters: {},
                replaceRouterHistory: false,
                page: 1,
                perPage: 25,
                originalLead: {},
            };
        },

        computed: {
            ...mapState(useInterAccountLeadsStore, [
                'leads',
                'loading',
                'siblingAccounts',
            ]),
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
            }),
            ...mapState(useGlobalStore, ['authUser', 'parentAuthUser']),

            filteredLeads() {
                return this.leads.filter(lead => {
                    return lead.account_id !== this.originalLead.accountId;
                });
            },

            showMoreButton() {
                return this.filteredLeads.length >= (this.page * this.perPage) && !!this.filteredLeads;
            },

            noMoreResults() {
                return !this.loading && this.filteredLeads.length && this.page !== 1;
            },

            childAccounts() {
                return this.siblingAccounts
                    .filter(child => child.active && child.id !== this.originalLead.account_id)
                    .sort((a, b) => a.name.localeCompare(b.name));
            },
        },

        watch: {
            '$route.name'() {
                if (this.$route.name !== 'leads.update') {
                    this.hideRightSidebar();
                }
            },

            'contextLead.id'() {
                if (this.contextLead.customer_id === this.originalLead.customer_id) {
                    this.searchLeads(true);
                }
            },
        },

        methods: {
            ...mapActions(useInterAccountLeadsStore, ['fetchInterAccountLeads']),

            nextPage() {
                this.page++;
                this.searchLeads(false);
            },

            updateFilters(value) {
                this.page = 1;
                this.filters = value;
            },

            setOriginalLead(leadId) {
                if (!this.originalLead.id) {
                    this.originalLead = {
                        id: this.contextLead.id,
                        name: this.contextLead.fullName,
                        account_id: this.contextLead.account_id,
                        customer_id: this.contextLead.customer_id,
                    };
                }

                if (this.originalLead.id === leadId) {
                    this.originalLead = {};
                }
            },

            async navigateToLead(leadId) {
                this.setOriginalLead(leadId);

                const route = {
                    name: 'leads.update',
                    params: { id: leadId },
                };

                this.handleRouterHistory(route);

                await this.$nextTick();
            },

            handleRouterHistory(route, forcePush = false) {
                if (this.replaceRouterHistory && !forcePush) {
                    this.$router.replace(route);
                } else {
                    this.replaceRouterHistory = true;
                    this.$router.push(route);
                }
            },

            resetLocalFilters() {
                this.$refs.interAccountLeadForm.$reset();
                this.resetSearch();
            },

            resetSearch() {
                this.$reset();
                this.$refs.interAccountLeadForm.$reset();
                this.searchLeads();
            },

            async searchLeads(clear = true) {
                const divisionIds = this.filters.division ? this.filters.division.map(division => division.id) : [];
                const accountIds = this.filters.account ? this.filters.account.map(account => account.id) : [];

                try {
                    await this.fetchInterAccountLeads({
                        page: this.page,
                        per_page: this.perPage,
                        division_ids: divisionIds,
                        account_ids: accountIds,
                        lead_id: this.originalLead.id || this.contextLead.id,
                        clear,
                    });
                } catch (error) {
                    this.$notify.error(this.$t('addLead.errorDuringSearch'));
                    throw error;
                }
            },

            hideRightSidebar() {
                this.$eventBus.$emit('hide-right-sidebar');
            },
        },

        created() {
            window.onpopstate = () => {
                this.replaceRouterHistory = false;
            };
        },

        mounted() {
            if (!this.filteredLeads.length) {
                this.searchLeads();
            }
        },

        beforeDestroy() {
            if (this.$route.name !== 'leads.update') {
                useInterAccountLeadsStore().leads = [];
                return;
            }

            if (this.originalLead.id && this.contextLead.id !== this.originalLead.id) {
                this.fetchInterAccountLeads({ lead_id: this.contextLead.id });
            }
        },
    };
</script>
