<template>
    <div>
        <div :key="item.id" v-for="item in formattedItems">
            <component
                :is="tag"
                :disabled="disabled"
                :name="name"
                :value="item.selected"
                :checked="item.selected"
                v-bind="$attrs"
                @input="onInput(item, $event)"
            >
                <template #before>
                    <div class="flex items-center">
                        <icon class="mr-2" :name="item.icon" />
                        <span v-text="item.label" />
                        <span class="ml-1" v-if="item.extension">#{{ item.extension }}</span>
                    </div>
                </template>
            </component>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: 'radio',
                validator: value => ['radio', 'checkbox'].includes(value),
            },
            items: {
                type: Array,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            value: {
                type: null,
                required: true,
            },
        },

        computed: {
            formattedItems() {
                return this.items.map(item => ({
                    ...item,
                    icon: this.getIcon(item),
                    selected: this.isSelected(item),
                }));
            },

            tag() {
                return {
                    radio: 'activix-radio',
                    checkbox: 'activix-checkbox',
                }[this.type];
            },
        },

        methods: {
            getIcon(item) {
                return {
                    home: 'regular/house-chimney-2',
                    work: 'regular/briefcase',
                    cell: 'regular/mobile-phone',
                }[item.type];
            },

            isSelected(item) {
                if (this.type === 'radio') {
                    return this.value === item.id;
                }

                return this.value.some(value => item.id == value.id);
            },

            onInput(item, selected) {
                const currentItem = this.items.find(i => i.id === item.id);

                if (this.type === 'radio') {
                    this.$emit('input', currentItem.id);
                    return;
                }

                const currentValue = [...this.value];

                if (selected) {
                    currentValue.push(currentItem);
                } else {
                    currentValue.splice(currentValue.indexOf(currentItem), 1);
                }

                this.$emit('input', currentValue);
            },
        },
    };
</script>
