export default {
    account: {
        singular: 'account',
    },
    civility: {
        singular: 'civility',
    },
    presented: {
        singular: 'visit',
    },
    province: {
        CA: 'province',
    },
    renewal: {
        singular: 'renewal',
        plural: 'renewals',
        prefix: 'a renewal',
        abr: 'renewal',
    },
    renewed: {
        infinitive: 'renew',
        singular: 'renewed',
        plural: 'renewed',
    },
    result: {
        singular: 'result',
    },
    sale: {
        singular: 'sale',
        plural: 'sales',
        prefix: 'a sale',
    },
    sold: {
        singular: 'sold',
        plural: 'sold',
    },
    version: {
        singular: 'version',
    },
    walkIn: {
        singular: 'walk-in',
        plural: 'walk-in',
        prefix: 'a walk-in',
        abr: 'walk-in',
    },
};
