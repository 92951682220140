<template>
    <section class="flex flex-col">
        <portal-target name="content-header-prepend" />
        <div class="flex justify-between">
            <div class="flex items-center mb-6 min-w-0 ">
                <portal-target name="content-header" slim>
                    <h1
                        class="m-0 text-2xl font-semibold text-grey-650"
                        :class="{ 'ml-4': lgLayout }"
                        v-text="pageTitle"
                    />
                </portal-target>
                <portal-target class="flex items-center" name="content-header-left" />
            </div>
            <portal-target class="mb-6" name="content-header-right" />
        </div>
    </section>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '../store/store.js';

    export default {
        computed: {
            ...mapState(useGlobalStore, ['pageTitle']),
        },

        metaInfo: {
            changed({ titleChunk }) {
                useGlobalStore().setPageTitle(titleChunk);
            },
        },
    };
</script>
