<template>
    <component :is="svgFile" v-if="name" />
</template>

<script>
/* eslint-disable */
export default {
    name: 'ActivixIllustrations',

    props: {
        name: {
            type: String,
            required: true,
        },
    },

    computed: {
        svgFile() {
            if (!this.name) {
                return '';
            }

            // We could also use "illustrations/[request]" to have seperate chunks for each SVG.
            return () => import(/* webpackMode: "lazy-once", webpackChunkName: "illustrations" */ '@/assets/svg/illustrations/' + this.name + '.svg');
        },
    },
};
</script>
