<template>
    <div class="space-y-6 | xs:flex xs:space-x-6 xs:space-y-0">
        <div class="flex-1">
            <label>{{ $t('responseLeadXpress.sender') }}</label>
            <activix-select
                class="w-full"
                :value="from"
                identifier="value"
                label="text"
                :options="mobileOptions"
                @input="$emit('update:from', $event)"
            >
                <el-option-group>
                    <el-option
                        :label="`${currentUser.fullName} (${$t('responseLeadXpress.myself')})`"
                        :value="currentUser.id"
                        :key="currentUser.id"
                    />
                </el-option-group>
                <el-option-group :label="$t('responseLeadXpress.relativeFields')" v-if="isMassMailing">
                    <el-option
                        :label="relativeOwner.text"
                        :value="relativeOwner.value"
                        :key="index"
                        v-for="(relativeOwner, index) in relativeOptionsMassMailing"
                    />
                </el-option-group>
                <el-option-group :label="$t('responseLeadXpress.relativeFields')" v-else-if="!!relativeOptionsSimpleMailing.length">
                    <el-option
                        :label="option.text"
                        :value="option.value"
                        :key="index"
                        v-for="(option, index) in relativeOptionsSimpleMailing"
                    />
                </el-option-group>
                <el-option-group :label="$t('responseLeadXpress.specificUser')" v-if="displayUserOptions">
                    <el-option
                        :label="option.text"
                        :value="option.value"
                        :key="index"
                        v-for="(option, index) in userOptions"
                    />
                </el-option-group>
            </activix-select>
        </div>
        <div class="flex-1">
            <label>{{ $t('responseLeadXpress.senderBackup') }} </label>
            <activix-select
                class="w-full"
                :value="fromBackup"
                :disabled="!displayFromBackup"
                identifier="value"
                label="text"
                :options="mobileBackupOptions"
                @input="$emit('update:fromBackup', $event)"
            >
                <el-option
                    :label="`${currentUser.fullName} (${$t('responseLeadXpress.myself')})`"
                    :value="currentUser.id"
                    :key="currentUser.id"
                />
                <template v-if="displayUserOptions">
                    <el-option
                        :label="option.text"
                        :value="option.value"
                        :key="option.value"
                        v-for="option in userOptions"
                    />
                </template>
            </activix-select>
        </div>
    </div>
</template>

<script>
    import { concat } from 'lodash-es';
    import User from '@/entities/User.js';

    import { mapState } from 'pinia';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        props: {
            from: [String, Number],
            fromBackup: [String, Number],
            lead: Object,
        },

        data() {
            return {
                sendAs: [],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useDashboardStore, ['isMassMailing']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            authUserOption() {
                return [
                    {
                        value: this.currentUser.id,
                        text: `${this.currentUser.fullName} (${this.$t('responseLeadXpress.myself')})`,
                    },
                ];
            },

            mobileOptions() {
                if (this.isMassMailing) {
                    if (this.displayUserOptions) {
                        return concat(this.authUserOption, this.relativeOptionsMassMailing, this.userOptions);
                    }

                    return concat(this.authUserOption, this.relativeOptionsMassMailing);
                }

                if (this.displayUserOptions) {
                    return concat(this.authUserOption, this.relativeOptionsSimpleMailing, this.userOptions);
                }

                return concat(this.authUserOption, this.relativeOptionsSimpleMailing);
            },

            mobileBackupOptions() {
                return concat(this.authUserOption, this.userOptions);
            },

            userOptions() {
                return this.currentAccount.activeUsers.filter(
                    user => !([this.currentUser.id, this.lead.commercial_id, this.lead.service_agent_id, this.lead.bdc_user_id, this.lead.user_id].includes(user.id)),
                ).map(user => (
                    {
                        value: user.id,
                        text: user.fullName,
                    }
                ));
            },

            relativeOptionsMassMailing() {
                return [
                    {
                        value: 'user_id',
                        text: this.$t('responseLeadXpress.leadRelatedUser.user'),
                    },
                    {
                        value: 'bdc_user_id',
                        text: this.$t('responseLeadXpress.leadRelatedUser.bdcUser'),
                    },
                    {
                        value: 'commercial_id',
                        text: this.$t('responseLeadXpress.leadRelatedUser.commercial'),
                    },
                    {
                        value: 'service_agent_id',
                        text: this.$t('responseLeadXpress.leadRelatedUser.serviceAgentUser'),
                    },
                    {
                        value: 'service_advisor_id',
                        text: this.$t('responseLeadXpress.leadRelatedUser.serviceAdvisorUser'),
                    },
                ];
            },

            relativeOptionsSimpleMailing() {
                const options = [];

                if (this.lead.user_id) {
                    options.push({
                        value: this.lead.user_id,
                        text: `${this.$t('responseLeadXpress.leadRelatedUser.user')} (${this.getFullNameById(this.lead.user_id)})`,
                    });
                }

                if (this.lead.bdc_user_id) {
                    options.push({
                        value: this.lead.bdc_user_id,
                        text: `${this.$t('responseLeadXpress.leadRelatedUser.bdcUser')} (${this.getFullNameById(this.lead.bdc_user_id)})`,
                    });
                }

                if (this.lead.commercial_id) {
                    options.push({
                        value: this.lead.commercial_id,
                        text: `${this.$t('responseLeadXpress.leadRelatedUser.commercial')} (${this.getFullNameById(this.lead.commercial_id)})`,
                    });
                }

                if (this.lead.service_agent_id) {
                    options.push({
                        value: this.lead.service_agent_id,
                        text: `${this.$t('responseLeadXpress.leadRelatedUser.serviceAgentUser')} (${this.getFullNameById(this.lead.service_agent_id)})`,
                    });
                }

                if (this.lead.service_advisor_id) {
                    options.push({
                        value: this.lead.service_advisor_id,
                        text: `${this.$t('responseLeadXpress.leadRelatedUser.serviceAdvisorUser')} (${this.getFullNameById(this.lead.service_advisor_id)})`,
                    });
                }

                return options;
            },

            displayFromBackup() {
                if (this.isMassMailing) {
                    return !Number.isInteger(this.from);
                }

                return false;
            },

            displayUserOptions() {
                return this.currentUser.isAdmin() ||
                    this.currentUser.isDirector() ||
                    this.currentUser.isBdcDirector() ||
                    this.currentUser.isSuperBdc();
            },

            parentAuthUser() {
                return this.$auth.user();
            },

            currentAccount() {
                return this.lead.account_id ? this.lead.account : this.contextAccount;
            },

            currentUser() {
                const authUser = (this.parentAuthUser.children || []).find(
                    child => child.account_id == this.currentAccount.id,
                );

                return authUser ? new User(authUser) : this.authUser;
            },
        },

        methods: {
            getFullNameById(userId) {
                return this.currentAccount.getUserById(userId)?.fullName;
            },
        },
    };
</script>
