import Vue from 'vue';
import optimizely from '@optimizely/optimizely-sdk';
import { get } from 'lodash-es';
import { globalStore } from '@/pinia/storeHelper.js';
import Role from '../entities/Role.js';

optimizely.setLogLevel('warn');

Vue.use({
    install(VueInstance) {
        if (this.installed) {
            return;
        }

        this.installed = true;

        let optimizelyInstance;

        if (window.optimizelyDatafile) {
            optimizelyInstance = optimizely.createInstance({
                datafile: window.optimizelyDatafile,
            });
        }

        const feature = {
            isInitialized: false,
            user: null,

            getAuthUser() {
                return Vue.auth.user();
            },

            getOptimizelyUserId(userId) {
                return `user_${userId}`;
            },

            validate(userId) {
                return !!userId && !!optimizelyInstance;
            },

            isEnabled(feature, defaultValue = true) {
                if (this.hasUrlOverride(feature)) {
                    return this.isForced(feature);
                }

                if (this.featureExist(feature)) {
                    return this.isEnabledForUser(this.getAuthUser(), feature);
                }

                return defaultValue && this.getFeaturesList().length !== 0;
            },

            isEnabledForUser(user, feature) {
                if (!this.validate(user.id)) {
                    return false;
                }

                if (this.isPreviewFeature(feature)) {
                    return this.isPreviewEnabledForUser(feature);
                }

                return optimizelyInstance.isFeatureEnabled(feature, this.getOptimizelyUserId(user.id), {
                    id: user.id,
                    account_id: user.account_id,
                    role: Role.getKey(user.role_id),
                    environment: process.env.VUE_APP_ENV,
                });
            },

            isPreviewEnabledForUser(feature) {
                const featurePreviewData = Vue.auth.featurePreviews().find(featurePreview => featurePreview.key === feature);

                return !!featurePreviewData?.activated_at;
            },

            isPreviewFeature(feature) {
                return globalStore().featurePreviews.some(featurePreview => featurePreview.key === feature);
            },

            hasUrlOverride(feature) {
                return !['production'].includes(process.env.VUE_APP_ENV) && get(Vue.router, `app.$route.query.${feature}`);
            },

            isForced(feature) {
                return get(Vue.router, `app.$route.query.${feature}`) === 'true';
            },

            featureExist(feature) {
                return this.getFeaturesList().some((featureFlag) => {
                    return featureFlag.key === feature;
                });
            },

            getFeaturesList() {
                const authUserId = this.getAuthUser().id;

                if (!this.validate(authUserId)) {
                    return [];
                }

                return get(window, 'optimizelyDatafile.featureFlags', []);
            },

            getEnabled() {
                const authUserId = this.getAuthUser().id;
                if (!this.validate(authUserId)) {
                    return [];
                }

                return optimizelyInstance.getEnabledFeatures(this.getOptimizelyUserId(authUserId));
            },
        };

        VueInstance.prototype.$feature = feature;
        VueInstance.feature = feature;
    },
});
