<template>
    <activix-select
        class="nav-select"
        identifier="value"
        :autosize="!mdLayout"
        :default-first-option="true"
        :filterable="true"
        :filter-method="setFilterValue"
        :has-input-look="false"
        :min-width="!mdLayout ? 150 : null"
        :options="options"
        :placeholder="$t('multiselect.selectOption')"
        @mouseenter.native="render = true"
        @visible-change="setFilterValue('')"
        v-model="groupAccountId"
    >
        <template v-if="render">
            <el-option-group :label="group.label" :key="group.label" v-for="group in groupAccounts">
                <el-option
                    :label="item.label"
                    :value="item.value"
                    :key="item.value"
                    v-for="item in group.options.filter(filterOptions)"
                />
            </el-option-group>
        </template>
        <template v-else-if="selectedOption">
            <el-option :label="selectedOption.label" :value="selectedOption.value" />
        </template>
    </activix-select>
</template>

<script>
    import { get, cloneDeep, isEmpty } from 'lodash-es';
    import { latinize } from '@/utils/string.js';
    import { mapActions, mapState } from 'pinia';

    // Pinia
    import { useContextStore } from '../store/modules/context/store.js';
    import { useUserCardStore } from '../store/modules/userCard/store.js';
    import { useGlobalStore } from '../store/store.js';

    export default {
        props: {
            activeAccounts: {
                type: Array,
                default: () => [],
            },
            childAccounts: {
                type: Array,
                default: () => [],
            },
            childUsers: {
                type: Array,
                default: () => [],
            },
            groups: {
                type: Array,
                default: () => [],
            },
            inactiveAccounts: {
                type: Array,
                default: () => [],
            },
            parentAccounts: {
                type: Array,
                default: () => [],
            },
            parentUser: {
                type: Object,
                default: () => ({}),
            },
        },

        data() {
            return {
                filterValue: '',
                groupAccountId: '',
                render: false,
            };
        },

        computed: {
            ...mapState(useUserCardStore, {
                currentEditedUser: 'currentForm',
            }),
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextLead: 'contextLead',
                contextAccount: 'account',
                contextGroup: 'group',
            }),

            showGroups() {
                return !isEmpty(this.groups) && get(this.$route.meta, 'accountSelect.showGroups', false);
            },

            showActiveAccounts() {
                return !isEmpty(this.activeAccounts) && get(this.$route.meta, 'accountSelect.showActiveAccounts', true);
            },

            showInactiveAccounts() {
                return (
                    !isEmpty(this.inactiveAccounts) && get(this.$route.meta, 'accountSelect.showInactiveAccounts', true)
                );
            },

            showParentAccounts() {
                return !isEmpty(this.parentAccounts) && get(this.$route.meta, 'accountSelect.showParentAccounts', true);
            },

            showChildAccounts() {
                return !isEmpty(this.childAccounts);
            },

            groupAccounts() {
                const groupAccounts = [];

                if (this.showGroups) {
                    groupAccounts.push({
                        label: this.$t('general.groups'),
                        options: this.groups.map(group => {
                            return {
                                label: group.name,
                                value: `G${group.id}`,
                            };
                        }),
                    });
                }

                if (this.showParentAccounts) {
                    groupAccounts.push({
                        label: this.$t('general.parentAccounts'),
                        options: this.parentAccounts.map(account => {
                            return {
                                label: account.name,
                                value: account.id.toString(),
                            };
                        }),
                    });
                }

                if (this.showChildAccounts) {
                    groupAccounts.push({
                        label: this.$t('general.childAccounts'),
                        options: this.childAccounts.map(account => {
                            return {
                                label: account.name,
                                value: account.id.toString(),
                            };
                        }),
                    });
                }

                if (this.showActiveAccounts) {
                    groupAccounts.push({
                        label: this.$t('general.activeAccount'),
                        options: this.activeAccounts.map(account => {
                            return {
                                label: account.name,
                                value: account.id.toString(),
                            };
                        }),
                    });
                }

                if (this.showInactiveAccounts) {
                    groupAccounts.push({
                        label: this.$t('general.inactiveAccount'),
                        options: this.inactiveAccounts.map(account => {
                            return {
                                label: account.name,
                                value: account.id.toString(),
                            };
                        }),
                    });
                }

                return groupAccounts;
            },

            options() {
                const groupAccounts = [];

                if (this.showGroups) {
                    groupAccounts.push({
                        label: this.$t('general.groups'),
                        value: `S_${this.$t('general.groups')}`,
                        isSeparator: true,
                    });

                    this.groups
                        .map(group => {
                            return {
                                label: group.name,
                                value: `G${group.id}`,
                            };
                        })
                        .forEach(group => {
                            groupAccounts.push(group);
                        });
                }

                if (this.showParentAccounts) {
                    groupAccounts.push({
                        label: this.$t('general.parentAccounts'),
                        value: `S_${this.$t('general.parentAccounts')}`,
                        isSeparator: true,
                    });

                    this.parentAccounts
                        .map(account => {
                            return {
                                label: account.name,
                                value: account.id.toString(),
                            };
                        })
                        .forEach(account => {
                            groupAccounts.push(account);
                        });
                }

                if (this.showChildAccounts) {
                    groupAccounts.push({
                        label: this.$t('general.childAccounts'),
                        value: `S_${this.$t('general.childAccounts')}`,
                        isSeparator: true,
                    });

                    this.childAccounts
                        .map(account => {
                            return {
                                label: account.name,
                                value: account.id.toString(),
                            };
                        })
                        .forEach(account => {
                            groupAccounts.push(account);
                        });
                }

                if (this.showActiveAccounts) {
                    groupAccounts.push({
                        label: this.$t('general.activeAccount'),
                        value: `S_${this.$t('general.activeAccount')}`,
                        isSeparator: true,
                    });

                    this.activeAccounts
                        .map(account => {
                            return {
                                label: account.name,
                                value: account.id.toString(),
                            };
                        })
                        .forEach(account => {
                            groupAccounts.push(account);
                        });
                }

                if (this.showInactiveAccounts) {
                    groupAccounts.push({
                        label: this.$t('general.inactiveAccount'),
                        value: `S_${this.$t('general.inactiveAccount')}`,
                        isSeparator: true,
                    });

                    this.inactiveAccounts
                        .map(account => {
                            return {
                                label: account.name,
                                value: account.id.toString(),
                            };
                        })
                        .forEach(account => {
                            groupAccounts.push(account);
                        });
                }

                return groupAccounts;
            },

            selectedOption() {
                return this.options.find(option => {
                    return !option.isSeparator && option.value == this.groupAccountId;
                });
            },
        },

        watch: {
            'contextGroup.id': {
                immediate: true,
                handler(groupId) {
                    if (groupId) {
                        this.groupAccountId = `G${groupId}`;
                    }
                },
            },

            'contextAccount.id': {
                immediate: true,
                handler(newContextAccountId, oldContextAccountId) {
                    if (newContextAccountId != oldContextAccountId) {
                        this.groupAccountId = String(newContextAccountId);
                    }
                },
            },

            groupAccountId: {
                immediate: true,
                async handler(newValue, oldValue) {
                    if (newValue == oldValue || !newValue) {
                        return;
                    }

                    const isGroup = String(newValue).indexOf('G') !== -1;
                    const value = String(newValue).replace('G', '');

                    if (isGroup) {
                        this.setContextGroupAction(value);
                    } else if (this.$route.name == 'users.update') {
                        await this.setContextAccountAction(value);

                        // Find the appropriate userId
                        let parentUser = cloneDeep(this.parentUser);

                        if (isEmpty(parentUser) && !isEmpty(this.currentEditedUser)) {
                            parentUser = this.currentEditedUser;
                        }

                        if (!parentUser.id) {
                            return;
                        }

                        const user = [parentUser].concat(this.childUsers).find(user => {
                            if (user.account) {
                                return user.account.id == newValue;
                            }

                            return user.account_id == newValue;
                        });

                        this.$router.push({
                            name: 'users.update',
                            params: {
                                id: !isEmpty(user) ? user.id : this.authUser.id,
                            },
                            query: this.$route ? this.$route.query : {},
                        });
                    } else if (this.$route.name == 'accounts.update') {
                        this.$router.push({
                            name: 'accounts.update',
                            params: {
                                id: value,
                            },
                            query: this.$route ? this.$route.query : {},
                        });
                    } else {
                        if (this.contextLead.id != null && this.contextLead.account_id != value) {
                            await this.setContextLeadIdAction();
                        }

                        await this.setContextAccountAction(value);
                        this.setContextGroupAction();
                        this.setContextUserAction();
                    }
                },
            },
        },

        methods: {
            ...mapActions(useContextStore, [
                'setContextAccountAction',
                'setContextGroupAction',
                'setContextLeadIdAction',
                'setContextUserAction',
            ]),

            affectDefaultSelection() {
                if (
                    !this.contextGroup.id &&
                    !this.contextAccount.id &&
                    !this.groupAccountId &&
                    this.parentAccounts.length > 0 &&
                    (this.authUser.isBdc() || this.authUser.isBdcDirector() || this.authUser.isCommercialDirector())
                ) {
                    if (this.$route.name != 'leads.update') {
                        this.groupAccountId = this.parentAccounts[0].id;
                    }
                }
            },

            setFilterValue(filterValue) {
                this.filterValue = filterValue;
            },

            filterOptions(option) {
                const label = latinize(option.label.toLowerCase());
                const search = latinize(this.filterValue.toLowerCase());

                return label.includes(search);
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.affectDefaultSelection();
            });
        },
    };
</script>
