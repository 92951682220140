// Utils
import { escapeRegExp, get } from 'lodash-es';
import { mapState } from 'pinia/dist/pinia.prod.cjs';
import { useGlobalStore } from '@/store/store.js';
import ClientCardSection from '@/entities/ClientCardSection';
import { parseCustomField } from '@/value-objects/CustomField.js';
import { useContextStore } from '@/store/modules/context/store.js';
import i18n from '../plugins/vue-i18n.js';
import { formatPhone, getFullName } from '../utils/index.js';

// Entities
import CommunicationType from '../entities/CommunicationType.js';
import LeadVehicle from '../entities/LeadVehicle.js';
import Service from '../entities/Service.js';

const getLeadValue = ({ attribute, html, lead, locale, prefix }) => {
    switch (attribute.tag) {
        case 'prefix':
            return getPrefix({ prefix, lead, html });

        case 'gender':
            return locale.toLowerCase() == 'fr' && lead.sex == 'W' ? 'e' : '';

        case 'response-subject':
            return getResponseSubject(lead);

        case 'wanted-vehicle-link':
            return getWantedVehicleLink({ lead, html, locale });

        case 'exchange-equity':
            if (lead.exchange_vehicles.length) {
                return LeadVehicle.calculateEquity(lead.exchange_vehicles[0]);
            }

            break;

        case 'exchange-month-remaining':
            if (lead.exchange_vehicles.length) {
                return LeadVehicle.calculateMonthsRemaining(lead.exchange_vehicles[0].end_contract_date, true);
            }

            break;

        case 'commercial-institution':
            return getInstitution(lead);

        case 'subscribe':
            return getSubscribe(locale);
    }

    return '';
};

const getAdvisorValue = ({ attribute, lead, locale }) => {
    switch (attribute.tag) {
        // @TODO Rename "firstName" to "first-name"
        case 'assigned-advisor-firstName':
            return lead.user.first_name;

        case 'assigned-advisor-name':
            return getFullName(lead.user);

        case 'assigned-advisor-post':
            return getUserPost({ user: lead.user, locale });

        case 'assigned-advisor-phone':
            return getUserPhone(lead.user);

        case 'assigned-advisor-email':
            return lead.user.email || '';
    }

    return '';
};

const getBDCValue = ({ attribute, lead, locale }) => {
    switch (attribute.tag) {
        // @TODO Rename "firstName" to "first-name"
        case 'assigned-bdc-first-name':
            return lead.bdc_user.first_name;

        case 'assigned-bdc-name':
            return getFullName(lead.bdc_user);

        case 'assigned-bdc-post':
            return getUserPost({ user: lead.bdc_user, locale });

        case 'assigned-bdc-phone':
            return getUserPhone(lead.bdc_user);

        case 'assigned-bdc-email':
            return lead.bdc_user.email || '';
    }

    return '';
};

const getDeliveryValue = ({ attribute, lead, locale }) => {
    switch (attribute.tag) {
        case 'assigned-delivery-user-first-name':
            return lead.delivered_by.first_name;

        case 'assigned-delivery-user-name':
            return getFullName(lead.delivered_by);

        case 'assigned-delivery-user-post':
            return getUserPost({ user: lead.delivered_by, locale });

        case 'assigned-delivery-user-phone':
            return getUserPhone(lead.delivered_by);

        case 'assigned-delivery-user-email':
            return lead.delivered_by.email || '';
    }

    return '';
};

const getCustomableValue = ({ attribute, lead }) => {
    let customField = lead.account.getCustomFieldByField(attribute.tag);

    if (!customField) {
        customField = useContextStore().account.getCustomFieldByField(attribute.tag);
    }

    if (!customField) {
        return '';
    }

    let customable;

    switch (customField.section) {
        case ClientCardSection.VEHICLE_WANTED:
        case ClientCardSection.VEHICLE_EXCHANGE:
            customable = lead.getCustomableVehicleById(customField.id, customField.section);
            break;

        default:
            customable = lead.getCustomableById(customField.id);
            break;
    }

    if (!customable) {
        if (customField.type === 'boolean') {
            return i18n.t('general.no');
        }

        return '';
    }

    return parseCustomField.toRead(customable.pivot.value, customField.type);
};

const getMassMailingValue = ({ attribute, group }) => {
    return normalizeTagText(`{${group.name} - ${attribute.text}}`);
};

const getVideoCommunicationThumbnail = ({ locale }) => {
    return `
        <thumbnail data-video-communication-thumbnail class='communication-video-placeholder-wrapper' style="background: rgba(0, 0, 0, .35) url('/img/cars.jpeg')">
            <div class="communication-video-placeholder" style="backdrop-filter: blur(3px);">{ ${i18n.t('communications.video.thumbnailPlaceholder', locale)} }</div>
        </thumbnail>
    `;
};

const getModelValue = payload => {
    const { attribute, locale } = payload;
    let value = '';

    attribute.model.forEach(model => {
        value += !value ? '' : ' ';

        const dynamicVariable = get(payload, model);

        if (!dynamicVariable) {
            return;
        }

        if (attribute.formatJs) {
            value += locale_dt(dynamicVariable).humanString(attribute.formatJs == 'date', locale);
            return;
        }

        value += dynamicVariable;
    });

    return value;
};

const getPrefix = ({ prefix, lead, html }) => {
    const civility = prefix || (lead.civility ? i18n.t(`civilities.${lead.civility}`) : '');

    if (html) {
        return `<span class="civility">${civility}</span>`;
    }

    return civility;
};

const getResponseSubject = lead => {
    const communication = lead.communications.find(communication => {
        return (
            communication.communication_type_id == CommunicationType.INCOMING &&
            communication.service_id == Service.LEAD_XPRESS &&
            !!communication.email_subject
        );
    });

    if (!communication) {
        return '';
    }

    return `Re: ${communication.email_subject}`;
};

const getSenderValue = ({ attribute, locale, sender }) => {
    switch (attribute.tag) {
        case 'sender-advisor-first-name':
            return sender.first_name;

        case 'sender-advisor-last-name':
            return sender.last_name;

        case 'sender-advisor-full-name':
            return getFullName(sender);

        case 'sender-advisor-phone':
            return getUserPhone(sender);

        case 'sender-advisor-post':
            return getUserPost({ user: sender, locale });
    }

    return '';
};

const getUserPhone = user => {
    const preferredPhone = user.phones.find(p => p.preferred) || user.phones[0];

    if (!preferredPhone) {
        return '';
    }

    const number = formatPhone(preferredPhone.phone);
    const extension = preferredPhone.extension ? `#${preferredPhone.extension}` : '';

    return `${number} ${extension}`.trim();
};

const getUserPost = ({ user, locale }) => {
    if (!user || !user.post) {
        return '';
    }

    return i18n.t(`database.post_${user.post.name}`, locale).toLowerCase();
};

const getWantedVehicleLink = ({ lead, html, locale }) => {
    const vehicle = lead.wanted_vehicles[0];

    if (!vehicle || !vehicle.url) {
        return '';
    }

    if (html) {
        return `
            <a href="${vehicle.url}">
                ${i18n.t('responseLeadXpress.wantedVehicleLink', locale)}
            </a>
        `;
    }

    return vehicle.url;
};

const getInstitution = lead => {
    if (lead.lead_bank) {
        return lead.lead_bank.name;
    }

    if (lead.imported_bank) {
        return lead.imported_bank;
    }

    return '';
};

const getSubscribe = locale => {
    return `
            <a rel="noopener" href="%tag_subscribe_url%" target="_blank">
                ${i18n.t('templates.subscribe', locale)}
            </a>
        `;
};

const normalizeTagText = (content) => {
    if (content.includes('&amp;')) {
        return content.replace(/&amp;/g, '&');
    }

    return content;
};

export default {
    computed: {
        ...mapState(useGlobalStore, ['templateAttributes']),
    },

    methods: {
        normalizeTagText(content) {
            return normalizeTagText(content);
        },

        replaceAttributesInMessage(payload) {
            let { message, html } = payload;

            this.templateAttributes.forEach(group => {
                group.tags.forEach(attribute => {
                    const tag = `<span class="${attribute.tag}"></span>`;
                    const value = this.getAttributeValue({ group, attribute, ...payload });
                    let attributeTagFr = `{${group.name_fr} - ${attribute.text_fr}}`;
                    let attributeTagEn = `{${group.name_en} - ${attribute.text_en}}`;

                    attributeTagFr = html ? attributeTagFr : this.normalizeTagText(attributeTagFr);
                    attributeTagEn = html ? attributeTagEn : this.normalizeTagText(attributeTagEn);

                    message = message.replace(new RegExp(tag, 'g'), value);
                    message = message.replace(new RegExp(escapeRegExp(attributeTagFr), 'g'), value);
                    message = message.replace(new RegExp(escapeRegExp(attributeTagEn), 'g'), value);
                });
            });

            return message;
        },

        getAttributeValue(payload) {
            const { attribute, sender, massMailing, notification } = payload;

            if (attribute.tag.startsWith('sender-')) {
                if (!sender) {
                    return getMassMailingValue(payload);
                }

                return getSenderValue(payload);
            }

            if (massMailing || notification) {
                return getMassMailingValue(payload);
            }

            if (attribute.tag == 'dealer-phone') {
                return formatPhone(get(payload, 'lead.account.phone'));
            }

            if (attribute.tag == 'lead-phone') {
                return formatPhone(get(payload, 'lead.lead_phones[0].phone'));
            }

            if (attribute.tag.startsWith('assigned-advisor-')) {
                return getAdvisorValue(payload);
            }

            if (attribute.tag.startsWith('assigned-bdc-')) {
                return getBDCValue(payload);
            }

            if (attribute.tag.startsWith('assigned-delivery-user-')) {
                return getDeliveryValue(payload);
            }

            if (attribute.tag === 'video-communication-thumbnail') {
                return getVideoCommunicationThumbnail(payload);
            }

            if (attribute.tag.startsWith('custom_')) {
                return getCustomableValue(payload);
            }

            if (attribute.model.length) {
                return getModelValue(payload);
            }

            return getLeadValue(payload);
        },
    },
};
