/* eslint-disable camelcase */
export function minutesToTime(minutes) {
    if (!Number.isInteger(minutes)) {
        return null;
    }

    return {
        HH: Math.floor(minutes / 60),
        mm: minutes % 60,
    };
}

export function timeToMinutes(time) {
    const minutes = Number(time.HH) * 60 + Number(time.mm);

    return Number.isNaN(minutes) ? 0 : minutes;
}

export function timeHms(timeInSeconds, hideSeconds) {
    let timeInHms = '';

    if (timeInSeconds == 'N/A' || timeInSeconds === null) {
        return 'N/A';
    }

    // Calculate (and subtract) whole hours
    const hours = Math.floor(timeInSeconds / 3600);

    if (hours > 0) {
        timeInSeconds -= hours * 3600;
        timeInHms += `${Math.floor(hours)}h `;
    }

    // Calculate (and subtract) whole minutes
    let minutes = Math.floor(timeInSeconds / 60) % 60;

    if (hours > 0 || minutes > 0) {
        timeInSeconds -= minutes * 60;

        if (hideSeconds) {
            // If we hide the seconds and there are more than 30 add a min to round to the correct minute count
            if (Math.floor(timeInSeconds % 60) > 30) {
                minutes += 1;
            }
        }

        timeInHms += `${Math.floor(minutes)}m `;
    }

    // What's left is seconds
    if (hours < 1 && hideSeconds !== true) {
        timeInHms += `${Math.floor(timeInSeconds % 60)}s`;
    } else if (hours == 0 && minutes == 0 && hideSeconds === true && timeInSeconds > 0) {
        timeInHms += '< 1m';
    }

    timeInHms = timeInHms.trim();

    return timeInHms || 'N/A';
}
