<template>
    <header
        class="main-header | z-30 sticky bg-white shadow-lg | transition-spacing duration-300 | print:hidden"
        :class="showSearch ? 'h-32' : 'h-18'"
        :style="{ top: `${bodySpacingTop}px` }"
        v-if="!$browser.isMobileWebView()"
    >
        <div class="h-18 flex px-4">
            <div class="flex items-center | md:mr-6 md:flex-1" data-intercom-target="dashboard.searchAdd">
                <button
                    class="btn-nav sidebar-toggle | pl-0 flex-shrink-0 | md:pl-2"
                    type="button"
                    @click="toggleNav"
                    v-if="includeInLayout('nav')"
                >
                    <icon :class="mdLayout ? 'text-3xl' : 'text-2xl'" name="regular/navigation-menu" />
                </button>
                <mq-layout class="btn-nav px-3" :mq="['xs', 'sm', 'md']">
                    <icon
                        class="text-2xl"
                        name="regular/search"
                        :class="{ 'text-blue-600': showSearch }"
                        @click="toggleShowSearch"
                    />
                </mq-layout>
                <mq-layout class="flex-1 ml-4 | md:max-w-sm" mq="lg+">
                    <main-search v-if="showHeaderElement('search')" />
                </mq-layout>
                <mq-layout mq="2xl+">
                    <button class="link-blue ml-6 flex items-center group" @click="openLeadCreate" v-if="showAddLead">
                        <icon class="text-sm text-blue-500 group-hover:text-blue-600" :name="$icons.add" />
                        <span
                            class="ml-2 uppercase tracking-wider text-grey-500 group-hover:text-grey-600"
                            v-text="addLeadLabel"
                        />
                    </button>
                </mq-layout>
            </div>

            <div class="flex-1 flex items-center justify-end overflow-hidden | xl:flex-initial">
                <slot>
                    <template v-if="showHeaderElement('notification')">
                        <router-link
                            class="btn-nav | md:mr-2"
                            :class="{ 'px-3': mdLayout }"
                            data-intercom-target="dashboard.calendar"
                            :to="{ name: 'calendar' }"
                            v-if="authUser.hasAccessTo('calendar')"
                        >
                            <icon :class="mdLayout ? 'text-2xl' : 'text-lg'" name="regular/calendar" />
                        </router-link>
                        <notification-center />
                    </template>
                    <mq-layout mq="2xl+">
                        <teams class="ml-2" v-if="showHeaderElement('team') && showTeams" />
                    </mq-layout>
                    <divisions class="ml-2" v-if="showHeaderElement('division')" />
                    <group-account-menu
                        class="ml-2 flex-shrink"
                        :class="{ 'mr-4': mdLayout }"
                        v-if="showHeaderElement('account')"
                    />
                    <user-dropdown
                        data-intercom-target="dashboard.preferences"
                        class="md:ml-2"
                        v-if="showHeaderElement('settings')"
                    />
                </slot>
            </div>
        </div>
        <div class="h-18 px-4" v-if="showSearch">
            <main-search
                class="w-full"
                ref="search"
                @submit="showSearch = false"
                v-if="showHeaderElement('search')"
            />
        </div>
    </header>
</template>

<script>
    // Utils
    import { get } from 'lodash-es';

    // Components
    import { mapState, mapActions } from 'pinia';
    import Divisions from './Divisions.vue';
    import GroupAccountMenu from './GroupAccountMenu.vue';
    import MainSearch from './MainSearch.vue';
    import NotificationCenter from './NotificationCenter.vue';
    import Teams from './Teams.vue';
    import UserDropdown from './nav/UserDropdown.vue';

    // Entities
    import DashboardType from '../entities/DashboardType.js';

    // Pinia
    import { useDashboardStore } from '../store/modules/dashboard/store.js';
    import { useLayoutStore } from '../store/modules/layout/store.js';
    import { useGlobalStore } from '../store/store.js';
    import { useContextStore } from '../store/modules/context/store.js';

    export default {
        components: {
            Divisions,
            GroupAccountMenu,
            MainSearch,
            NotificationCenter,
            Teams,
            UserDropdown,
        },

        data() {
            return {
                showSearch: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useDashboardStore, ['dashboardType']),
            ...mapState(useLayoutStore, ['bodySpacingTop']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            showTeams() {
                return (
                    !!this.contextAccount.activeTeams.length &&
                    (this.authUser.isAdmin() || this.authUser.isDirector() || this.authUser.isBdcDirector())
                );
            },

            showAddLead() {
                return (
                    this.contextAccount.id &&
                    this.dashboardType != DashboardType.EVENT &&
                    this.includeInLayout('nav')
                );
            },

            addLeadLabel() {
                switch (this.dashboardType) {
                    case DashboardType.WALK_IN:
                        return this.$t('general.walkIn');

                    case DashboardType.PHONE_UP:
                        return this.$t('general.phoneUp');

                    case DashboardType.ALL:
                    case DashboardType.RENEWAL:
                    case DashboardType.COMMERCIAL:
                    case DashboardType.LOYALTY:
                    case DashboardType.SERVICE:
                    case DashboardType.SALE:
                    case DashboardType.EVENT:
                        return this.$tc('general.client', 1);

                    default:
                        return this.$tc('general.lead', 1);
                }
            },
        },

        watch: {
            '$route.path'() {
                if (this.showSearch && this.mdLayout) {
                    this.showSearch = false;
                }
            },
        },

        methods: {
            ...mapActions(useLayoutStore, ['setHeaderHeight']),

            openLeadCreate() {
                this.$eventBus.$emit('open-lead-create', {
                    accountId: this.contextAccount.id,
                    type: this.dashboardType ? this.underscore(this.dashboardType) : null,
                });
            },

            showHeaderElement(element) {
                return get(this.$route.meta, `header.${element}`, false);
            },

            toggleNav() {
                this.$eventBus.$emit('toggle-nav');
            },

            async toggleShowSearch() {
                this.showSearch = !this.showSearch;

                await this.$nextTick();

                if (this.showSearch) {
                    this.$refs.search.$refs.mainSearch.$refs.input.focus();
                }
            },

            async onHeaderMutation() {
                await this.$nextTick();

                this.setHeaderHeight(this.$el.offsetHeight);
            },
        },

        mounted() {
            this.headerObserver = new MutationObserver(this.onHeaderMutation);
            this.headerObserver.observe(this.$el, { attributeFilter: ['class'] });
            this.onHeaderMutation();
        },

        beforeDestroy() {
            this.headerObserver.disconnect();
            this.headerObserver = null;
        },
    };
</script>

<style lang="less">
    .main-header {
        .btn-nav {
            @apply relative p-2 border-0 bg-transparent text-grey-700 flex items-center;

            &:hover {
                @apply text-grey-800;
            }

            .badge {
                @apply absolute top-0 right-0;
                margin-right: -2px;

                &.badge-empty {
                    margin-top: 5px;
                    margin-right: 5px;
                }
            }
        }

        .nav-select {
            .el-input {
                .el-input__inner {
                    @apply text-lg pl-0;

                    background-color: transparent;
                    border-color: transparent;
                }

                .el-input__inner,
                .el-input__inner::placeholder {
                    @apply text-grey-700;
                }

                .el-select__caret {
                    @apply text-grey-600;
                }

                &:hover,
                &:active,
                &:focus,
                &.is-focus {
                    .el-input__inner,
                    .el-input__inner::placeholder {
                        @apply text-grey-800;
                    }

                    .el-select__caret {
                        @apply text-grey-700;
                    }

                    .el-input__inner {
                        @apply bg-transparent border-transparent;
                    }
                }
            }
        }
    }
</style>
