import Enum from './Enum.js';

export default class ClientCardSection extends Enum {
    static get entries() {
        return {
            generalInfo: 'generalInfo',
            performance: 'performance',
            vehicleWanted: 'vehicleWanted',
            vehicleExchange: 'vehicleExchange',
            process: 'process',
            processService: 'processService',
            funnel: 'funnel',
            dollarAndLost: 'dollarAndLost',
        };
    }

    static get GENERAL_INFO() {
        return this.entries.generalInfo;
    }

    static get PERFORMANCE() {
        return this.entries.performance;
    }

    static get VEHICLE_WANTED() {
        return this.entries.vehicleWanted;
    }

    static get VEHICLE_EXCHANGE() {
        return this.entries.vehicleExchange;
    }

    static get PROCESS() {
        return this.entries.process;
    }

    static get PROCESS_SERVICE() {
        return this.entries.processService;
    }

    static get FUNNEL() {
        return this.entries.funnel;
    }

    static get DOLLAR_AND_LOST() {
        return this.entries.dollarAndLost;
    }

    static get translationGroup() {
        return 'clientCard';
    }
}
