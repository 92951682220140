import axios from '@/plugins/axios.js';
import InvalidPayload from '../../../exceptions/InvalidPayload.js';
import Lead from '../../../entities/Lead.js';

export default {
    async fetchAssociatedLeads(customerId) {
        if (!customerId) {
            throw new InvalidPayload('ID not provided.');
        }

        this.associatedLeads = [];

        try {
            const response = await axios.get(`v1/customers/${customerId}/associatedLeads`);

            this.associatedLeads = response.data.data
                .map(lead => new Lead(lead))
                .sort((b, a) => {
                    return a.contextDate.diffInMilliseconds(b.contextDate);
                });
        } catch (error) {
            // Glitch with the associated lead creation flow which is executed in 2 steps
        }
    },

    removeLead(leadId) {
        const leadIdIndex = this.associatedLeads.findIndex(lead => lead.id === leadId);

        if (leadIdIndex === -1) {
            return;
        }

        this.associatedLeads.splice(leadIdIndex, 1);
    },
};
