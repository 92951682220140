<template>
    <div class="flex items-center px-5 space-x-2 py-2 bg-gray-150 overflow-y-hidden h-full | 2xl:space-x-3 | transition-width duration-300" :class="[{'rounded-lg': !editing }, waveformClasses]">
        <button class="relative bg-blue-500 shadow-md rounded-full | transition duration-200 | hover:bg-blue-600" @click="playPause">
            <div class="h-10 w-10 flex items-center justify-center">
                <activix-spinner :size="18" :line-size="2" v-if="loading && !placeholder && !editing" />
                <icon
                    :name="iconIsPlaying"
                    class="text-sm text-white"
                    :class="{'-mr-0.5': !isPlaying}"
                    v-else
                />
            </div>
        </button>

        <wave-surfer
            :url="file.url"
            :duration="file.duration"
            :placeholder="placeholder"
            :autoload="editing"
            :autoplay="!editing"
            :loading.sync="loading"
            ref="wavesurfer"
            @ready="setReady"
            @time="setTime"
            @playing="setPlaying"
        />

        <div class="tracking-wider" v-text="currentTime" />
    </div>
</template>

<script>
    import WaveSurfer from './WaveSurfer.vue';

    export default {
        components: {
            WaveSurfer,
        },

        props: {
            file: {
                type: Object,
                required: true,
                duration: null,
            },
            editing: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                isPlaying: false,
                wavesurferReady: false,
                currentTime: '00:00',
                loading: false,
            };
        },

        computed: {
            iconIsPlaying() {
                return this.isPlaying ? 'bold/controls-pause' : 'bold/arrow-button-right-2';
            },

            waveformClasses() {
                if (!this.placeholder && !this.wavesurferReady && !this.editing) {
                    return 'w-32';
                }

                if (!this.wavesurferReady && !this.editing) {
                    return 'w-auto';
                }

                return 'w-full';
            },
        },

        methods: {
            setPlaying(playing) {
                this.isPlaying = playing;
            },

            setReady(ready) {
                this.wavesurferReady = ready;
            },

            setTime(time) {
                this.currentTime = time;
            },

            async playPause() {
                if (!this.$refs.wavesurfer) {
                    return;
                }

                this.$refs.wavesurfer.playPause();
            },
        },
    };
</script>
