<template>
    <activix-modal
        size="lg"
        portal="modal-3"
        :opened="opened"
        @close="close"
        @closed="$emit('closed')"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('clientCard.automatedEmail') }}
            </h4>
        </template>
        <template slot="body">
            <div class="flex">
                <icon class="cursor-pointer text-grey-650" scale="2.1" :name="$icons.email" />
                <div class="flex-1 flex flex-col mt-2 ml-4">
                    <div class="flex justify-between items-center">
                        <span class="font-semibold text-lg" v-html="subject" />
                        <span class="font-normal text-sm" v-html="date" />
                    </div>
                    <div class="mt-4" v-html="body" />
                </div>
            </div>
        </template>
        <template slot="footer">
            <div class="flex justify-between">
                <div class="flex items-center justify-center" v-if="job && $feature.isEnabled('new-lead-page', false)">
                    <activix-tooltip :content="activateAutomationTooltip">
                        <activix-switcher
                            :disabled="guest || isPastAutomation"
                            :value="isActiveAutomation"
                            @input="toggleAutomationActivation"
                        />
                    </activix-tooltip>

                    <activix-tooltip :content="$t('communications.automationTooltip')">
                        <router-link
                            target="_blank"
                            :to="{ name: 'automations.update', params: { id: job.action.automation_id } }"
                            v-if="parentAuthUser.hasAccessTo('automations.index')"
                        >
                            <activix-button class="ml-2" size="small">
                                <icon name="regular/video-game-magic-wand" />
                            </activix-button>
                        </router-link>
                    </activix-tooltip>
                </div>

                <div class="flex flex-1 justify-end">
                    <activix-button @click="close">
                        {{ $t('general.close') }}
                    </activix-button>
                </div>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { mapActions, mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store.js';
    import { useGlobalStore } from '@/store/store.js';

    import EmailCompose from '@/mixins/EmailCompose.js';

    import ActivixDate from '@/value-objects/ActivixDate.js';

    export default {
        mixins: [EmailCompose],

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            job: {
                required: true,
            },
            lead: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState(useGlobalStore, {
                guest: 'guest',
                parentAuthUser: 'parentAuthUser',
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextResponseTemplates: 'contextResponseTemplates',
            }),

            activateAutomationTooltip() {
                if (this.isPastAutomation) {
                    return this.$t('automations.pastExecutionDate');
                }

                if (this.isActiveAutomation) {
                    return this.$t('automations.disableJob');
                }

                return this.$t('automations.enableJob');
            },

            isActiveAutomation() {
                return !!this.job?.active;
            },

            isPastAutomation() {
                const executionDate = new ActivixDate(this.job?.execution_date);

                return executionDate.isPast();
            },

            templateTranslation() {
                if (!this.job) {
                    return null;
                }

                const template = this.contextResponseTemplates.find(
                    t => t.id == this.job.action.parameters.template.id,
                );

                return template.translations.find(t => t.locale == this.lead.locale);
            },

            templateSender() {
                if (!this.job) {
                    return null;
                }

                return this.lead[this.job.action.parameters.from_role];
            },

            subject() {
                if (!this.job) {
                    return '';
                }

                return this.replaceAttributesInMessage({
                    html: false,
                    lead: this.lead,
                    locale: this.lead.locale,
                    message: this.templateTranslation.subject,
                    sender: this.templateSender,
                    users: this.contextAccount.users,
                });
            },

            body() {
                if (!this.job) {
                    return '';
                }

                return this.replaceAttributesInMessage({
                    html: true,
                    lead: this.lead,
                    locale: this.lead.locale,
                    message: this.templateTranslation.body,
                    sender: this.templateSender,
                    users: this.contextAccount.users,
                });
            },

            date() {
                if (!this.job) {
                    return '';
                }

                return new ActivixDate(this.job.execution_date).toHumanShort();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateAutomationJobAction']),

            close() {
                this.$emit('update:opened', false);
            },

            toggleAutomationActivation(active) {
                if (this.isPastAutomation) {
                    return;
                }

                this.updateAutomationJobAction({
                    id: this.job.id,
                    leadId: this.lead.id,
                    payload: { active },
                });
            },
        },
    };
</script>
