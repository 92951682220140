import Enum from './Enum.js';

export default class TaskEventType extends Enum {
    static get entries() {
        return {
            call: 1,
            email: 2,
            appointment: 3,
            delivery: 4,
            other: 5,
            csi: 6,
            sms: 7,
            phone_appointment: 8,
        };
    }

    static get CALL() {
        return this.entries.call;
    }

    static get EMAIL() {
        return this.entries.email;
    }

    static get APPOINTMENT() {
        return this.entries.appointment;
    }

    static get DELIVERY() {
        return this.entries.delivery;
    }

    static get OTHER() {
        return this.entries.other;
    }

    static get CSI() {
        return this.entries.csi;
    }

    static get SMS() {
        return this.entries.sms;
    }

    static get PHONE_APPOINTMENT() {
        return this.entries.phone_appointment;
    }
}
