<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div>
        <div class="space-y-6">
            <div>
                <div class="flex items-center space-x-2 mb-2">
                    <label class="mb-0">{{ isForwarding ? $t('responseLeadXpress.forwardTo') : $t('responseLeadXpress.to') }}</label>
                    <transition appear name="fade">
                        <email-validation
                            :email="riskyEmail"
                            :show-email="true"
                            v-if="!massMailing && riskyEmail"
                        />
                    </transition>
                </div>
                <div class="flex">
                    <activix-input
                        class="w-full truncate"
                        :readonly="true"
                        :value="limitText(massMailingLeadCount)"
                        v-if="massMailing"
                    />
                    <template v-else>
                        <activix-select
                            class="w-full truncate attached-r"
                            :value="response.forwardTo"
                            :no-data-text="$t('mailClient.enterForwardRecipients')"
                            :placeholder="$t('options.defaultFowarding')"
                            :multiple="true"
                            :filterable="true"
                            :allow-create="true"
                            :default-first-option="true"
                            :options="forwardToOptions"
                            @change="updateRecipients('forwardTo', $event)"
                            v-if="isForwarding"
                        >
                            <el-option
                                :label="item.label"
                                :value="item.value"
                                :key="item.value"
                                v-for="item in forwardToOptions"
                            />
                        </activix-select>
                        <activix-multiselect
                            class="w-full truncate"
                            label="email"
                            :allow-empty="false"
                            :disabled="disallowCommunication"
                            :limit-text="limitText"
                            :multiple="true"
                            :options="emails"
                            :searchable="false"
                            :selected="response.to"
                            :taggable="true"
                            @update="updateSelectedTo"
                            v-else
                        />
                        <activix-button
                            class="attached-l attached-r text-blue-500"
                            :active="ccFieldsVisible.cc"
                            @click="ccFieldsVisible.cc = !ccFieldsVisible.cc"
                        >
                            {{ $t('mailClient.cc') }}
                        </activix-button>
                        <activix-button
                            class="attached-l text-blue-500"
                            :active="ccFieldsVisible.bcc"
                            @click="ccFieldsVisible.bcc = !ccFieldsVisible.bcc"
                        >
                            {{ $t('mailClient.bcc') }}
                        </activix-button>
                    </template>
                </div>
            </div>

            <div v-if="ccShouldBeVisible && !massMailing">
                <label>{{ $t('responseLeadXpress.cc') }}</label>
                <activix-select
                    :value="response.cc"
                    :no-data-text="$t('mailClient.enterCcRecipients')"
                    :placeholder="$t('options.emailCc')"
                    :multiple="true"
                    :filterable="true"
                    :allow-create="true"
                    :default-first-option="true"
                    :options="[]"
                    :force-desktop-layout="true"
                    @change="updateRecipients('cc', $event)"
                >
                    <el-option
                        :label="item.label"
                        :value="item.value"
                        :key="item.value"
                        v-for="item in options"
                    />
                </activix-select>
            </div>

            <div v-if="bccShouldBeVisible && !massMailing">
                <label>{{ $t('responseLeadXpress.bcc') }}</label>
                <activix-select
                    :value="response.bcc"
                    :no-data-text="$t('mailClient.enterBccRecipients')"
                    :placeholder="$t('options.emailBcc')"
                    :multiple="true"
                    :filterable="true"
                    :allow-create="true"
                    :default-first-option="true"
                    :options="[]"
                    :force-desktop-layout="true"
                    @change="updateRecipients('bcc', $event)"
                >
                    <el-option
                        :label="item.label"
                        :value="item.value"
                        :key="item.value"
                        v-for="item in options"
                    />
                </activix-select>
            </div>

            <div>
                <label>{{ $t('responseLeadXpress.subject') }}</label>
                <activix-input :disabled="disallowCommunication" v-model="response.subject" />
            </div>

            <div class="space-y-6 | md:space-y-0 md:flex ">
                <div class="w-full | md:w-1/4 md:pr-3">
                    <label>{{ $t('responseLeadXpress.category') }}</label>
                    <activix-multiselect
                        class="w-full truncate"
                        label="locale_name"
                        :selected="response.division"
                        :options="orderedDivisions"
                        :searchable="false"
                        :allow-empty="!hasTemplate"
                        :clearable="!hasTemplate"
                        :multiple="false"
                        :disabled="disallowCommunication"
                        @update="updateSelectedDivision"
                        @cancel="() => updateSelectedDivision('')"
                    />
                </div>
                <div class="w-full | md:flex-1 md:px-3">
                    <label>
                        {{ $t('responseLeadXpress.template') }}
                        <span class="text-red-500" v-if="hasDivision && !hasTemplate">*</span>
                    </label>
                    <div class="flex">
                        <activix-multiselect
                            class="w-full truncate attached-r"
                            label="title"
                            :selected="response.template"
                            :options="filteredTemplates"
                            :searchable="true"
                            :allow-empty="false"
                            :multiple="false"
                            :disabled="disabledTemplateSelection"
                            :max-height="400"
                            @update="updateSelectedTemplate"
                        />
                        <activix-button
                            class="attached-l attached-r text-blue-500"
                            :active="currentLang == 'FR'"
                            @click="$emit('change-lang', 'FR')"
                        >
                            {{ 'FR' }}
                        </activix-button>
                        <activix-button
                            class="attached-l text-blue-500"
                            :active="currentLang == 'EN'"
                            @click="$emit('change-lang', 'EN')"
                        >
                            {{ 'EN' }}
                        </activix-button>
                    </div>
                </div>
                <div class="w-full | md:w-1/4 md:pl-3">
                    <label>{{ $t('responseLeadXpress.civility') }}</label>
                    <activix-multiselect
                        label="title"
                        class="w-full truncate"
                        :selected="civility"
                        :options="civilities"
                        :searchable="false"
                        :allow-empty="false"
                        :multiple="false"
                        :disabled="disabledCivilitySelection"
                        :max-height="800"
                        @update="updateSelectedCivility"
                    />
                </div>
            </div>

            <div class="space-y-6 | xs:space-y-0 xs:flex xs:justify-between">
                <div class="w-full | xs:w-2/3 | md:w-1/2">
                    <lead-xpress-from
                        :lead="lead"
                        :from.sync="response.from"
                        :from-backup.sync="response.fromBackup"
                        v-if="showFrom"
                    />
                    <div class="flex items-center | xs:h-10" v-else-if="!massMailing">
                        <activix-tooltip :content="$t('responseLeadXpress.originalCommunicationTooltip')">
                            <activix-checkbox :disabled="includeOriginalCommunicationDisabled" v-model="includeOriginalCommunication">
                                <template #before>
                                    <label class="m-0">{{ $t('responseLeadXpress.originalCommunication') }}</label>
                                </template>
                            </activix-checkbox>
                        </activix-tooltip>
                    </div>
                </div>

                <div class="w-full | xs:w-auto xs:max-w-1/3 xs:text-right | md:max-w-1/2">
                    <label class="hidden | xs:block xs:invisible" v-if="showFrom">{{ $t('responseLeadXpress.addAttachment') }}</label>
                    <activix-upload
                        class="truncate"
                        :show-file-list="false"
                        :maximum-size="attachmentParameters.maximumAttachmentSize"
                        :multiple="true"
                        :disabled="disallowCommunication"
                        v-model="response.attachments"
                    >
                        <activix-button size="medium">
                            <icon class="mr-2" name="regular/attachment" />
                            {{ $t('responseLeadXpress.addAttachment') }}
                        </activix-button>
                    </activix-upload>
                </div>
            </div>

            <div class="mt-6 space-y-4">
                <activix-alert type="info" v-if="signatureWillBeUpdatedOnSend">
                    {{ $t('responseLeadXpress.signatureWillBeUpdatedOnSend') }}
                </activix-alert>

                <template-editor
                    :locale="currentLang.toLowerCase()"
                    :lead="lead"
                    :mass-mailing="massMailing"
                    :design="response.design || response.template.design"
                    @update-design="response.design = $event"
                    @update-body="response.body = $event"
                    v-if="templateAdvanced"
                />
                <activix-summernote
                    :sticky-toolbar="!mdLayout"
                    :disabled="disallowCommunication"
                    :is-last="massMailing"
                    ref="email"
                    v-model="response.body"
                    v-else
                />
            </div>
        </div>

        <template v-if="displayCommunicationVideo">
            <div class="panel panel-default rounded-t-none border-t-0" v-if="!massMailing && leadId">
                <div class="panel-body border-rounded-t-none">
                    <video-communication
                        :ziggeo-id="ziggeoId"
                        :processed="videoProcessed"
                        :user="currentUser"
                        :lead-id="leadId"
                    />
                </div>
            </div>
        </template>

        <div class="panel panel-default | mt-8" v-if="lead && connectedCommunicationsCount && !massMailing">
            <div class="panel-heading">
                {{ $t('client.previousEmails') }}
            </div>
            <div class="panel-body">
                <email-accordion :lead="lead" parent="leadxpress" />
            </div>
        </div>

        <div class="panel panel-default | mt-8 " v-if="!massMailing">
            <div class="panel-heading">
                {{ $t('email.originalEmail') }}
            </div>
            <div class="panel-body" v-if="lead">
                <div
                    v-if="
                        (!empty(lead.request) && !empty(lead.request.old_url)) || lead.chat || lead.scraper_description
                    "
                >
                    <span v-html="lead.chat" v-if="lead.chat" />
                    <span v-else>
                        <span v-html="scraperDescription" v-if="lead.scraper_description" />

                        <span v-if="!empty(lead.request) && lead.request.parser.type != 'xml'">
                            <hr />

                            <iframe :src="url" scrolling="no" ref="iframe" />
                        </span>
                    </span>
                </div>
                <div class="relative" v-else>
                    <span v-html="lead.original_email" v-if="lead.original_email" />
                    <span v-else>
                        {{ $t('email.no_original_email') }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types, vue/no-mutating-props */
    import { orderBy } from 'lodash-es';
    import { nl2br } from '@/utils/string.js';
    import LeadEmail from '@/entities/LeadEmail.js';
    import EmailValidationRisk from '@/entities/EmailValidationRisk.js';
    import Division from '@/entities/Division.js';
    import EmailCompose from '@/mixins/EmailCompose.js';
    import LeadXpressFrom from '@/components/lead_xpress/LeadXpressFrom.vue';
    import EmailAccordion from '@/components/lead/EmailAccordion.vue';
    import EmailValidation from '@/components/lead/EmailValidation.vue';
    import VideoCommunication from '@/components/lead/VideoCommunication.vue';
    import TemplateEditor from '@/components/templates/TemplateEditor.vue';

    // Pinia
    import { mapActions, mapState } from 'pinia';
    import TemplateType from '@/entities/TemplateType';
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            LeadXpressFrom,
            EmailAccordion,
            EmailValidation,
            VideoCommunication,
            TemplateEditor,
        },

        mixins: [EmailCompose],

        props: [
            'communicating',
            'communicatingUser',
            'currentLang',
            'draft',
            'isForwarding',
            'lead',
            'response',
            'signature',
            'unsubscribeLink',
            'massMailingLeadCount',
        ],

        data() {
            return {
                hasResponseObject: false,
                summernote: {
                    height: null,
                    minHeight: 160,
                    maxHeight: null,
                    focus: true,
                    lang: this.$i18n.locale == 'en' ? 'en-US' : 'fr-FR',
                },
                civility: {
                    id: 'none',
                    title: this.$t('general.none'),
                },
                showCivility: false,
                attachementInternalErrors: [],
                includeOriginalCommunication: false,
                options: [],
                forwardToOptions: [],
                ccFieldsVisible: {
                    cc: false,
                    bcc: false,
                },
            };
        },

        computed: {
            ...mapState(useDashboardStore, {
                massMailing: 'isMassMailing',
                pagination: 'pagination',
            }),
            ...mapState(useGlobalStore, {
                attachmentParameters: 'attachmentParameters',
                configs: 'configs',
                authUser: 'authUser',
                parentAuthUser: 'parentAuthUser',
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                filteredContextReponseTemplates: 'filteredContextReponseTemplates',
            }),

            ownCommunication() {
                return this.communicatingUser.id === this.parentAuthUser?.id || this.communicatingUser.id === this.authUser.id;
            },

            disallowCommunication() {
                return this.communicating && !this.ownCommunication;
            },

            ziggeoId() {
                return this.draft?.media?.ziggeo_id;
            },

            videoProcessed() {
                return this.draft?.media?.video_processed;
            },

            displayCommunicationVideo() {
                return !this.templateAdvanced || this.response.body.includes('<data value="data-video-communication-thumbnail"') || this.response.body.includes('<thumbnail data-video-communication-thumbnail');
            },

            hasDivision() {
                return !!this.response.division?.id;
            },

            hasTemplate() {
                return !!this.response.template?.id;
            },

            templateAdvanced() {
                return (this.response.template?.is_advanced && this.response.template?.design) || this.response.design;
            },

            url() {
                if (this.lead.request) {
                    return `${window.location.protocol}//leadactivix.ca/scraper/show_original.php?authToken=${process.env.VUE_APP_SCRAPER_AUTH_TOKEN}&file=${this.lead.request.old_url}`;
                }

                return '';
            },

            divisions() {
                const divisions = [];
                const divisionIds = [];
                const templates = this.filteredContextReponseTemplates;

                for (let index = 0; index < templates.length; index++) {
                    if (divisionIds.indexOf(templates[index].division_id) == -1) {
                        divisionIds.push(templates[index].division_id);
                        divisions.push({
                            id: templates[index].division_id,
                            locale_name: Division.getTranslation(templates[index].division_id),
                        });
                    }
                }

                return divisions;
            },

            orderedDivisions() {
                return orderBy(this.divisions, 'locale_name');
            },

            ccShouldBeVisible() {
                return !empty(this.response.cc) || this.ccFieldsVisible.cc;
            },

            bccShouldBeVisible() {
                return !empty(this.response.bcc) || this.ccFieldsVisible.bcc;
            },

            civilities() {
                return [
                    {
                        id: 'none',
                        title: this.$t('general.none', this.currentLang.toLowerCase()),
                    },
                    {
                        id: 'mr',
                        title: this.$t('database.mr', this.currentLang.toLowerCase()),
                    },
                    {
                        id: 'ms',
                        title: this.$t('database.ms', this.currentLang.toLowerCase()),
                    },
                    {
                        id: 'mrs',
                        title: this.$t('database.mrs', this.currentLang.toLowerCase()),
                    },
                    {
                        id: 'miss',
                        title: this.$t('database.miss', this.currentLang.toLowerCase()),
                    },
                    {
                        id: 'dr',
                        title: this.$t('database.dr', this.currentLang.toLowerCase()),
                    },
                    {
                        id: 'me',
                        title: this.$t('database.me', this.currentLang.toLowerCase()),
                    },
                ].filter((item, index, civilities) => civilities.findIndex(civility => civility.title === item.title) === index);
            },

            filteredTemplates() {
                if (!this.response.division) {
                    return [];
                }

                const responseTemplates = this.filteredContextReponseTemplates
                    .filter(responseTemplate => {
                        const validType = responseTemplate.type === TemplateType.EMAIL;
                        const validDivision = responseTemplate.division_id == this.response.division.id;
                        const localizedTemplate = responseTemplate.translations.find(template => {
                            return template.locale == this.currentLang.toLowerCase();
                        });

                        return validDivision && validType && localizedTemplate;
                    })
                    .map(value => {
                        const translation = value.translations.find(t => t.locale == this.currentLang.toLowerCase());
                        const titlePrefix = value.parent ? `[${this.$t('leadXpress.parent')}]` : '';

                        return {
                            id: value.id,
                            main: !value.parent,
                            subject: translation.subject,
                            body: translation.body,
                            design: translation.design,
                            is_advanced: value.is_advanced,
                            title: `${titlePrefix} ${value.title || translation.title}`.trim(),
                            attachments: translation.attachments,
                            cc: value.cc,
                            bcc: value.bcc,
                            bcc_assigned: value.bcc_assigned,
                            cc_assigned: value.cc_assigned,
                            icon: value.is_favorite ? 'regular/rating-star' : '',
                            is_favorite: value.is_favorite,
                            iconStyle: value.is_favorite ? 'fill-current text-yellow-400' : '',
                        };
                    });
                return orderBy(responseTemplates, ['title'], ['asc']).sort((a, b) => b.is_favorite - a.is_favorite);
            },

            emails() {
                if (empty(this.lead)) {
                    return [];
                }

                return this.lead.lead_emails
                    .filter(tmpEmail => {
                        return tmpEmail.valid && !tmpEmail.validated_email?.is_disposable;
                    })
                    .sort((a, b) => a.validated_email?.risk_score - b.validated_email?.risk_score);
            },

            riskyEmail() {
                const email = this.response.to
                    .filter(email => email.validated_email?.risk === EmailValidationRisk.MEDIUM)
                    .sort((a, b) => a.validated_email?.risk_score - b.validated_email?.risk_score)
                    .reverse()[0];

                if (!email) {
                    return null;
                }

                return new LeadEmail(email);
            },

            connectedCommunicationsCount() {
                let count = 0;

                if (this.lead.communications.length > 0) {
                    const communications = this.lead.communications.filter(communication => {
                        if (
                            communication.connected &&
                            communication.service_id == 4 &&
                            communication.status != 'interrupted' &&
                            communication.status != 'error' &&
                            communication.status != 'invalid' &&
                            communication.status != 'pending'
                        ) {
                            return true;
                        }

                        return false;
                    });

                    count = communications.length;
                }

                return count;
            },

            scraperDescription() {
                let content = '';

                if (!empty(this.lead.scraper_description)) {
                    content = nl2br(this.lead.scraper_description);

                    const emailRegex = /(\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)/;
                    const phoneRegex = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/;

                    if (this.authUser.hasAdvisorSkills()) {
                        content = content.replace(emailRegex, 'XXXX');
                        content = content.replace(phoneRegex, 'XXXX');
                    }
                }

                return content;
            },

            currentCivility() {
                let tmpCivility = 'ms';

                if (
                    (empty(this.lead.civility) || this.lead.civility == -1) &&
                    (empty(this.lead.sex) || this.lead.civility == -1)
                ) {
                    return {
                        id: 'none',
                        title: this.$t('general.none', this.currentLang.toLowerCase()),
                    };
                }
                if (!empty(this.lead.civility)) {
                    tmpCivility = this.lead.civility;
                } else if (!empty(this.lead.sex) && this.lead.sex == 'M') {
                    tmpCivility = 'mr';
                }

                return {
                    id: tmpCivility,
                    title: this.$t(`database.${tmpCivility}`, this.currentLang.toLowerCase()),
                };
            },

            originalCommunication() {
                let originalCommunication = '';

                if (this.includeOriginalCommunicationDisabled || !this.includeOriginalCommunication) {
                    return originalCommunication;
                }

                originalCommunication = `<hr>${this.$t('email.originalEmail')}:<br><br>${this.lead.comment}<hr>`;

                return nl2br(originalCommunication);
            },

            includeOriginalCommunicationDisabled() {
                return !this.lead.comment || this.massMailing || this.response.template?.is_advanced;
            },

            disabledTemplateSelection() {
                return (
                    !this.response.division ||
                    !this.filteredTemplates.length ||
                    !this.templateAttributes.length ||
                    this.disallowCommunication
                );
            },

            disabledCivilitySelection() {
                return (
                    !this.showCivility ||
                    !this.response.division ||
                    !this.filteredTemplates.length ||
                    !this.templateAttributes.length ||
                    this.disallowCommunication
                );
            },

            signatureWillBeUpdatedOnSend() {
                return this.response.from !== this.currentUser.id;
            },

            showFrom() {
                return this.massMailing;
            },

            parentAuthUser() {
                return this.$auth.user();
            },

            currentUser() {
                const authUser = (this.parentAuthUser.children || []).find(
                    child => child.account_id == this.lead.account_id,
                );

                return authUser || this.authUser;
            },

            leadId() {
                return this.lead?.id;
            },
        },

        watch: {
            response(newValue) {
                if (!empty(newValue.cc)) {
                    this.ccFieldsVisible.cc = true;
                }

                if (!empty(newValue.bcc)) {
                    this.ccFieldsVisible.bcc = true;
                }
            },

            civility(newValue) {
                const html = $('<div />', { html: this.response.body });

                html.find('.civility').html(newValue.id != 'none' ? newValue.title : '');

                this.response.body = html.html();
                this.updateResponse();

                const civilityId = newValue.id != 'none' ? newValue.id : null;
                this.updateLeadAction(this.lead.id, { civility: civilityId });
            },

            signature(newValue) {
                const html = $('<div />', { html: this.response.body });

                html.find('.signature').first().replaceWith(newValue);

                this.response.body = html.html();
                this.updateResponse();
            },

            unsubscribeLink(newValue) {
                const html = $('<div />', { html: this.response.body });

                html.find('.unsubscribe-link').first().replaceWith(newValue);

                this.response.body = html.html();
                this.updateResponse();
            },

            'response.to'(newValue) {
                if (!newValue.length && this.emails.length == 1) {
                    this.response.to = this.emails;
                    this.updateResponse();
                }
            },

            'response.from'() {
                this.updateResponse();
            },

            'response.subject'(newValue) {
                if (!newValue.length) {
                    this.updateResponse();
                }
            },

            'response.body'(newValue) {
                if (!newValue.length) {
                    this.updateResponse();
                }
            },

            'response.design'() {
                this.updateResponse();
            },

            'response.template'(newValue) {
                this.showCivility = false;

                if (!empty(newValue)) {
                    this.showCivility = true;

                    this.civility = this.currentCivility;

                    const payload = {
                        sender: this.authUser,
                        lead: this.lead,
                        locale: this.currentLang.toLowerCase(),
                        prefix: this.currentCivility.id != 'none' ? this.currentCivility.title : '',
                        massMailing: this.massMailing,
                        users: this.contextAccount.users,
                    };
                    const body = this.replaceAttributesInMessage({
                        ...payload,
                        html: true,
                        message: newValue.body,
                    });
                    const subject = this.replaceAttributesInMessage({
                        ...payload,
                        html: false,
                        message: newValue.subject,
                    });

                    if (!this.hasResponseObject) {
                        this.response.subject = subject;
                    }

                    if (!newValue.is_advanced) {
                        const html = $('<div />', { html: this.response.body });
                        html.find('.body').first().html(body);

                        this.response.body = html.html();
                    } else {
                        this.response.body = body;
                    }

                    this.response.design = newValue.is_advanced ? newValue.design : null;

                    this.updateResponse();
                }
            },

            'response.templateAttachments'() {
                this.updateResponse();
            },

            'response.attachments'() {
                this.updateResponse();
            },

            includeOriginalCommunication() {
                const html = $('<div />', { html: this.response.body });

                html.find('.original-communication').first().html(this.originalCommunication);

                this.response.body = html.html();
                this.updateResponse();
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            limitText(count) {
                return this.$tc('responseLeadXpress.selectedRecipient', count);
            },

            updateRecipients(field, value) {
                value = this.splitAndCleanEmailList(value);

                this.response[field] = [...new Set(value)];

                this.updateResponse();
            },

            splitAndCleanEmailList(emails) {
                return emails.map((email) => {
                    return email.toLowerCase().split(/[\s,;]+/);
                }).flat().filter((email) => {
                    return /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/gm.test(email);
                }) || [];
            },

            updateSelectedDivision(value) {
                this.response.division = value;
                this.response.template = '';

                this.updateResponse();
            },

            updateSelectedTemplate(value) {
                this.$emit('set-initial-body');
                this.response.template = value;
                this.response.templateAttachments = value.attachments;
                this.response.attachments = [];

                const emails = this.lead.getAssignedUserEmails();

                if (value.cc_assigned && emails.length > 0) {
                    const currentCCs = this.response.cc.concat(value.cc || []);
                    const cc = emails
                        .map(email => email.email)
                        .filter(email => {
                            if (value.cc.length < 0) {
                                return false;
                            }

                            return currentCCs.some(templateCc => email == templateCc);
                        });

                    if (cc) {
                        this.response.cc = cc;
                    }
                } else {
                    this.response.cc = this.response.cc.concat(value.cc || []);
                }

                if (value.bcc_assigned && emails.length > 0) {
                    const currentBCCs = this.response.bcc.concat(value.bcc || []);
                    const bcc = emails
                        .map(email => email.email)
                        .filter(email => {
                            if (value.bcc.length < 0) {
                                return false;
                            }

                            return currentBCCs.some(templateBcc => email == templateBcc);
                        });

                    if (bcc) {
                        this.response.bcc = bcc;
                    }
                } else {
                    this.response.bcc = this.response.bcc.concat(value.bcc || []);
                }

                value.attachments.forEach(attachment => {
                    this.response.attachments.push({
                        id: attachment.id,
                        name: attachment.name,
                        size: attachment.size,
                        path: attachment.path,
                    });
                });

                this.updateResponse();
            },

            updateSelectedTo(value) {
                this.response.to = value;
                this.updateResponse();
            },

            updateSelectedCivility(value) {
                this.civility = value;
            },

            updateResponse() {
                this.$emit('update:response', this.response);
            },
        },
    };
</script>
