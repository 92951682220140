<template>
    <div class="flex items-center ml-2">
        <div class="flex items-center relative">
            <icon
                :name="$icons.loading"
                class="align-middle link-grey text-base"
                :class="{ 'spin-inverse': loading, 'text-xl': mdLayout }"
                @click="$emit('click')"
            />
            <div class="badge badge-warning | absolute top-0 left-0 ml-2 -mt-1" v-if="showBadge" />
        </div>
        <div class="text-grey-500 ml-2" v-if="showLoadingPercent">
            {{ loadingPercent }}%
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ActivixReload',

        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            loadingPercent: {
                type: Number,
                default: null,
            },
            showBadge: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            showLoadingPercent() {
                return this.loading && this.loadingPercent !== null;
            },
        },
    };
</script>
