import axios from '../plugins/axios.js';
import InvalidPayload from '../exceptions/InvalidPayload.js';

export default {
    async update(id, payload = {}) {
        if (!id) {
            throw new InvalidPayload('ID not provided.');
        }

        const response = await axios.put(`v1/automation-jobs/${id}`, payload);

        return response.data.data;
    },
};
