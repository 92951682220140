import Vue from 'vue';
import { get, orderBy } from 'lodash-es';
import i18n from '../plugins/vue-i18n.js';
import moment from '../plugins/moment.js';

import { getFullName } from '../utils/index.js';
import ActivixDate from '../value-objects/ActivixDate.js';
import Account from '../entities/Account.js';
import LeadType from '../entities/LeadType.js';
import CreatedMethod from '../entities/CreatedMethod.js';
import CommunicationMethod from '../entities/CommunicationMethod.js';
import CommunicationType from '../entities/CommunicationType.js';
import { useContextStore } from './modules/context/store.js';

export default {
    timezones() {
        const timezones = this.configs.timezones.map(timezone => {
            const offset = moment.tz(timezone).format('Z');

            return {
                value: timezone,
                text: `(GMT${offset}) ${timezone}`,
            };
        });

        return orderBy(timezones, 'text');
    },
    groupDisplay() {
        return !empty(useContextStore().group.id) || get(useContextStore().account, 'account_manager', false);
    },
    authUser() {
        const authUser = (this.parentAuthUser.children || []).find(child => child.account_id == useContextStore().account.id);

        return authUser || this.parentAuthUser;
    },
    childAccount() {
        const lead = useContextStore().contextLead;
        let account = useContextStore().account;

        if (lead.account_id && useContextStore().account.id && lead.account_id != useContextStore().account.id) {
            account = useContextStore().account.children.find(c => c.id == lead.account_id) || useContextStore().account;
        }

        if (account.id) {
            return new Account(account);
        }

        return new Account(this.authUser.account);
    },
    activeUsers() {
        return useContextStore().account.activeUsers;
    },
    usersAndSupplierUsers() {
        return useContextStore().account.usersAndSupplierUsers;
    },
    activeUsersAndSupplierUsers() {
        return useContextStore().account.activeUsersAndSupplierUsers;
    },
    serviceUsers() {
        return useContextStore().account.serviceUsers;
    },
    notServiceUsers() {
        return useContextStore().account.notServiceUsers;
    },

    assignmentUsers() {
        const lead = useContextStore().contextLead;
        const assignmentUsers = useContextStore().account.activeUsers;

        if (lead.user_id) {
            const assignedAdvisor = assignmentUsers.find(u => u.id == lead.user_id);

            if (!assignedAdvisor) {
                assignmentUsers.unshift(lead.user);
            }
        }

        if (lead.commercial_id) {
            const assignedCommercial = assignmentUsers.find(u => u.id == lead.commercial_id);

            if (!assignedCommercial) {
                assignmentUsers.unshift(lead.commercial);
            }
        }

        if (lead.bdc_user_id) {
            const assignedBdc = assignmentUsers.find(u => u.id == lead.bdc_user_id);

            if (!assignedBdc) {
                assignmentUsers.unshift(lead.bdc_user);
            }
        }

        if (lead.service_advisor_id) {
            const assignedServiceAdvisorIncluded = assignmentUsers.some(u => u.id == lead.service_advisor_id);

            if (!assignedServiceAdvisorIncluded) {
                assignmentUsers.unshift(lead.service_advisor_user);
            }
        }

        if (lead.service_agent_id) {
            const assignedServiceAgentIncluded = assignmentUsers.some(u => u.id == lead.service_agent_id);

            if (!assignedServiceAgentIncluded) {
                assignmentUsers.unshift(lead.service_agent_user);
            }
        }

        return assignmentUsers;
    },

    advisorUsers() {
        const lead = useContextStore().contextLead;
        const advisorUsers = useContextStore().account.advisorUsers;

        if (lead.user_id) {
            const assignedAdvisor = advisorUsers.find(u => u.id == lead.user_id);

            if (!assignedAdvisor) {
                advisorUsers.unshift(lead.user);
            }
        }

        return advisorUsers;
    },

    bdcUsers() {
        const lead = useContextStore().contextLead;
        const bdcUsers = useContextStore().account.bdcUsers;

        if (lead.bdc_user_id) {
            const assignedBdc = bdcUsers.find(u => u.id == lead.bdc_user_id);

            if (!assignedBdc) {
                bdcUsers.unshift(lead.bdc_user);
            }
        }

        return bdcUsers;
    },
    commercialUsers() {
        const lead = useContextStore().contextLead;
        const commercialUsers = useContextStore().account.commercialUsers;

        if (lead.commercial_id) {
            const assignedCommercial = commercialUsers.find(u => u.id == lead.commercial_id);

            if (!assignedCommercial) {
                commercialUsers.unshift(lead.commercial);
            }
        }

        return commercialUsers;
    },
    serviceAdvisorUsers() {
        const lead = useContextStore().contextLead;
        const serviceAdvisorUsers = useContextStore().account.serviceAdvisorUsers;

        if (lead.service_advisor_id) {
            const assignedServiceAdvisorIncluded = serviceAdvisorUsers.some(u => u.id == lead.service_advisor_id);

            if (!assignedServiceAdvisorIncluded) {
                serviceAdvisorUsers.unshift(lead.service_advisor_user);
            }
        }

        return serviceAdvisorUsers;
    },
    serviceAgentUsers() {
        const lead = useContextStore().contextLead;
        const serviceAgentUsers = useContextStore().account.serviceAgentUsers;

        if (lead.service_agent_id) {
            const assignedServiceAgentIncluded = serviceAgentUsers.some(u => u.id == lead.service_agent_id);

            if (!assignedServiceAgentIncluded) {
                serviceAgentUsers.unshift(lead.service_agent_user);
            }
        }

        return serviceAgentUsers;
    },
    activityReportUsers() {
        const users = [];

        if (!empty(useContextStore().user.id) && empty(useContextStore().account.id)) {
            users.push(useContextStore().user);
        } else if (!empty(useContextStore().account.activeUsers)) {
            for (const user of useContextStore().account.activeUsers) {
                if ((user.active && user.isBdc()) || user.isBdcDirector()) {
                    users.push(user);
                }
            }
        }

        if (!empty(users)) {
            // Sort
            users.sort((a, b) => a.first_name.localeCompare(b.first_name));
        }

        return users;
    },
    activeDirectors() {
        const users = [];

        if (empty(useContextStore().account.activeUsers)) {
            return users;
        }

        for (const user of useContextStore().account.activeUsers) {
            if (user.isDirector()) {
                users.push({
                    value: user.id,
                    text: getFullName(user),
                });
            }
        }

        if (!empty(users)) {
            // Sort
            users.sort((a, b) => a.text.localeCompare(b.text));
        }

        return users;
    },
    sources() {
        const sources = [
            {
                id: null,
                name: i18n.t('general.none'),
            },
        ];

        if (empty(useContextStore().account.sources)) {
            return sources;
        }

        for (const source of useContextStore().account.sources) {
            source.name = source.name.trim();
            sources.push(source);
        }

        if (!empty(sources)) {
            // Sort
            sources.sort((a, b) => a.name.localeCompare(b.name));
        }

        return sources;
    },
    sourcesWithProvider() {
        const sources = [
            {
                id: null,
                name: i18n.t('general.none'),
            },
        ];

        if (empty(useContextStore().account.sourcesWithProvider)) {
            return sources;
        }

        for (const source of useContextStore().account.sourcesWithProvider) {
            source.name = source.name.trim();
            sources.push(source);
        }

        if (!empty(sources)) {
            // Sort
            sources.sort((a, b) => a.name.localeCompare(b.name));
        }

        return sources;
    },
    communicatingLead() {
        const lead = useContextStore().contextLead;
        const user = this.authUser;

        if (!lead.id) {
            return null;
        }

        return this.communicatingLeads.find(communicatingLead => {
            return communicatingLead.id === lead.id && communicatingLead.communicating_user.id !== user.id;
        });
    },

    guest() {
        const user = this.authUser;
        const lead = useContextStore().contextLead;

        if (typeof Vue.router === 'undefined' || empty(Vue.router.app) || Vue.router.app.$route.name != 'leads.update') {
            return false;
        }

        if (user.isAdmin()) {
            return false;
        }

        if (!user.hasAccessToEditAccount(lead.account_id)) {
            return true;
        }

        const releaseDate = new ActivixDate('2021-05-01 00:00');
        const leadCreationDate = new ActivixDate(lead.created_at);

        if (lead.lead_type_id === LeadType.EVENT && leadCreationDate.isAfter(releaseDate)) {
            const nonEventLeads = lead.customer.leads.filter(customerLead => {
                return customerLead.id === lead.event_related_id;
            });

            if (nonEventLeads.length === 0 || !lead.event_related_id) {
                return true;
            }
        }

        if (lead.isAssignedTo(user.id)) {
            return false;
        }

        if (
            !user.isDirector() &&
            lead.lead_type_id == LeadType.EMAIL &&
            lead.created_method != CreatedMethod.MANUAL &&
            lead.account.leadxpress &&
            lead.leadxpress_flow &&
            lead.account.leadxpress_option.priority &&
            !userHasAccessToLeadXpressFlow(lead, user)
        ) {
            return true;
        }

        return false;
    },
};

function userHasAccessToLeadXpressFlow(lead, user) {
    const validFlow = user.account.flows.find(flow => {
        return (
            (
                (lead.division_id && flow.division_id == lead.division_id) ||
                (!lead.division_id && !flow.division_id && flow.lead_form_id && lead.lead_form_id && flow.lead_form_id == lead.lead_form_id)
            ) &&
            flow.communication_method_id == CommunicationMethod.EMAIL &&
            flow.communication_type_id == CommunicationType.INCOMING
        );
    });

    if (!validFlow) {
        return true;
    }

    const userIsInFlow = validFlow.activeUsers.some(flowUser => {
        return user.id == flowUser.id && flowUser.flow_order;
    });

    const userIsInFutureProcedure = validFlow.activeUsers.some(flowUser => {
        return user.id == flowUser.id && Math.floor(flowUser.flow_order) > lead.leadxpress_flow;
    });

    if (!userIsInFlow) {
        return false;
    }

    if (fallbackEnoughTimeElapsed(lead)) {
        return true;
    }

    if (userIsInFutureProcedure) {
        return false;
    }

    return true;
}

function fallbackEnoughTimeElapsed(lead) {
    const minutesSinceLeadCreation = new ActivixDate('now').diffInMinutes(new ActivixDate(lead.created_at));
    return minutesSinceLeadCreation >= lead.account.leadxpress_option.priority * 6;
}
