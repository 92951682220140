var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex"},[_c('activix-tooltip',{attrs:{"content":!_vm.disabled ? _vm.$t('users.edit.confirmationWhen') : ''}},[_c('activix-multiselect',{staticClass:"flex-1",class:{
                'attached-l': _vm.attached,
                'attached-r': _vm.showTime,
            },attrs:{"identifier":"value","label":"name","max-height":200,"options":_vm.whenOptions,"selected":_vm.selectedWhen,"searchable":false,"allow-empty":false,"disabled":_vm.disabled},on:{"update":_vm.updateWhen}})],1),(_vm.showTime)?_c('el-time-select',{staticClass:"attached-l",attrs:{"disabled":_vm.disabled,"value":_vm.selectedTime,"clearable":false,"editable":false,"picker-options":{
            start: '06:00',
            step: '00:15',
            end: '22:00',
        }},on:{"input":_vm.updateTime}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }