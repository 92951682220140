export default {
    account: {
        singular: 'space',
    },
    civility: {
        singular: 'title',
    },
    presented: {
        singular: 'space visit',
    },
    province: {
        CA: 'province / State',
    },
    renewal: {
        singular: 'retention',
        plural: 'retentions',
        prefix: 'a retention',
        abr: 'reten.',
    },
    renewed: {
        infinitive: 'retain',
        singular: 'retained',
        plural: 'retained',
    },
    result: {
        singular: 'contacted',
    },
    sale: {
        singular: 'order',
        plural: 'orders',
        prefix: 'an order',
    },
    sold: {
        singular: 'ordered',
        plural: 'ordered',
    },
    version: {
        singular: 'variant',
    },
    walkIn: {
        singular: 'space visit',
        plural: 'space visits',
        prefix: 'a space visit',
        abr: 'space',
    },
};
