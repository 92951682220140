import i18n, { loadLanguageAsync } from '../plugins/vue-i18n.js';

const renamedPaths = {
    '/lead/': '/leads/',
    '/accounts/edit/': '/accounts/',
    '/dashboard/': '/dashboards/',
    '/templates/edit/': '/templates/',
    '/users/edit/': '/users/',
};

const parsePath = (to, locale = '') => {
    let path = to.fullPath
        .replace(`/${locale}`, '')
        .replace('/#/', '/')
        .replace('#', '')
        .split('?')[0];

    if (path.charAt(0) != '/') {
        path = `/${path}`;
    }

    Object.keys(renamedPaths).forEach(oldPath => {
        const newPath = renamedPaths[oldPath];

        path = path.replace(oldPath, newPath);
    });

    return path;
};

const localeRedirect = to => {
    let locale = '';

    if (to.path.startsWith('/fr')) {
        locale = 'fr';
    }

    if (to.path.startsWith('/en')) {
        locale = 'en';
    }

    if (locale) {
        loadLanguageAsync(locale);
    }

    return parsePath(to, locale);
};

export default [
    // Localized routes
    { path: '/fr*', redirect: localeRedirect },
    { path: '/en*', redirect: localeRedirect },
];
