<template>
    <div>
        <template v-if="mdLayout && !forceDesktopLayout">
            <div
                :class="[
                    hasInputLook ? 'link-grey-dark flex form-control items-center' : 'text-grey-700 flex items-center',
                    { disabled: disabled, 'input-white': white },
                ]"
                @click="editField"
            >
                <span
                    class="flex-1 mr-3 truncate"
                    :class="{ 'text-lg': !hasInputLook, italic: !selectionText && placeholder }"
                    ref="selectionText"
                >
                    {{ selectionText || placeholder || '' }}
                </span>
                <icon class="text-grey-600" name="regular/arrow-down-1" :class="{ 'text-xs': !hasInputLook }" />
            </div>

            <select-picker-modal
                :allow-empty="false"
                :auto-apply="true"
                :custom-label="customLabel"
                :identifier="identifier"
                :label="label"
                :multiple="false"
                :opened.sync="modalOpened"
                :options="options"
                @cancel="$emit('cancel')"
                @input="triggerUpdate"
                v-model="currentSelection"
            />
        </template>

        <template v-else>
            <el-select
                :class="{ outline: outline, white: white, 'has-error': hasError }"
                v-bind="$props"
                ref="select"
                v-on="$listeners"
                @visible-change="onVisibleChange"
            >
                <slot />
            </el-select>
        </template>
    </div>
</template>

<script>
    import autosizeInput from '../../plugins/autosize-input.js';
    import SelectPickerModal from '../lead/inputs/SelectPickerModal.vue';

    export default {
        name: 'ActivixSelect',

        components: { SelectPickerModal },

        props: {
            name: String,
            id: String,
            value: null,
            autoComplete: String,
            automaticDropdown: Boolean,
            size: String,
            disabled: Boolean,
            clearable: Boolean,
            filterable: Boolean,
            allowCreate: Boolean,
            loading: Boolean,
            popperClass: String,
            remote: Boolean,
            loadingText: String,
            noMatchText: String,
            noDataText: String,
            remoteMethod: Function,
            filterMethod: Function,
            multiple: Boolean,
            multipleLimit: Number,
            placeholder: String,
            defaultFirstOption: Boolean,
            reserveKeyword: Boolean,
            valueKey: String,
            collapseTags: Boolean,
            popperAppendToBody: {
                type: Boolean,
                default: true,
            },

            // Custom props
            taggable: {
                type: Boolean,
                default: true,
            },
            minWidth: {
                type: Number,
                default: null,
            },
            autosize: {
                type: Boolean,
                default: false,
            },
            outline: {
                type: Boolean,
                default: false,
            },
            white: {
                type: Boolean,
                default: false,
            },
            hasError: {
                type: Boolean,
                default: false,
            },

            // Mobile-oriented props
            hasInputLook: {
                type: Boolean,
                default: true,
            },
            // In some cases, this param is empty because the page is not available on mobile
            options: {
                type: Array,
                required: true,
            },
            identifier: {
                type: String,
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
            customLabel: {
                type: Function,
                default(option, label) {
                    if (option) return option[label];
                    return label ? option[label] : option;
                },
            },
            forceDesktopLayout: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                autosizeInput: null,
                currentSelection: '',
                modalOpened: false,
                selectionText: '',
            };
        },

        computed: {
            key() {
                if (this.identifier && !empty(this.identifier)) {
                    return this.identifier;
                }

                return 'id';
            },

            displayLabel() {
                if (this.label && !empty(this.label)) {
                    return this.label;
                }

                return 'label';
            },
        },

        watch: {
            value: {
                immediate: true,
                handler() {
                    if (this.mdLayout) {
                        this.currentSelection = this.value;
                        this.selectionText = this.getOptionLabel(
                            this.options.find(option => option[this.key] == this.value),
                        );
                    }

                    this.updateAutosize();
                },
            },
        },

        methods: {
            onVisibleChange(visible) {
                if (visible) {
                    this.setMinWidth();
                } else {
                    this.updateAutosize();
                }
            },

            setMinWidth() {
                if (this.autosize && this.minWidth) {
                    this.autosizeInput.setWidth(this.mdLayout && !forceDesktopLayout ? 150 : this.minWidth);
                }
            },

            initAutosize() {
                if (!this.autosize) {
                    return;
                }

                const select = this.$refs.select;
                const input = select ? select.$refs.reference.$refs.input : null;

                if (input) {
                    this.autosizeInput = autosizeInput(input);
                }
            },

            destroyAutosize() {
                if (this.autosizeInput) {
                    this.autosizeInput.destroy();
                }
            },

            updateAutosize() {
                if (this.autosizeInput) {
                    this.$nextTick(() => {
                        this.autosizeInput.setWidth();
                    });
                }
            },

            editField() {
                if (this.disabled) {
                    return;
                }

                this.modalOpened = true;
            },

            getOptionLabel(option) {
                if (typeof option === 'object' && option !== null) {
                    return this.customLabel(option, this.displayLabel) || option[this.displayLabel] || '';
                }

                return option ? this.customLabel(option) : '';
            },

            triggerUpdate(value) {
                this.selectionText = this.getOptionLabel(this.options.find(option => option[this.key] == value));

                this.$emit('input', value, this.id);
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.initAutosize();
            });
        },

        beforeDestroy() {
            this.destroyAutosize();
        },
    };
</script>
