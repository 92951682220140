import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client';
import { ApolloLink, HttpLink } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import * as Sentry from '@sentry/vue';

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'auth_access_token';

// Config
const defaultOptions = {
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    wsEndpoint: null,
    // LocalStorage token
    tokenName: AUTH_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false,

    apollo: {
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache',
            },
        },

    },

    inMemoryCacheOptions: {
        dataIdFromObject: object => {
            switch (object.__typename) {
                case 'AccountProduct': return `${object.__typename}:${object.id}_${object.account_id}`;
                case 'AccountSupplier': return `${object.__typename}:${object.account_supplier_id}`;
                case 'FlowUser': return `${object.__typename}:${object.id}_${object.flow_id}`;
                default: return `${object.__typename}:${object.id || object._id}`; // fall back to `id` and `_id` for all other types
            }
        },
        fragmentMatcher: {
            possibleTypes: {
                UserInterface: ['User', 'FlowUser'],
            },
        },
    },

    link: ApolloLink.from([
        onError((error) => {
            if (error.graphQLErrors) {
                error.graphQLErrors.forEach(({ message }) => {
                    Sentry.withScope(scope => {
                        scope.setExtras({
                            operationName: error.operation.operationName,
                            body: error.operation.query.loc.source.body,
                        });
                        Sentry.captureMessage(`[GraphQL error] ${message}`, 'error');
                    });
                });
            }
        }),
        new HttpLink({ uri: `${process.env.VUE_APP_API_URL}/graphql` }),
    ]),

    // Override the way the Authorization header is set
    getAuth: async () => {
        const token = await Vue.auth.token();

        return token ? `Bearer ${token}` : '';
    },

    defaultHttpLink: false,
};

function getApolloClient(options = {}) {
    if (typeof Vue.prototype.$apolloClient === 'undefined') {
        // Create apollo client
        const { apolloClient, wsClient } = createApolloClient({
            ...defaultOptions,
            ...options,
        });

        apolloClient.wsClient = wsClient;

        Vue.prototype.$apolloClient = apolloClient;
    }

    return Vue.prototype.$apolloClient;
}

export const apolloClient = getApolloClient();

export function createProvider(options = {}) {
    return new VueApollo({
        defaultClient: getApolloClient(options),
        defaultOptions: {
            $query: {},
        },
        errorHandler(error) {
            console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message);
        },
    });
}

export async function apolloLogin() {
    if (apolloClient.wsClient) {
        restartWebsockets(apolloClient.wsClient);
    }
    try {
        await apolloClient.resetStore();
    } catch (e) {
        console.log('%cError on cache reset (login)', 'color: orange;', e.message);
    }
}

export async function apolloLogout() {
    if (apolloClient.wsClient) {
        restartWebsockets(apolloClient.wsClient);
    }

    try {
        await apolloClient.resetStore();
    } catch (e) {
        console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
    }
}
