import axios from '../../../plugins/axios.js';
import { useGlobalStore } from '../../store.js';

export default {
    async validateLeadPhones(leadId) {
        try {
            const response = await axios.post(`v1/leads/${leadId}/validate-phones`);

            return response.data.data.lead_phones.find(phone => phone.validated && phone.mobile);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                return null;
            }

            useGlobalStore().appendNewError({
                code: '0006',
                display: true,
                error,
                payload: leadId,
            });
        }

        return null;
    },
};
