export default function () {
    return {
        key: null,
        data: null,
        reset() {
            this.key = null;
            this.data = null;
        },
        set(key, data) {
            if (!key || key === this.key) {
                return;
            }

            if (typeof data !== 'function') {
                throw new Error('The provided "data" must be a function.');
            }

            this.key = key;
            this.data = new Promise((resolve, reject) => {
                data().then(value => resolve(value)).catch(error => reject(error));
            });
        },
        async get(key) {
            if (!key || key !== this.key) {
                return null;
            }

            return this.data;
        },
    };
}
