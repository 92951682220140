export default {
    refresh: 'Le véhicule a été rafraîchi de vAuto avec succès',
    error: {
        userNotActive: "L'utilisateur {0} n'est pas actif dans vAuto.",
    },
    warning: {
        assignedFallbackVerifyUsername: 'Le véhicule a été soumis à vAuto, impossible d\'assigner la soumission à votre nom d\'utilisateur vAuto, la soumission est assignée au conseiller par défaut de votre compte',
        assignedFallbackUsernameNotSet: 'Le véhicule a été soumis à vAuto, aucun utilisateur vAuto défini dans vos préférences, la soumission est assignée au conseiller par défaut de votre compte',
        assignedDirectorVerifyUsername: 'Le véhicule a été soumis à vAuto, impossible d\'assigner la soumission à votre nom d\'utilisateur vAuto, la soumissions a été envoyé sans conseiller.',
        assignedDirectorUsernameNotSet: 'Le véhicule a été soumis à vAuto, aucun utilisateur vAuto défini dans vos préférences, la soumissions a été envoyé sans conseiller.',
        assignedDirectorLastFallback: 'Le véhicule a été soumis à vAuto, le conseiller par défault du compte est invalide, la soumissions a été envoyé sans conseiller',
    },
    success: {
        assignedAdvisor: 'Le véhicule a été soumis à vAuto avec succès avec le conseiller {0}',
        assignedDirector: 'Le véhicule a été soumis à vAuto avec succès avec le conseiller par défaut du compte',
    },
    label: {
        unsubmitted: 'Non soumis',
        waiting: 'Évaluation soumise le {0}, en attente...',
        received: 'Évaluation reçue',
    },
    tooltip: {
        unsubmitted: 'Soumettre le véhicule vers vAuto',
        niv: "Vous devez saisir un NIV valide afin d'avoir une soumission valide vers vAuto",
        match:
            "La soumission du véhicule vers vAuto est basée sur la marque, le modèle et l'année. Assurez-vous que les valeurs entrées soient valides avant la soumission",
    },
};
