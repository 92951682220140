import Enum from './Enum.js';

export default class SaleState extends Enum {
    static get entries() {
        return {
            approved: 'approved',
            carryOver: 'carryOver',
            delivered: 'delivered',
            deliveryCarryOver: 'deliveryCarryOver',
            discounted: 'discounted',
            lost: 'lost',
            none: 'none',
            planned: 'planned',
            postponed: 'postponed',
            refused: 'refused',
            sold: 'sold',
            waitingSale: 'waitingSale',
        };
    }

    static get APPROVED() {
        return this.entries.approved;
    }

    static get CARRY_OVER() {
        return this.entries.carryOver;
    }

    static get DELIVERED() {
        return this.entries.delivered;
    }

    static get DELIVERY_CARRY_OVER() {
        return this.entries.deliveryCarryOver;
    }

    static get DISCOUNTED() {
        return this.entries.discounted;
    }

    static get LOST() {
        return this.entries.lost;
    }

    static get NONE() {
        return this.entries.none;
    }

    static get PLANNED() {
        return this.entries.planned;
    }

    static get POSTPONED() {
        return this.entries.postponed;
    }

    static get REFUSED() {
        return this.entries.refused;
    }

    static get SOLD() {
        return this.entries.sold;
    }

    static get WAITING_SALE() {
        return this.entries.waitingSale;
    }
}
