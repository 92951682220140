import Model from './Model.js';
import LeadPhoneType from './LeadPhoneType.js';

export default class LeadPhone extends Model {
    static get defaults() {
        return {
            phone: '',
        };
    }

    static validType(type) {
        return LeadPhoneType.exists(type);
    }

    static format(phone, country = false) {
        if (!phone) return '';

        phone = phone.replace(/\D/g, '');

        if (!phone) return '';

        if (!phone.startsWith('1')) {
            phone = `1${phone}`;
        }

        if (country) {
            return phone.replace(new RegExp(/(\d{1})(\d{3})(\d{3})(\d{4})/, 'g'), '+$1 $2 $3-$4');
        }

        return phone.replace(new RegExp(/(\d{1})(\d{3})(\d{3})(\d{4})/, 'g'), '$2 $3-$4');
    }

    numberWithoutCountry() {
        if (this.phone.indexOf('+1') === 0) {
            return this.phone.slice(2);
        }

        return this.phone;
    }

    toString() {
        return LeadPhone.format(this.phone);
    }
}
