<template>
    <div>
        <activix-modal
            classes="sms-modal"
            size="lg"
            cancel-class-name="mt-2 z-20 | xl:mt-6"
            :opened="opened"
            :loading="loading"
            @open="onOpen"
            @close="close"
            @closed="onClosed"
        >
            <template slot="body">
                <iframe
                    id="niotext"
                    class="h-full"
                    scrolling="no"
                    :src="nioTextUrl"
                />
            </template>
        </activix-modal>

        <activix-confirm-modal
            type="info"
            :content="communicatingModalConfirm.text"
            :approve-text="$t('general.yes')"
            :deny-text="$t('general.cancel')"
            :opened.sync="communicatingModalConfirm.opened"
            :show-deny="false"
            @cancel="communicatingModalConfirm.text = ''"
        />
    </div>
</template>

<script>
    // Utils
    import CommunicationMethod from '@/entities/CommunicationMethod';
    import { mapActions, mapState } from 'pinia';
    import { showWarning } from '../../utils/toastr.js';

    // Mixins
    import LeadPhoneValidation from '../../mixins/LeadPhoneValidation.js';

    // Entities
    import Lead from '../../entities/Lead.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useLeadStore } from '../../store/modules/lead/store.js';

    export default {
        mixins: [LeadPhoneValidation],

        data() {
            return {
                lead: new Lead(),
                leadId: null,
                loggedIn: false,
                loading: true,
                nioTextIframe: null,
                communicationUserId: null,
                niotextId: null,
                opened: false,
                communicatingModalConfirm: {
                    opened: false,
                    text: '',
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs', 'parentAuthUser']),

            nioTextUrl() {
                return process.env.VUE_APP_NIOTEXT_URL;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['appendNewError', 'fetchLead']),
            ...mapActions(useLeadStore, ['validateLeadPhones']),

            leadAccountHasNiotext() {
                return this.authUser.children.some(c => this.lead.account_id == c.account_id && c.hasNioText());
            },

            async removeCommunicating() {
                if (!this.authUser.hasNioText() && !this.leadAccountHasNiotext()) {
                    return;
                }

                await this.$api.leads.removeCommunicating({ id: this.leadId });
            },

            async setCommunicating(leadId) {
                if ((!this.authUser.hasNioText() && !this.authUser.children.find(c => c.hasNioText())) || !leadId) {
                    return;
                }

                const response = await this.$api.leads.setCommunicating({ id: leadId }, { type: CommunicationMethod.SMS });
                const { data } = response.data;

                if (empty(data.user)) {
                    this.opened = true;
                    return;
                }

                let communicatingText = 'sendingEmail';

                if (data.type == CommunicationMethod.PHONE) {
                    communicatingText = 'calling';
                } else if (data.type == CommunicationMethod.SMS) {
                    communicatingText = 'sendingSms';
                }

                this.communicatingModalConfirm.text = this.$t(`responseLeadXpress.${communicatingText}`, [
                    this.getFullName(data.user),
                ]);

                this.communicatingModalConfirm.opened = true;
            },

            initIframe() {
                if (!this.authUser.hasNioText() && !this.leadAccountHasNiotext()) {
                    return;
                }

                this.nioTextIframe = iFrameResize(
                    {
                        checkOrigin: [process.env.VUE_APP_NIOTEXT_URL],
                        sizeHeight: false,
                        scrolling: true,
                        onMessage: data => {
                            if (data.message == 'ready') {
                                this.sendInfo();
                            }

                            if (data.message == 'leadSelected') {
                                this.loading = false;
                            }
                        },
                    },
                    '#niotext',
                )[0].iFrameResizer;
            },

            sendInfo() {
                this.nioTextIframe.sendMessage({
                    accessToken: this.parentAuthUser.niotext_token,
                    accountId: this.lead.account_id,
                    leadId: this.lead.id,
                    contactId: this.niotextId,
                    userId: this.communicationUserId || this.parentAuthUser.id,
                });
            },

            async openModal() {
                if ((!this.authUser.hasNioText() && !this.leadAccountHasNiotext()) || !this.lead.communications) {
                    return;
                }

                // Opened from communication list
                if (this.communicationUserId) {
                    this.niotextId = this.lead.niotext_id;
                    this.opened = true;
                    return;
                }

                try {
                    const response = await this.$axios.post(`v1/leads/${this.lead.id}/query-niotext`);

                    this.niotextId = response.data.data.niotext_id;
                    this.setCommunicating(this.lead.id);
                } catch (error) {
                    if (error.response && error.response.status === 403) {
                        showWarning(this.$t('toastr.noPhoneNioText'));
                        return;
                    }

                    showWarning(this.$t('toastr.errorMessages.niotext'));

                    this.appendNewError({
                        code: '0097',
                        display: false,
                        error,
                    });
                }
            },

            async triggerOpenModal({ leadId, communication = null }) {
                this.leadId = leadId;
                this.lead = await this.fetchLead(leadId);

                if (
                    (!this.authUser.hasNioText() && !this.leadAccountHasNiotext()) ||
                    this.$wait.is('validating.leadPhones')
                ) {
                    return;
                }

                // Make sure lead can be contacted
                if (this.lead.unsubscribe_all_date) {
                    return;
                }

                if (communication && communication.user) {
                    this.communicationUserId = communication.user.parent_user_id || communication.user.id;
                }

                // Clicked on communication, or already validated. Skip validation.
                if (communication || this.hasValidatedMobile(this.lead)) {
                    this.openModal();
                    return;
                }

                // All phones are already validated...
                if (!this.hasNotValidatedPhone(this.lead)) {
                    return;
                }

                this.validatePhones();
            },

            async validatePhones() {
                this.$wait.start('validating.leadPhones');

                const validPhones = await this.validateLeadPhones(this.lead.id);

                if (validPhones) {
                    this.openModal();
                } else {
                    showWarning(this.$t('client.noMobilePhone'));
                }

                this.$wait.end('validating.leadPhones');
            },

            close() {
                this.opened = false;
            },

            onOpen() {
                this.initIframe();
            },

            onClosed() {
                this.removeCommunicating();
                this.loading = true;
                this.communicationUserId = null;
                this.lead = new Lead();
                this.leadId = null;

                if (this.nioTextIframe) {
                    this.nioTextIframe.close();
                    this.nioTextIframe = null;
                }
            },
        },

        created() {
            this.$eventBus.$on('open-sms-modal', this.triggerOpenModal);
        },

        beforeDestroy() {
            this.$eventBus.$off('open-sms-modal', this.triggerOpenModal);

            this.$wait.end('validating.leadPhones');
        },
    };
</script>
