import Model from './Model.js';
import Lead from './Lead.js';
import User from './User.js';
import Reminder from './Reminder.js';

export default class TaskEvent extends Model {
    static get defaults() {
        return {
            canceled: false,
            confirmed: false,
            description: '',
            end_at: null,
            guests: [],
            lead: {},
            main_event: false,
            no_show: false,
            priority: 'normal',
            reminders: [],
            start_at: null,
            status: false,
            task_event_type_id: null,
            title: '',
            user: {},
            user_id: null,
            confirmation_timeframe: null,
            send_ics: false,
        };
    }

    /**
     * Getters
     */

    get lead() {
        return this._lead;
    }

    get user() {
        return this._user;
    }

    get guests() {
        return this._guests;
    }

    get reminders() {
        return this._reminders;
    }

    /**
     * Setters
     */

    set lead(lead) {
        this._lead = new Lead(lead);
    }

    set user(user) {
        this._user = new User(user);
    }

    set guests(guests) {
        this._guests = guests
            .map(guest => new User(guest))
            .sort((a, b) => {
                return a.fullName.localeCompare(b.fullName);
            });
    }

    set reminders(reminders) {
        this._reminders = reminders.map(reminder => new Reminder(reminder));
    }

    /**
     * Specifications
     */

    //
}
