import { cloneDeep, has } from 'lodash-es';
import Lead from '../../../entities/Lead.js';
import Moment from '../../../value-objects/Moment.js';
import ActivixDate from '../../../value-objects/ActivixDate.js';

export default {
    updatePagination(key, value) {
        const updatedPagination = cloneDeep(this.pagination);

        updatedPagination[key] = value;

        this.pagination = updatedPagination;
    },
    setDashboardConfigs(configs, overwrite = false) {
        const newConfigs = {};

        Object.keys(configs).forEach(key => {
            if (has(configs, `${key}.__ob__`)) {
                configs[key] = JSON.parse(JSON.stringify(configs[key]));
            }

            if (overwrite || ['filters', 'columnVisibilities', 'hotSwapDate', 'dates'].includes(key)) {
                newConfigs[key] = configs[key];
            } else {
                newConfigs[key] = { ...this.configs[key], ...configs[key] };
            }
        });

        this.configs = { ...this.configs, ...newConfigs };
    },
    setAllLeadsInTableSelected(allLeadsInTableSelected) {
        if (!allLeadsInTableSelected) {
            this.bulkSelect = [];
            this.blackListedLeadIds = [];
        }

        this.allLeadsInTableSelected = allLeadsInTableSelected;
    },
    setBulkSelect(bulkSelect) {
        if (!bulkSelect.length) {
            this.dashboardSelect = false;
        }

        this.bulkSelect = Object.freeze(bulkSelect);
    },
    setDashboardTableLeads(leads) {
        this.tableLeads = leads.map(lead => new Lead(lead));
    },
    setStartDate(startDate) {
        this.startDate = Moment.asLocale(startDate, 'startDate').toDateString();
    },
    setEndDate(endDate) {
        this.endDate = Moment.asLocale(endDate, 'endDate').toDateString();
    },
    setStartContractDateStore(startContractDate) {
        this.startContractDate = Moment.init(startContractDate, 'date').toString();
    },
    appendRenewalAlertDateInFilters(notificationDate) {
        if (!notificationDate) {
            return;
        }
        this.configs.filters.renewal_alert_date = new ActivixDate(notificationDate).toHumanShort(false);
    },
};
