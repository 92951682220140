export default {
    accountManagement: 'Comptes',
    userManagement: 'Utilisateurs',

    sidebar: {
        webOrderBadgeTooltip: 'Commande web active en attente | Commandes web actives en attente',
        activityReport: '@:dashboards.activityReport',
        allLead: '@:dashboards.allLead',
        automations: 'Automatisations',
        commercial: 'Dir. commercial',
        duplicates: 'Doublons',
        event: '@:dashboards.event',
        flow: 'Procédure',
        headers: {
            client: 'Client',
            management: 'Gestion',
            tools: 'Outils',
        },
        import: 'Importation',
        inTurn: 'Tour de rôle',
        leadXpress: '@:dashboards.leadXpress',
        loyalty: '@:dashboards.loyalty',
        objectives: 'Objectifs',
        phoneUp: '@:dashboards.phoneUp',
        renewal: '@:dashboards.renewal',
        templates: 'Modèles',
        saleTable: '@:dashboards.saleTable',
        schedule: 'Horaire',
        service: '@:dashboards.service',
        trash: 'Corbeille',
        walkIn: '@:dashboards.walkIn',
        webBoost: '@:dashboards.webBoost',
        webOrder: '@:dashboards.webOrder',
    },
    userDropdown: {
        accounts: 'Comptes',
        changelog: 'Mise à jour',
        disconnect: 'Déconnexion',
        groups: 'Groupes',
        training: 'Formation',
        help: 'Aide',
        myAccount: 'Mon compte',
        myProfile: 'Mon profil',
        suggestions: 'Suggestions',
        users: 'Utilisateurs',
        turnOffNotifications: 'Suspendre les notifications',
        turnOffNotificationsDetails: "Voulez-vous suspendre la réception de notification et les rediriger vers l'écran ?",
        turningOnNotifications: 'Les notifications ont été réactivées.',
    },
};
