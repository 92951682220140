import Division from '../entities/Division.js';

export default [
    {
        path: '/schedule',
        name: 'schedule',
        component: () => import(/* webpackChunkName: "schedule" */ '../views/Schedule.vue'),
        meta: {
            fixedContent: true,
            accountSelect: {
                disabled: false,
                showParentAccounts: false,
            },
            header: {
                account: true,
                division: [Division.NEW, Division.USED, Division.FINANCE, Division.SERVICE],
                notification: true,
                search: true,
                settings: true,
            },
            saveInUrl: ['accountId'],
        },
    },
];
