import User from '../entities/User.js';
import Division from '../entities/Division.js';
import i18n from '../plugins/vue-i18n.js';

export default {
    pageTitle: '',
    accounts: [],
    featurePreviews: [],
    parentAuthUser: new User(),
    configs: {
        locale: i18n.locale, // Mainly used for Web Workers
        skipCasts: [],
        namingConventions: [],
        provinces: {
            CA: ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'QC', 'PE', 'SK', 'YT'],
            US: ['AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FM', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MH', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'PW', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'],
        },
        countries: ['CA', 'US'],
        timezone: 'UTC',
        timezones: [
            'America/Edmonton',
            'America/Halifax',
            'America/Iqaluit',
            'America/Moncton',
            'America/Montreal',
            'America/Regina',
            'America/St_Johns',
            'America/Toronto',
            'America/Vancouver',
            'America/Whitehorse',
            'America/Winnipeg',
            'America/Yellowknife',

            'America/New_York',
            'America/Chicago',
            'America/Denver',
            'America/Phoenix',
            'America/Los_Angeles',
            'America/Anchorage',
            'America/Adak',
            'Pacific/Honolulu',
        ],
        provinceTimezones: {
            AB: 'America/Edmonton',
            BC: 'America/Vancouver',
            MB: 'America/Winnipeg',
            NB: 'America/Moncton',
            NL: 'America/St_Johns',
            NT: 'America/Yellowknife',
            NS: 'America/Halifax',
            NU: 'America/Iqaluit',
            ON: 'America/Toronto',
            QC: 'America/Montreal',
            PE: 'America/Halifax',
            SK: 'America/Regina',
            YT: 'America/Whitehorse',

            AZ: 'America/Phoenix',
            AK: 'America/Anchorage',
            CA: 'America/Los_Angeles',
            CO: 'America/Denver',
            IL: 'America/Chicago',
            HI: 'Pacific/Honolulu',
            NY: 'America/New_York',
        },
        automatableModels: [],
        automatableEvents: [],
        automatableCriteriaFields: [],
        automatableTriggerFields: [],
        automatableActions: [],
        casts: {},
        dateCasts: {},
        divisions: [],
        leadTypes: [],
        leadBanks: [],
        accidentedParts: [],
        leadForms: [],
        taskEventTypes: [],
        serviceProducts: [],
    },
    divisions: [
        { id: Division.NEW, name: i18n.t('divisions.new') },
        { id: Division.USED, name: i18n.t('divisions.used') },
        { id: Division.SERVICE, name: i18n.t('divisions.service') },
        { id: Division.FINANCE, name: i18n.t('divisions.finance') },
    ],
    groups: [],

    errors: [],
    attachmentParameters: {
        maximumAttachmentSize: 18, // In Megabytes
        blacklistedFileTypes: [
            'ade',
            'adp',
            'avi',
            'bat',
            'chm',
            'cmd',
            'com',
            'cpl',
            'exe',
            'flv',
            'hta',
            'ins',
            'isp',
            'jar',
            'jse',
            'lib',
            'lnk',
            'm2v',
            'm4v',
            'm4v',
            'mde',
            'mkv',
            'mov',
            'mp4',
            'mpeg',
            'mpg',
            'msc',
            'msp',
            'mst',
            'ogg',
            'ogv',
            'pif',
            'scr',
            'sct',
            'shb',
            'sys',
            'vb',
            'vbe',
            'vbs',
            'vob',
            'vxd',
            'webm',
            'wmv',
            'wsc',
            'wsf',
            'wsh',
        ],
        whitelistedFileTypes: [
            'doc',
            'docx',
            'xls',
            'xlsx',
            'ppt',
            'pps',
            'wp',
            'txt',
            'rtf',
            'pdf',
            'gif',
            'jpg',
            'png',
            'htm',
            'html',
            'zip',
            'rar',
            'tar',
            'csv',
        ],
    },
    leads: [],
    communicatingLeads: [],
    mergedLead: null,
    specificPhoneToCall: null,
    resultDetails: {
        roadTest: {
            format: 'DD MMM YYYY',
            default: true,
        },
        beBack: {
            format: 'DD MMM YYYY',
            default: true,
        },
        takeOver: {
            format: 'DD MMM YYYY',
            default: true,
        },
        appointment: {
            format: 'DD MMM YYYY HH:mm',
            default: false,
        },
        apptCall: {
            format: 'DD MMM YYYY HH:mm',
            default: false,
        },
        apptConfirmation: {
            format: 'DD MMM YYYY HH:mm',
            default: true,
        },
        presented: {
            format: 'DD MMM YYYY HH:mm',
            default: true,
        },
        sale: {
            format: 'DD MMM YYYY',
            default: true,
        },
        delivered: {
            format: 'DD MMM YYYY HH:mm',
            default: true,
        },
        refinanced: {
            format: 'DD MMM YYYY HH:mm',
            default: true,
        },
        csi: {
            format: 'DD MMM YYYY',
            default: true,
        },
        recorded: {
            format: 'DD MMM YYYY',
            default: true,
        },
        delivery: {
            format: 'DD MMM YYYY HH:mm',
            default: true,
        },
        available: {
            format: 'DD MMM YYYY HH:mm',
            default: true,
        },
        paperwork: {
            format: 'DD MMM YYYY HH:mm',
            default: true,
        },
        discounted: {
            format: 'DD MMM YYYY',
            default: true,
        },
        deliverable: {
            format: 'DD MMM YYYY HH:mm',
            default: true,
        },
    },
    taskEventToBeCompleted: null,
    webOrderBadgeCount: 0,
    templateAttributes: [],
};
