<template>
    <div :class="{ loading: communicating }" v-if="!callInProgress && !loading">
        <div class="-mb-2">
            <p>
                {{ lead.lead_phones.length ? $t('call.toNumber') : $t('call.toNumber2') }}
            </p>

            <communication-method-selection
                class="mb-2 space-y-2"
                name="leadPhone"
                :disabled="communicating"
                :items="leadPhoneOptions"
                :value="leadPhone"
                @input="$emit('update:leadPhone', $event)"
            />
        </div>

        <hr />

        <div class="-mb-2">
            <p>
                {{ currentUser.phones.length ? $t('call.fromNumber') : $t('call.fromNumber2') }}
            </p>

            <communication-method-selection
                class="mb-2 space-y-2"
                name="userPhone"
                :disabled="communicating"
                :items="userPhoneOptions"
                :value="userPhone"
                @input="$emit('update:userPhone', $event)"
            />
        </div>
    </div>
</template>

<script>
    import CommunicationMethodSelection from '@/components/CommunicationMethodSelection.vue';

    export default {
        components: {
            CommunicationMethodSelection,
        },
        props: {
            leadPhoneOptions: {
                type: Array,
                required: true,
            },
            userPhoneOptions: {
                type: Array,
                required: true,
            },
            leadPhone: {
                validator: prop => typeof prop === 'number' || prop === null,
                required: true,
            },
            userPhone: {
                validator: prop => typeof prop === 'number' || prop === null,
                required: true,
            },
            communicating: {
                type: Boolean,
                required: true,
            },
            callInProgress: {
                type: Boolean,
                required: true,
            },
            loading: {
                type: Boolean,
                required: true,
            },
            lead: {
                type: Object,
                required: true,
            },
            currentUser: {
                type: Object,
                required: true,
            },
        },
    };
</script>
