import { mapState } from 'pinia';

// Entities
import LeadType from '../entities/LeadType.js';
import Division from '../entities/Division.js';
import { useGlobalStore } from '../store/store.js';

export default {
    computed: {
        ...mapState(useGlobalStore, ['authUser']),

        isWalkIn() {
            return !!this.newLead && this.newLead.leadType == LeadType.WALK_IN;
        },

        isValidPhone() {
            if (!this.newLead || !this.newLead.phone) {
                return false;
            }

            const stripedPhone = this.newLead.phone.replace('+1', '');

            return this.validatePhone(stripedPhone.trim());
        },

        isValidEmail() {
            if (!this.newLead || !this.newLead.email) {
                return false;
            }

            return this.emailIsValid(this.newLead.email.trim());
        },

        isService() {
            return !!this.newLead && !!(this.newLead.division && this.newLead.division == Division.SERVICE);
        },

        isValidLeadType() {
            return !!this.newLead && !!this.newLead.leadType;
        },

        isValidClientName() {
            return !!this.newLead && !!this.newLead.name && this.newLead.name.length > 2;
        },

        selectedAccount() {
            return (
                this.accountOptions.find(account => !!this.newLead && account.id == this.newLead.childAccountId) ||
                this.account
            );
        },

        showSource() {
            return !this.isRenewal;
        },

        showBdcAgent() {
            return (
                this.selectedAccount.bdc_advisor &&
                !!this.newLead &&
                this.newLead.leadType != null &&
                !this.isWalkIn
            );
        },

        sources() {
            const sources = [];

            for (const source of this.selectedAccount.sourcesWithProvider || []) {
                if (source.lead_type_id && this.newLead.leadType && source.lead_type_id != this.newLead.leadType) {
                    continue;
                }

                sources.push(source);
            }

            sources.sort((a, b) => {
                if (
                    a.lead_type_id == b.lead_type_id ||
                    (a.lead_type_id != this.newLead.leadType && b.lead_type_id != this.newLead.leadType)
                ) {
                    return a.name.localeCompare(b.name);
                }

                if (a.lead_type_id == this.newLead.leadType) {
                    return -1;
                }

                if (b.lead_type_id == this.newLead.leadType) {
                    return 1;
                }

                return 0;
            });

            return sources;
        },

        isRenewal() {
            return !!this.newLead && this.newLead.leadType == LeadType.RENEWAL;
        },
    },

    methods: {
        formatPhoneNumber(phone = '', addDashes = false) {
            if (!phone) {
                return '';
            }

            // Remove extention
            if (phone.indexOf('x') > 1) {
                phone = phone.substring(0, phone.indexOf('x') - 1);
            }

            // Remove any non digit character
            phone = phone.replace(/\D/g, '');

            // Remove leading '1' in saved string (automatically added by component)
            if (phone.charAt(0) == 1) {
                phone = phone.slice(1);
            }

            if (addDashes && phone.length == 10) {
                phone = `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, 10)}`;
            }

            return phone;
        },
    },
};
