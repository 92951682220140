export default {
    account: {
        singular: 'compte',
    },
    civility: {
        singular: 'civilité',
    },
    presented: {
        singular: 'visite',
    },
    province: {
        CA: 'province',
    },
    renewal: {
        singular: 'renouvellement',
        plural: 'renouvellements',
        prefix: 'le renouvellement',
        abr: 'renouv.',
    },
    renewed: {
        infinitive: 'renouveler',
        singular: 'renouvelé',
        plural: 'renouvelés',
    },
    result: {
        singular: 'résultat',
    },
    sale: {
        singular: 'vente',
        plural: 'ventes',
        prefix: 'une vente',
    },
    sold: {
        singular: 'vendu',
        plural: 'vendus',
    },
    version: {
        singular: 'version',
    },
    walkIn: {
        singular: 'walk-in',
        plural: 'walk-in',
        prefix: 'a walk-in',
        abr: 'walk-in',
    },
};
