import { cloneDeep, isEqual, isArray, isEmpty } from 'lodash-es';
import deepmerge from 'deepmerge';
import { merge } from '../utils/index.js';

export default class Model {
    constructor(initialValues) {
        initialValues = Object.entries(initialValues || {}).reduce((result, [key, value]) => {
            if (key.indexOf('_') === 0) {
                key = key.substr(1);
            }

            result[key] = value;
            return result;
        }, {});

        const values = deepmerge(this.constructor.defaults, initialValues);

        this.init(values);
    }

    init(values) {
        /**
         * .sort() was added to make sure _property was set first to avoid overwiting the accessor after setting it
         */
        for (const [field, value] of Object.entries(values)) {
            this[field] = value;
        }
    }

    reset() {
        if (this.defaults) {
            merge(this, this.defaults);
        }
    }

    clone() {
        return cloneDeep(this);
    }

    isEmpty() {
        for (const [field, value] of Object.entries(this.constructor.defaults)) {
            if (!isEqual(this[field], value)) {
                return false;
            }
        }

        return true;
    }

    isNotEmpty() {
        return !this.isEmpty();
    }

    /**
     * Caching and object pooling
     */

    relationLoaded(relation) {
        const relationKey = `_${relation}`;

        return this[relationKey] instanceof Model && this[relationKey].id;
    }

    loaded(attribute, property = null) {
        const value = this[`_${attribute}_cache`];

        if (isEmpty(value)) {
            return false;
        }

        const valueModel = isArray(value) ? value[0] : value;

        if (!(valueModel instanceof Model)) {
            return false;
        }

        if (!property) {
            return true;
        }

        return valueModel.id == this[property];
    }

    load(attribute) {
        return this.loaded(attribute) ? this[`_${attribute}_cache`] : null;
    }

    store(attribute, data) {
        this[`_${attribute}_cache`] = data;
        return data;
    }

    free(attribute) {
        this[`_${attribute}_cache`] = null;
        delete this[`_${attribute}_cache`];
    }
}
