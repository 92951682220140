<template>
    <nav class="flex-grow flex-shrink-0 flex flex-col mt-8 mb-4 | print:hidden">
        <!-- CLIENT -->
        <main-nav-header :content="$t('navs.sidebar.headers.client')" />

        <!--All Lead-->
        <main-nav-item
            icon="regular/house-chimney-2"
            :to="{ name: 'dashboards.allLead', query: dashboardQuery }"
            :title="$t('navs.sidebar.allLead')"
            v-show="parentAuthUser.hasAccessTo('dashboards.allLead')"
        />

        <!-- Web Order -->
        <main-nav-item
            :icon="$icons.webOrder"
            :module-active="contextAccount.hasOption('web_order')"
            :to="{ name: 'dashboards.webOrder', query: dashboardQuery }"
            :title="$t('navs.sidebar.webOrder')"
            :badge="webOrderBadgeCount"
            :badge-tooltip="$tc('navs.sidebar.webOrderBadgeTooltip', webOrderBadgeCount)"
            v-show="parentAuthUser.hasAccessTo('dashboards.webOrder')"
        />

        <!-- LeadXpress -->
        <main-nav-item
            :icon="$icons.leadXpress"
            :module-active="contextAccount.hasOption('leadxpress')"
            :to="{ name: 'dashboards.leadXpress', query: dashboardQuery }"
            :title="$t('navs.sidebar.leadXpress')"
            v-show="parentAuthUser.hasAccessTo('dashboards.leadXpress')"
        />

        <!-- Walk-in -->
        <main-nav-item
            :icon="$icons.visit"
            :module-active="contextAccount.hasOption('walk_in')"
            :fill-icon="true"
            :to="{ name: 'dashboards.walkIn', query: dashboardQuery }"
            :title="$t('navs.sidebar.walkIn')"
            v-show="parentAuthUser.hasAccessTo('dashboards.walkIn')"
        />

        <!-- Phone-up -->
        <main-nav-item
            icon="regular/phone"
            :module-active="contextAccount.hasOption('phone_up')"
            :to="{ name: 'dashboards.phoneUp', query: dashboardQuery }"
            :title="$t('navs.sidebar.phoneUp')"
            v-show="parentAuthUser.hasAccessTo('dashboards.phoneUp')"
        />

        <!-- Renewal -->
        <main-nav-item
            :icon="$icons.renewal"
            :module-active="contextAccount.hasOption('renewal')"
            :to="{ name: 'dashboards.renewal', query: dashboardQuery }"
            :title="$t('navs.sidebar.renewal')"
            v-show="parentAuthUser.hasAccessTo('dashboards.renewal')"
        />

        <!-- Loyalty -->
        <main-nav-item
            :icon="$icons.loyalty"
            :module-active="contextAccount.hasOption('loyalty')"
            :to="{ name: 'dashboards.loyalty', query: dashboardQuery }"
            :title="$t('navs.sidebar.loyalty')"
            v-show="parentAuthUser.hasAccessTo('dashboards.loyalty')"
        />

        <!-- Service -->
        <main-nav-item
            :icon="$icons.service"
            :flip-icon="true"
            :module-active="contextAccount.hasOption('service')"
            :to="{ name: 'dashboards.service', query: dashboardQuery }"
            :title="$t('navs.sidebar.service')"
            v-show="parentAuthUser.hasAccessTo('dashboards.service')"
        />

        <!-- TOOLS -->
        <main-nav-header class="mt-12" :content="$t('navs.sidebar.headers.tools')" />

        <!-- Sale Table -->
        <main-nav-item
            icon="regular/award-trophy-star-1"
            :module-active="contextAccount.hasOption('sale_table')"
            :to="{ name: 'dashboards.saleTable', query: dashboardQuery }"
            :title="$t('navs.sidebar.saleTable')"
            :force-opened="$route.name == 'objectives.saleTable'"
            v-show="parentAuthUser.hasAccessTo('dashboards.saleTable')"
        >
            <main-nav-sub-item
                icon="regular/target-center-1"
                :to="{ name: 'objectives.saleTable' }"
                :active="$route.name == 'objectives.saleTable'"
                :title="$t('navs.sidebar.objectives')"
                v-if="parentAuthUser.hasAccessTo('objectives.saleTable')"
            />
        </main-nav-item>

        <!-- In Turn -->
        <main-nav-item
            :icon="$icons.inTurn"
            :module-active="contextAccount.hasOption('in_turn')"
            :to="{ name: 'inTurn.management' }"
            :title="$t('navs.sidebar.inTurn')"
            v-show="parentAuthUser.hasAccessTo('inTurn.management')"
        />

        <!-- WebBoost -->
        <main-nav-item
            :icon="$icons.webBoost"
            :module-active="contextAccount.hasOption('webboost')"
            :to="{ name: 'dashboards.webBoost', query: dashboardQuery }"
            :title="$t('navs.sidebar.webBoost')"
            v-show="parentAuthUser.hasAccessTo('dashboards.webBoost')"
        />

        <!-- Commercial -->
        <main-nav-item
            icon="regular/credit-card-1"
            :module-active="contextAccount.hasOption('commercial')"
            :to="{ name: 'dashboards.commercial', query: dashboardQuery }"
            :title="$t('navs.sidebar.commercial')"
            v-show="parentAuthUser.hasAccessTo('dashboards.commercial')"
        />

        <!-- NioText -->
        <div class="nav-item" :module-active="contextAccount.hasOption('niotext')" v-if="hasAccessToNioText">
            <a class="nav-link" target="_blank" :href="nioTextUrl">
                <icon class="nav-icon" name="regular/messages-bubble-typing-1" />
                <span>NioText</span>
            </a>
        </div>

        <!-- Deskit -->
        <div class="nav-item" v-if="hasDeskitSupplier">
            <a class="nav-link" target="_blank" :href="deskitUrl">
                <icon class="nav-icon" name="regular/accounting-calculator" />
                <span>Deskit</span>
            </a>
        </div>

        <!-- Event -->
        <main-nav-item
            :icon="$icons.event"
            :module-active="contextAccount.hasOption('event')"
            :to="{ name: 'dashboards.event', query: dashboardQuery }"
            :title="$t('navs.sidebar.event')"
            v-show="parentAuthUser.hasAccessTo('dashboards.event')"
        />

        <!-- Activity Report -->
        <main-nav-item
            icon="regular/graph-stats"
            :module-active="contextAccount.hasOption('activity_report')"
            :to="{ name: 'dashboards.activityReport', query: dashboardQuery }"
            :title="$t('navs.sidebar.activityReport')"
            :force-opened="$route.name == 'objectives.activityReport'"
            v-show="parentAuthUser.hasAccessTo('dashboards.activityReport')"
        >
            <main-nav-sub-item
                icon="regular/target-center-1"
                :to="{ name: 'objectives.activityReport' }"
                :active="$route.name == 'objectives.activityReport'"
                :title="$t('navs.sidebar.objectives')"
                v-if="parentAuthUser.hasAccessTo('objectives.activityReport')"
            />
        </main-nav-item>

        <template v-if="!mdLayout">
            <!-- MANAGEMENT -->
            <main-nav-header class="mt-12" :content="$t('navs.sidebar.headers.management')" />

            <!-- Response template -->
            <main-nav-item
                icon="regular/content-pencil-write"
                :to="{ name: 'templates.index' }"
                :title="$t('navs.sidebar.templates')"
                v-show="parentAuthUser.hasAccessTo('templates.index')"
            />

            <!-- Automation -->
            <main-nav-item
                icon="regular/video-game-magic-wand"
                :module-active="contextAccount.hasOption('automation')"
                :to="{ name: parentAuthUser.hasAccessTo('automations.index') ? 'automations.index' : '' }"
                :title="$t('navs.sidebar.automations')"
                v-show="parentAuthUser.hasAccessTo('automations.index')"
            />

            <!-- Schedule -->
            <main-nav-item
                icon="regular/calendar-clock"
                :to="{ name: 'schedule' }"
                :title="$t('navs.sidebar.schedule')"
                v-show="parentAuthUser.hasAccessTo('schedule')"
            />

            <!-- Flow -->
            <main-nav-item
                icon="regular/filter-1"
                :to="{ name: 'flow' }"
                :title="$t('navs.sidebar.flow')"
                :force-opened="$route.name.startsWith('flow')"
                :force-active="$route.name.startsWith('flow')"
                v-show="parentAuthUser.hasAccessTo('flow')"
            />

            <!-- Duplicates -->
            <main-nav-item
                :icon="$icons.duplicate"
                :module-active="contextAccount.hasOption('duplicates')"
                :to="{ name: 'duplicates' }"
                :title="$t('navs.sidebar.duplicates')"
                v-show="parentAuthUser.hasAccessTo('duplicates')"
            />

            <!-- Import -->
            <main-nav-item
                icon="regular/cloud-upload"
                :to="{ name: 'imports.index' }"
                :title="$t('navs.sidebar.import')"
                v-show="parentAuthUser.hasAccessTo('imports.index')"
            />

            <!-- Trashed lead -->
            <main-nav-item
                icon="regular/bin-paper"
                :to="{ name: 'leads.trash' }"
                :title="$t('navs.sidebar.trash')"
                v-show="parentAuthUser.hasAccessTo('leads.trash')"
            />
        </template>

        <div class="flex-1 flex items-end justify-center mt-4">
            <icon class="text-3xl link-grey-light" name="regular/question-circle" @click="openFreshdesk" />
        </div>
    </nav>
</template>

<script>
    import { mapState } from 'pinia';
    import MainNavHeader from './MainNavHeader.vue';
    import MainNavItem from './MainNavItem.vue';
    import MainNavSubItem from './MainNavSubItem.vue';
    import FreshdeskUrl from '../../mixins/FreshdeskUrl.js';
    import { deskitSsoUrl } from '../../utils/index.js';

    // Entities
    import Supplier from '../../entities/Supplier.js';

    // Pinia
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'MainNav',

        components: {
            MainNavHeader,
            MainNavItem,
            MainNavSubItem,
        },

        mixins: [FreshdeskUrl],

        computed: {
            ...mapState(useGlobalStore, {
                webOrderBadgeCount: 'webOrderBadgeCount',
                parentAuthUser: 'parentAuthUser',
                configs: 'configs',
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),
            ...mapState(useDashboardStore, ['dashboardType']),

            nioTextUrl() {
                return `${process.env.VUE_APP_NIOTEXT_URL}#/login?token=${this.parentAuthUser.niotext_token}`;
            },

            deskitUrl() {
                return deskitSsoUrl();
            },

            hasAccessToNioText() {
                return this.parentAuthUser.hasNioText() || this.parentAuthUser.children.some(user => user.hasNioText());
            },

            hasDeskitSupplier() {
                return this.contextAccount.suppliers.some(supplier => supplier.id === Supplier.DESKIT);
            },

            dashboardQuery() {
                if (!this.$route.name?.includes('dashboards')) {
                    return null;
                }

                return { ...this.$route.query, viewId: null };
            },
        },
    };
</script>
