<template>
    <transition name="fade">
        <div class="absolute h-screen w-screen flex items-center justify-center" key="spinner" v-if="showLoading">
            <activix-spinner :message="$t('general.loading')" />
        </div>
        <div class="h-full min-h-screen flex flex-col" key="content" v-else>
            <!-- Banners -->
            <status-banner />
            <announcement-banner />

            <div class="flex-1 relative flex">
                <main-nav-container v-if="includeInLayout('nav') && !$browser.isMobileWebView()" />

                <main
                    class="flex-1 flex flex-col w-0 | transition-spacing duration-300"
                    :class="{
                        'lg:ml-64': navIsVisible,
                        'max-h-screen': $route.meta.fixedContent,
                    }"
                >
                    <main-header v-if="includeInLayout('header')" />
                    <div
                        class="flex-1 flex | transition-spacing duration-300"
                        :class="sidebar.opened && sidebar.push ? 'md:mr-80 xl:mr-96 2xl:mr-128' : 'mr-0'"
                    >
                        <portal-target
                            :style="leftSidebarStyle"
                            class="sticky left-0 top-0"
                            slim
                            name="left-sidebar"
                        />
                        <div
                            class="relative flex-1 max-w-full flex flex-col | print:p-0"
                            :class="{
                                'p-3 | md:p-6': $feature.isEnabled('new-lead-page'),
                                'py-6 | lg:px-6': !$feature.isEnabled('new-lead-page'),
                            }"
                        >
                            <content-header v-if="includeInLayout('contentHeader')" />
                            <router-view :right-sidebar-opened="sidebar.opened" class="relative flex-1" />
                            <portal-target name="content-footer" slim />

                            <div class="sticky inset-x-0 bottom-6 flex z-30 pointer-events-none">
                                <portal-target name="anchor-bottom-left" class="pointer-events-auto" slim />
                                <portal-target name="anchor-bottom-right" class="ml-auto pointer-events-auto" slim />
                            </div>
                        </div>
                    </div>
                    <right-sidebar :opened.sync="sidebar.opened" :push.sync="sidebar.push" />
                </main>
            </div>

            <!-- Functional components -->
            <listen-to-broadcasting />

            <!-- Screen Pops -->
            <screen-pop v-if="!$browser.isMobileWebView()" />
            <screen-pop-video-conference v-if="!$browser.isMobileWebView()" />

            <!-- Modals -->
            <sms-lead />
            <confidentiality-agreement />
            <modal-event />
            <activix-confirm-modal
                portal="modal-event-sub"
                :content="$t('modal.createAppointmentConfirmMessage')"
                :opened.sync="createAppointmentModal.opened"
                :title="$t('modal.createAppointment')"
                @approve="openAppointmentModal"
            />
        </div>
    </transition>
</template>

<script>
    // Libraries
    import Pusher from 'pusher-js'; // eslint-disable-line no-unused-vars
    import { mapActions, mapState } from 'pinia';
    import { gql } from 'apollo-boost';
    import IntercomClient from '../plugins/intercom.js';
    import { isValidNumber } from '../utils/numbers.js';

    import TaskEventType from '../entities/TaskEventType.js';
    import FreshdeskUrl from '../mixins/FreshdeskUrl.js';

    // Components
    import AnnouncementBanner from '../components/AnnouncementBanner.vue';
    import ConfidentialityAgreement from '../components/modals/ConfidentialityAgreement.vue';
    import ContentHeader from '../components/ContentHeader.vue';
    import ListenToBroadcasting from '../components/utils/ListenToBroadcasting.js';
    import MainHeader from '../components/MainHeader.vue';
    import MainNavContainer from '../components/MainNavContainer.vue';
    import RightSidebar from '../components/RightSidebar.vue';
    import ModalEvent from '../components/modals/ModalEvent.vue';
    import SmsLead from '../components/modals/SmsLead.vue';
    import ScreenPop from '../components/modals/ScreenPop.vue';
    import ScreenPopVideoConference from '../components/modals/ScreenPopVideoConference.vue';
    import StatusBanner from '../components/StatusBanner.vue';

    // Pinia
    import { useContextStore } from '../store/modules/context/store.js';
    import { useLayoutStore } from '../store/modules/layout/store.js';
    import { useGlobalStore } from '../store/store.js';

    export default {
        components: {
            AnnouncementBanner,
            ConfidentialityAgreement,
            ContentHeader,
            ListenToBroadcasting,
            MainHeader,
            MainNavContainer,
            RightSidebar,
            ModalEvent,
            SmsLead,
            ScreenPop,
            ScreenPopVideoConference,
            StatusBanner,
        },

        mixins: [FreshdeskUrl],

        metaInfo() {
            return {
                title: this.$route.name ? this.$t(`titles.${this.$route.name}`) : '',
            };
        },
        apollo: {
            accounts: gql`query {
                accounts {
                    account_manager
                    active
                    id
                    name
                }
              }`,
        },
        data() {
            return {
                loaded: false,
                createAppointmentModal: {
                    opened: false,
                    payload: {},
                },
                sidebar: {
                    opened: false,
                    push: false,
                },
                accounts: [],
            };
        },

        computed: {
            ...mapState(useLayoutStore, ['navOpened', 'bodySpacingTop', 'headerHeight']),
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextUser: 'user',
                contextGroup: 'group',
            }),

            navIsVisible() {
                return this.navOpened && this.includeInLayout('nav') && !this.$browser.isMobileWebView();
            },

            showLoading() {
                return !this.loaded || this.$route.meta.auth === false || !this.authUser.id;
            },

            contextQuery() {
                const accountId = this.canSaveInUrl('accountId') ? this.contextAccount.id : null;
                const groupId = this.canSaveInUrl('groupId') ? this.contextGroup.id : null;
                const userId = this.canSaveInUrl('userId') ? this.contextUser.id : null;

                return {
                    accountId,
                    groupId,
                    userId,
                };
            },

            leftSidebarStyle() {
                const top = this.headerHeight + this.bodySpacingTop;

                return {
                    top: `${top}px`,
                    height: `calc(100vh - ${top}px)`,
                };
            },
        },

        watch: {
            '$route.name': {
                immediate: true,
                handler() {
                    this.verifyAccess();
                    IntercomClient.ping();
                },
            },

            accounts: {
                immediate: true,
                handler(accounts) {
                    if (accounts.length) {
                        this.setAccounts(accounts);
                    }
                },
            },

            showLoading: {
                immediate: true,
                handler(showLoading) {
                    if (showLoading) {
                        return;
                    }

                    this.$nextTick(() => {
                        IntercomClient.init(this.authUser);
                    });
                },
            },

            contextQuery(newContextQuery) {
                this.updateQuery(newContextQuery);
            },

            'contextAccount.id': {
                immediate: true,
                handler(newAccountId, oldAccountId) {
                    if (!newAccountId || newAccountId == oldAccountId) {
                        return;
                    }

                    this.verifyAccess();

                    this.$broadcasting.accountChanged(newAccountId, oldAccountId);
                },
            },

            'authUser.confidentiality_agreement'() {
                IntercomClient.init(this.authUser);
            },
        },

        methods: {
            ...mapActions(useLayoutStore, ['setBodyMarginTop', 'setBodyPaddingTop']),
            ...mapActions(useContextStore, [
                'setContextDivisionId',
                'setContextTeamId',
                'setContextAccountAction',
                'setContextGroupAction',
                'setContextLeadIdAction',
                'setContextUserAction',
            ]),
            ...mapActions(useGlobalStore, [
                'setGroups',
                'setAccounts',
            ]),

            async loadInitialData() {
                await Promise.all([this.fetchGroups()]);
                this.$api.authUser.triggerScreenPop();
            },

            async fetchGroups() {
                const groups = await this.$api.groups.index();

                this.setGroups(groups);
            },

            async setContextData() {
                const query = this.getUrlQuery();
                const storage = this.$ls.get('context', {});

                const context = {
                    accountId: isValidNumber(query.accountId) ? query.accountId : storage.accountId,
                    groupId: isValidNumber(query.groupId) ? query.groupId : storage.groupId,
                    userId: isValidNumber(query.userId) ? query.userId : storage.userId,
                };

                this.setContextLeadIdAction(null);
                this.setContextDivisionId(storage.divisionId);
                this.setContextTeamId(storage.teamId);

                if (this.$route.name == 'leads.update') {
                    return;
                }

                if (this.$route.name == 'users.update' && this.authUser.hasUserAccess(this.$route.params.id)) {
                    this.setContextUserAction(this.$route.params.id);
                    return;
                }

                this.$wait.start('fetching.contextData');

                await this.setContextGroupAction(context.groupId);

                if (!this.contextGroup.id) {
                    await this.setContextUserAction(context.userId);
                }

                if (!this.contextAccount.id) {
                    await this.setContextAccountAction(context.accountId);
                }

                this.$wait.end('fetching.contextData');
            },

            openAppointmentModal() {
                this.$eventBus.$emit('open-add-task-event', {
                    leadId: this.createAppointmentModal.payload.leadId,
                    title: this.createAppointmentModal.payload.title,
                    description: this.createAppointmentModal.payload.description,
                    guests: this.createAppointmentModal.payload.guests,
                    priority: this.createAppointmentModal.payload.priority,
                    user: this.createAppointmentModal.payload.user_id,
                    reminders: this.createAppointmentModal.payload.reminders,
                    type: TaskEventType.APPOINTMENT,
                });
            },

            verifyAccess() {
                if (!this.$route.name || this.$route.name == 'auth.login') {
                    return;
                }

                if (!this.authUser.hasAccessTo(this.$route.name, this.contextAccount.id)) {
                    this.$router.replace({ name: 'unauthorized' });
                }
            },

            getUrlQuery() {
                if (!this.$route.query) {
                    return {};
                }

                const query = {};

                Object.keys(this.$route.query).forEach(key => {
                    if (this.canSaveInUrl(key)) {
                        query[key] = this.$route.query[key];
                    }
                });

                return query;
            },

            canSaveInUrl(key) {
                const saveInUrl = this.$route.meta.saveInUrl || [];

                return saveInUrl.includes(key);
            },

            openCreateAppointmentModal(payload) {
                this.createAppointmentModal.opened = true;
                this.createAppointmentModal.payload = payload;
            },

            async init() {
                await Promise.all([this.loadInitialData(), this.setContextData()]);

                this.loaded = true;
            },

            getRedirect() {
                return this.getReferer();
            },

            getReferer() {
                const referer = this.$ls.get('referer');

                this.$ls.remove('referer');

                return referer;
            },

            onBodyMutation(mutations) {
                mutations.forEach(mutation => {
                    this.setBodyMarginTop(mutation.target.style.marginTop);
                    this.setBodyPaddingTop(mutation.target.style.paddingTop);
                });
            },
        },

        created() {
            const redirect = this.getRedirect();

            if (redirect === 'freshdesk') {
                this.openFreshdesk(false);
            } else {
                this.init();
            }

            this.bodyObserver = new MutationObserver(this.onBodyMutation);
            this.bodyObserver.observe(document.body, { attributeFilter: ['style'] });

            this.$eventBus.$on('open-modal-event-appointment', this.openCreateAppointmentModal);
            this.$eventBus.$on('load-initial-data', this.loadInitialData);
        },

        beforeDestroy() {
            this.$eventBus.$off('open-modal-event-appointment', this.openCreateAppointmentModal);
            this.$eventBus.$off('load-initial-data', this.loadInitialData);

            this.bodyObserver.disconnect();
            this.bodyObserver = null;
        },
    };
</script>
