import Model from './Model.js';
import { formatPhone } from '../utils/index.js';

export default class UserPhone extends Model {
    static get defaults() {
        return {};
    }

    /**
     * Getters
     */

    // @TODO Find a better way to standardize these 3 properties between authUser and other users
    get phone() {
        return this.number;
    }

    get cellphone() {
        return this.is_mobile;
    }

    get preferred() {
        return this.is_preferred;
    }

    get formatedNumber() {
        return formatPhone(this.number);
    }

    get formatedNumberWithExtension() {
        if (this.extension) {
            return `${this.formatedNumber} x${this.extension}`.trim();
        }

        return this.formatedNumber;
    }
}
