// Bold
import '@activix/icons/bold/add.js';
import '@activix/icons/bold/alert-circle.js';
import '@activix/icons/bold/alert-triangle.js';
import '@activix/icons/bold/align-left.js';
import '@activix/icons/bold/analytics-bars-horizontal.js';
import '@activix/icons/bold/arrow-button-right-2.js';
import '@activix/icons/bold/arrow-down-1.js';
import '@activix/icons/bold/arrow-thick-left.js';
import '@activix/icons/bold/arrow-thick-right.js';
import '@activix/icons/bold/arrow-thick-circle-bottom-left-corner';
import '@activix/icons/bold/arrow-thick-circle-bottom-right-corner';
import '@activix/icons/bold/car-retro-1.js';
import '@activix/icons/bold/check-1.js';
import '@activix/icons/bold/check-2.js';
import '@activix/icons/bold/check-circle-1.js';
import '@activix/icons/bold/close.js';
import '@activix/icons/bold/controls-pause.js';
import '@activix/icons/bold/currency-dollar.js';
import '@activix/icons/bold/delete-2.js';
import '@activix/icons/bold/dial-finger.js';
import '@activix/icons/bold/email-action-reply.js';
import '@activix/icons/bold/email-action-unread.js';
import '@activix/icons/bold/filter-2.js';
import '@activix/icons/bold/layout-module.js';
import '@activix/icons/bold/megaphone-1.js';
import '@activix/icons/bold/messages-bubble-typing-1.js';
import '@activix/icons/bold/microphone.js';
import '@activix/icons/bold/multiple-neutral-2.js';
import '@activix/icons/bold/navigation-menu-horizontal.js';
import '@activix/icons/bold/navigation-menu-horizontal-1.js';
import '@activix/icons/bold/navigation-menu-horizontal-2.js';
import '@activix/icons/bold/navigation-menu-vertical.js';
import '@activix/icons/bold/phone.js';
import '@activix/icons/bold/pin.js';
import '@activix/icons/bold/question-circle.js';
import '@activix/icons/bold/rating-star.js';
import '@activix/icons/bold/rating-star-1.js';
import '@activix/icons/bold/remove-circle.js';
import '@activix/icons/bold/remove.js';
import '@activix/icons/bold/scooter-sport.js';
import '@activix/icons/bold/sign-badge-circle.js';
import '@activix/icons/bold/single-man.js';
import '@activix/icons/bold/social-media-facebook-1.js';
import '@activix/icons/bold/subtract-circle.js';
import '@activix/icons/bold/subtract.js';
import '@activix/icons/bold/synchronize-arrow-1.js';
import '@activix/icons/bold/synchronize-arrows-triangle.js';
import '@activix/icons/bold/time-clock-file-sync.js';
import '@activix/icons/bold/tools-wench-screwdriver.js';
import '@activix/icons/bold/view-1.js';
import '@activix/icons/bold/view-off.js';

// Light
import '@activix/icons/light/award-ribbon-star-3.js';
import '@activix/icons/light/barcode-scan.js';
import '@activix/icons/light/car-retro-1.js';
import '@activix/icons/light/gauge-dashboard.js';
import '@activix/icons/light/pin.js';
import '@activix/icons/light/scooter-sport.js';
import '@activix/icons/light/trophy.js';

// Regular
import '@activix/icons/regular/accessories-tie.js';
import '@activix/icons/regular/accounting-bill-stack-1.js';
import '@activix/icons/regular/accounting-calculator.js';
import '@activix/icons/regular/accounting-document.js';
import '@activix/icons/regular/accounting-invoice.js';
import '@activix/icons/regular/add.js';
import '@activix/icons/regular/alarm-bell.js';
import '@activix/icons/regular/alarm-bell-sleep.js';
import '@activix/icons/regular/alert-circle.js';
import '@activix/icons/regular/alert-diamond.js';
import '@activix/icons/regular/alert-triangle.js';
import '@activix/icons/regular/align-left.js';
import '@activix/icons/regular/angle-brackets.js';
import '@activix/icons/regular/archive-folder.js';
import '@activix/icons/regular/arrow-button-left-1.js';
import '@activix/icons/regular/arrow-button-right-1.js';
import '@activix/icons/regular/arrow-circle-left.js';
import '@activix/icons/regular/arrow-circle-right.js';
import '@activix/icons/regular/arrow-down-1.js';
import '@activix/icons/regular/arrow-left-1.js';
import '@activix/icons/regular/arrow-right-1.js';
import '@activix/icons/regular/arrow-right.js';
import '@activix/icons/regular/arrow-thick-up.js';
import '@activix/icons/regular/arrow-up-1.js';
import '@activix/icons/regular/attachment.js';
import '@activix/icons/regular/audio-file.js';
import '@activix/icons/regular/award-ribbon-star-3.js';
import '@activix/icons/regular/award-trophy-star-1.js';
import '@activix/icons/regular/baggage.js';
import '@activix/icons/regular/bin-paper.js';
import '@activix/icons/regular/book-open-2.js';
import '@activix/icons/regular/briefcase.js';
import '@activix/icons/regular/button-refresh-arrows.js';
import '@activix/icons/regular/button-refresh-one.js';
import '@activix/icons/regular/button-shuffle.js';
import '@activix/icons/regular/calendar-3.js';
import '@activix/icons/regular/calendar-add.js';
import '@activix/icons/regular/calendar-check.js';
import '@activix/icons/regular/calendar-clock.js';
import '@activix/icons/regular/calendar-favorite-heart.js';
import '@activix/icons/regular/calendar-favorite.js';
import '@activix/icons/regular/calendar-search.js';
import '@activix/icons/regular/calendar-setting.js';
import '@activix/icons/regular/calendar-sync.js';
import '@activix/icons/regular/calendar.js';
import '@activix/icons/regular/car-1.js';
import '@activix/icons/regular/car-actions-disable-1.js';
import '@activix/icons/regular/car-actions-search-1.js';
import '@activix/icons/regular/car-actions-shield.js';
import '@activix/icons/regular/car-repair-fluid.js';
import '@activix/icons/regular/cash-briefcase.js';
import '@activix/icons/regular/cash-payment-bag.js';
import '@activix/icons/regular/check-2.js';
import '@activix/icons/regular/check-circle-1.js';
import '@activix/icons/regular/check-double.js';
import '@activix/icons/regular/check-payment-sign.js';
import '@activix/icons/regular/check-shield.js';
import '@activix/icons/regular/check-square.js';
import '@activix/icons/regular/checklist.js';
import '@activix/icons/regular/cloud-upload.js';
import '@activix/icons/regular/coffee-cup.js';
import '@activix/icons/regular/cog-browser.js';
import '@activix/icons/regular/cog.js';
import '@activix/icons/regular/color-palette.js';
import '@activix/icons/regular/common-file-double-1.js';
import '@activix/icons/regular/common-file-download.js';
import '@activix/icons/regular/common-file-empty.js';
import '@activix/icons/regular/common-file-text.js';
import '@activix/icons/regular/composition-window-man.js';
import '@activix/icons/regular/content-paper-edit.js';
import '@activix/icons/regular/content-pencil-write.js';
import '@activix/icons/regular/credit-card-1.js';
import '@activix/icons/regular/cursor.js';
import '@activix/icons/regular/data-transfer-diagonal';
import '@activix/icons/regular/data-transfer-three-back-forth-back.js';
import '@activix/icons/regular/data-transfer-vertical.js';
import '@activix/icons/regular/database-upload.js';
import '@activix/icons/regular/delete-1.js';
import '@activix/icons/regular/delete.js';
import '@activix/icons/regular/design-tool-magic-wand.js';
import '@activix/icons/regular/diagram-fall-down.js';
import '@activix/icons/regular/dislike-1.js';
import '@activix/icons/regular/download-button.js';
import '@activix/icons/regular/drawer-send.js';
import '@activix/icons/regular/drawer-play.js';
import '@activix/icons/regular/earth-1.js';
import '@activix/icons/regular/email-action-check.js';
import '@activix/icons/regular/email-action-read.js';
import '@activix/icons/regular/email-action-reply.js';
import '@activix/icons/regular/email-action-unread.js';
import '@activix/icons/regular/email-action-disable.js';
import '@activix/icons/regular/expand-3.js';
import '@activix/icons/regular/expand-6.js';
import '@activix/icons/regular/expand-horizontal-2.js';
import '@activix/icons/regular/filter-1.js';
import '@activix/icons/regular/flag-plain-3.js';
import '@activix/icons/regular/floppy-disk-1.js';
import '@activix/icons/regular/folder-empty.js';
import '@activix/icons/regular/gas-load.js';
import '@activix/icons/regular/gift-box.js';
import '@activix/icons/regular/graph-stats.js';
import '@activix/icons/regular/hand-expand.js';
import '@activix/icons/regular/headphones-customer-support.js';
import '@activix/icons/regular/hierarchy-4.js';
import '@activix/icons/regular/house-chimney-2.js';
import '@activix/icons/regular/house-garage.js';
import '@activix/icons/regular/human-resources-employee-previous.js';
import '@activix/icons/regular/hyperlink.js';
import '@activix/icons/regular/image-file-camera.js';
import '@activix/icons/regular/information-circle.js';
import '@activix/icons/regular/lab-flask-experiment.js';
import '@activix/icons/regular/layout-two-colums.js';
import '@activix/icons/regular/like-1.js';
import '@activix/icons/regular/list-bullets.js';
import '@activix/icons/regular/lock-1.js';
import '@activix/icons/regular/lock-unlock.js';
import '@activix/icons/regular/login-1.js';
import '@activix/icons/regular/login-key.js';
import '@activix/icons/regular/login-keys.js';
import '@activix/icons/regular/logout-1.js';
import '@activix/icons/regular/matches-fire.js';
import '@activix/icons/regular/meeting-camera.js';
import '@activix/icons/regular/megaphone-1.js';
import '@activix/icons/regular/messages-bubble-square-information.js';
import '@activix/icons/regular/messages-bubble-square-typing.js';
import '@activix/icons/regular/messages-bubble-typing-1.js';
import '@activix/icons/regular/mobile-phone.js';
import '@activix/icons/regular/modern-tv-flat-screen.js';
import '@activix/icons/regular/money-bag-dollar.js';
import '@activix/icons/regular/monitor-play.js';
import '@activix/icons/regular/microphone.js';
import '@activix/icons/regular/multiple-neutral-2.js';
import '@activix/icons/regular/navigation-arrows-left-1.js';
import '@activix/icons/regular/navigation-arrows-right-1.js';
import '@activix/icons/regular/navigation-left-circle-1.js';
import '@activix/icons/regular/navigation-left.js';
import '@activix/icons/regular/navigation-menu-horizontal.js';
import '@activix/icons/regular/navigation-menu.js';
import '@activix/icons/regular/navigation-right-circle_1.js';
import '@activix/icons/regular/newspaper.js';
import '@activix/icons/regular/night-moon-new.js';
import '@activix/icons/regular/notes-paper-text';
import '@activix/icons/regular/notes-book-text.js';
import '@activix/icons/regular/office-file-doc.js';
import '@activix/icons/regular/office-file-pdf.js';
import '@activix/icons/regular/office-file-presentation-1.js';
import '@activix/icons/regular/office-file-xls.js';
import '@activix/icons/regular/image-file-landscape.js';
import '@activix/icons/regular/password-desktop-approved.js';
import '@activix/icons/regular/pencil-1.js';
import '@activix/icons/regular/pencil-write.js';
import '@activix/icons/regular/phone-actions-call.js';
import '@activix/icons/regular/phone-actions-next.js';
import '@activix/icons/regular/phone-landline.js';
import '@activix/icons/regular/phone-retro-1.js';
import '@activix/icons/regular/phone.js';
import '@activix/icons/regular/pin-1.js';
import '@activix/icons/regular/police-man-1.js';
import '@activix/icons/regular/question-circle.js';
import '@activix/icons/regular/rating-star.js';
import '@activix/icons/regular/redo.js';
import '@activix/icons/regular/real-estate-sign-board-sold.js';
import '@activix/icons/regular/remove-circle.js';
import '@activix/icons/regular/remove-shield.js';
import '@activix/icons/regular/remove.js';
import '@activix/icons/regular/reorder-down.js';
import '@activix/icons/regular/reorder-up.js';
import '@activix/icons/regular/road-straight.js';
import '@activix/icons/regular/screen-1.js';
import '@activix/icons/regular/search-remove.js';
import '@activix/icons/regular/search.js';
import '@activix/icons/regular/send-email-monitor.js';
import '@activix/icons/regular/send-email.js';
import '@activix/icons/regular/shield-settings.js';
import '@activix/icons/regular/shop.js';
import '@activix/icons/regular/shop-sign-open.js';
import '@activix/icons/regular/shopping-cart-check.js';
import '@activix/icons/regular/sign-badge-circle.js';
import '@activix/icons/regular/single-man-actions.js';
import '@activix/icons/regular/single-neutral-actions-alarm.js';
import '@activix/icons/regular/single-neutral-actions-block.js';
import '@activix/icons/regular/single-neutral-actions-warning.js';
import '@activix/icons/regular/single-neutral-actions-check-2.js';
import '@activix/icons/regular/single-neutral-actions-question.js';
import '@activix/icons/regular/single-neutral-actions-remove.js';
import '@activix/icons/regular/single-neutral-actions.js';
import '@activix/icons/regular/single-neutral.js';
import '@activix/icons/regular/single-neutral-circle.js';
import '@activix/icons/regular/single-neutral-id-card-3.js';
import '@activix/icons/regular/share-1.js';
import '@activix/icons/regular/smiley-bad.js';
import '@activix/icons/regular/social-media-facebook-1.js';
import '@activix/icons/regular/stopwatch.js';
import '@activix/icons/regular/switch-off-1.js';
import '@activix/icons/regular/synchronize-arrows-square-1.js';
import '@activix/icons/regular/synchronize-arrows-warning.js';
import '@activix/icons/regular/target-center-1.js';
import '@activix/icons/regular/task-list-approve.js';
import '@activix/icons/regular/task-list-check.js';
import '@activix/icons/regular/task-list-warning.js';
import '@activix/icons/regular/time-clock-circle.js';
import '@activix/icons/regular/transportation-ticket-car-transfer-1.js';
import '@activix/icons/regular/trash-1.js';
import '@activix/icons/regular/upload-thick-bottom.js';
import '@activix/icons/regular/video-file-play.js';
import '@activix/icons/regular/video-game-magic-wand.js';
import '@activix/icons/regular/view-1.js';
import '@activix/icons/regular/view-off.js';
import '@activix/icons/regular/vip-crown-queen-2.js';
import '@activix/icons/regular/walking.js';
import '@activix/icons/regular/wench-1.js';
import '@activix/icons/regular/wifi-signal-2.js';
import '@activix/icons/regular/check-double-1.js';
import '@activix/icons/regular/font-size.js';
import '@activix/icons/regular/color-painting-palette.js';
import '@activix/icons/regular/wifi-signal-4.js';
