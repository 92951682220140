export default {
    advisor: 'Conseiller',
    appt: 'Rendez-vous',
    apptSaleByOrigin: 'RV et @:lexicon.sale.singular par source',
    apptWithWitoutPhone: 'RV avec et sans téléphone',
    calls: 'Appels',
    callsByAdvisors: 'Appels par conseiller',
    callsByAppointments: 'Rendez-vous par sources',
    callsbyModels: 'Appels et @:lexicon.sale.plural par modèles',
    callsBySources: 'Appels par sources',
    delivered: 'Livrés',
    lead: 'Leads',
    leadByForm: 'Leads par formulaire',
    leadByOrigin: 'Leads par fournisseur',
    leadBySource: 'Leads par source',
    leadByUserOrAccount: 'Leads par conseiller',
    loading: 'Génération des graphiques en cours...',
    models: 'Modèles',
    new: 'Neuf',
    noAppt: 'Aucun rendez-vous',
    noApptSaleByOrigin: 'Aucun rendez-vous / @:lexicon.sale.singular',
    noDivision: 'Sans division',
    noLeadAssigned: 'Aucun lead assigné',
    noLeadByOrigin: 'Aucun lead avec une source',
    noSale: 'Aucune @:lexicon.sale.singular',
    noSaleRoadTest: 'Aucune @:lexicon.sale.singular avec ou sans essai routier',
    noWalkIn: 'Aucun @.capitalize:lexicon.walkIn.singular',
    numberOfAppointments: 'Nombre de rendez-vous',
    numberOfCalls: "Nombre d'appels",
    numberOfLead: 'Nombre de leads',
    numberOfSales: 'Nombre de @:lexicon.sale.plural',
    numberOfWalkIn: 'Nombre de @.capitalize:lexicon.walkIn.singular',
    numberOfWebOrder: 'Nombre de Commandes',
    numbers: 'Nombres',
    roadTest: 'essai routier',
    sale: '@.capitalize:lexicon.sale.plural',
    saleByUser: '@.capitalize:lexicon.sale.plural par conseiller',
    saleDeliveryByModels: '@.capitalize:lexicon.sale.plural et livraisons par Modèles',
    saleDeliveryByUsers: '@.capitalize:lexicon.sale.plural et livraisons par conseillers',
    saleRtByUser: '@.capitalize:lexicon.sale.plural avec ou sans essai routier',
    salesBySources: '@.capitalize:lexicon.sale.plural par sources',
    saleWithWitoutPhone: '@.capitalize:lexicon.sale.plural avec et sans téléphone',
    sources: 'Sources',
    title: 'Graphiques',
    used: 'Occasion',
    useOnlyCreatedAt: "Ce graphique n'utilise que la date de création pour éviter les doublons",
    users: 'Conseillers',
    walkIn: '@.capitalize:lexicon.walkIn.singular',
    walkInByUser: '@.capitalize:lexicon.walkIn.singular par conseiller',
    webOrder: 'Commandes',
    webOrderByOrigin: 'Commandes par source',
    webOrderBySource: 'Commandes par fournisseur',
    webOrderByForm: 'Commandes par formulaire',
    webOrderByUser: 'Commandes par conseiller',
    weekOf: 'Semaine du',
    weeks: 'Semaines',
    with: 'Avec',
    without: 'Sans',
    withoutPhone: 'Sans téléphone',
    withPhone: 'Avec téléphone',
    withPhoneStatInitial: 'Les statistiques avec ou sans téléphone sont basées sur les demandes initiales',

    months: {
        january: 'Janvier',
        february: 'Février',
        march: 'Mars',
        april: 'Avril',
        may: 'Mai',
        june: 'Juin',
        july: 'Juillet',
        august: 'Août',
        september: 'Septembre',
        october: 'Octobre',
        november: 'Novembre',
        december: 'Décembre',
    },
};
