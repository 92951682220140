<template>
    <div>
        <h3 class="text-4xl mt-0 mb-3" v-text="$t('auth.forbidden')" />
        <p class="text-lg mt-0 mb-6" v-text="errorMessage" />
        <activix-button
            type="primary"
            :loading="loading"
            @click="redirectToLogin"
            v-text="$t('navs.userDropdown.disconnect')"
            v-if="logout"
        />
    </div>
</template>

<script>
    export default {
        props: {
            logout: {
                type: Boolean,
                default: true,
            },
            error: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                loading: false,
            };
        },

        computed: {
            errorMessage() {
                const key = `auth.errors.${this.error}`;

                return this.$te(key) ? this.$t(key) : this.$t('auth.errors.userRestricted');
            },
        },

        methods: {
            redirectToLogin() {
                this.loading = true;
                this.$auth.redirectToLogout();
            },
        },
    };
</script>
