export default {
    install(Vue) {
        const extensionIds = {
            sbkDialer: 'pfilblgjdmmakankpdocpfefhfphgeen',
            sbkDialerPreprod: 'cigabmdoemjmcicgldgjmcgoppnggadl',
        };

        const chromeExtensions = [];

        function sbkDialerExtensionExist() {
            try {
                chrome.runtime.sendMessage(extensionIds.sbkDialerPreprod, {
                    action: 'getVersion',
                }, (response) => {
                    if (response) {
                        chromeExtensions.push('sbkDialer');
                    }
                });
            } catch (error) {
                // no code needed
            }
        }

        sbkDialerExtensionExist();

        Vue.$chromeExtensions = chromeExtensions;
        Vue.prototype.$chromeExtensions = chromeExtensions;
    },
};
