import Enum from './Enum.js';

export default class PhoneProvider extends Enum {
    static get INTEGRATED_PROVIDERS() {
        return [
            'twilio',
        ];
    }

    static get entries() {
        return {
            twilio: 'Twilio',
            tel_synergy: 'Tel Synergy',
            sbk_telecom: 'SBK Telecom',
            purple_cloud: 'Purple Cloud',
        };
    }

    static get EXTERNAL_PROVIDERS() {
        return [
            'sbk_telecom',
            'purple_cloud',
        ];
    }

    static get REQUIRES_EXTENSION() {
        return [
            'sbk_telecom',
        ];
    }

    static get TWILIO() {
        return this.entries.twilio;
    }

    static get TEL_SYNERGY() {
        return this.entries.tel_synergy;
    }

    static get SBK_TELECOM() {
        return this.entries.sbk_telecom;
    }

    static get PURPLE_CLOUD() {
        return this.entries.purple_cloud;
    }
}
