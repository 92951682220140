<template>
    <email-editor
        :locale="`${$i18n.locale}-CA`"
        class="h-0 min-h-md h-screen-75 border border-gray-300 rounded-sm bg-gray-200"
        :project-id="projectId"
        :options="emailEditorOptions"
        ref="emailEditor"
        @load="editorLoaded"
    />
</template>

<script>
    import { EmailEditor } from 'vue-email-editor';
    import { forEach } from 'lodash-es';

    import EmailCompose from '@/mixins/EmailCompose.js';

    import { mapState } from 'pinia';
    import { useGlobalStore } from '../../store/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    // eslint-disable-next-line import/no-webpack-loader-syntax
    import unlayerCSS from '!raw-loader!postcss-loader!@/assets/css/unlayer.css';

    export default {
        components: {
            EmailEditor,
        },

        mixins: [EmailCompose],

        props: {
            locale: {
                type: String,
                default: 'en',
            },
            lead: {
                type: Object,
                default: null,
            },
            design: {
                type: Object,
                default: null,
            },
            massMailing: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            const unsubscribeBlockText = `
                <p style="font-size: 14px; line-height: 140%;">
                    <a rel="noopener" href="%tag_unsubscribe_url%" target="_blank">
                        ${this.$t('templates.unsubscribe', this.locale)}
                    </a>
                </p>
            `;

            const subscribeBlockText = `
                <p style="font-size: 14px; line-height: 140%;">
                    <a rel="noopener" href="%tag_subscribe_url%" target="_blank">
                        ${this.$t('templates.subscribe', this.locale)}
                    </a>
                </p>
            `;

            const unsubscribeLink = `
                let unsubscribeText = '<a href="%tag_unsubscribe_url%" style="text-decoration: none;">';

                if (values.data.locale == 'en') {
                    unsubscribeText += '${this.$t('templates.unsubscribe', 'en')}';
                } else {
                    unsubscribeText += '${this.$t('templates.unsubscribe', 'fr')}';
                }

                unsubscribeText += '</a>';

                return \`
                    <div class="unsubscribe-link" style="font-size: 12px; text-align: \${values.textAlign}">
                        \${unsubscribeText}
                    </div>
                \`;
            `;

            const placeholderCommunicationVideo = `
                return \`
                    <div>
                        <thumbnail data-video-communication-thumbnail style="
                            position: relative;
                            background-size: cover !important;
                            border-radius: 0.5rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            padding-bottom: 56.25%;
                            background-blend-mode: darken;
                            background: rgba(0, 0, 0, .35) url('${process.env.VUE_APP_PRODUCTION_URL}/img/cars.jpeg')
                        ">
                            <div style="
                                position: absolute;
                                border-radius: 0.5rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 100%;
                                color: rgba(255, 255, 255, 1);
                                width: 100%;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                backdrop-filter: blur(3px);
                                text-align: center;
                            ">{ ${this.$t('communications.video.thumbnailPlaceholder')} }</div>
                        </thumbnail>
                    </div>
                \`;
            `;

            const unsubscribeTool = `unlayer.registerTool({
                name: 'unsubscribe',
                label: '${this.$t('templates.unsubscribe')}',
                icon: 'fa-ban',
                supportedDisplayModes: ['email'],
                values: {},
                options: {
                    text: {
                        title: '${this.$t('templates.options')}',
                        position: 1,
                        options: {
                            "textAlign": {
                                "label": "${this.$t('templates.textAlign')}",
                                "defaultValue": "left",
                                "widget": "alignment"
                            },
                        }
                    }
                },
                renderer: {
                    Viewer: unlayer.createViewer({
                        render(values) {
                            ${unsubscribeLink}
                        },
                    }),
                    exporters: {
                        email(values) {
                            ${unsubscribeLink}
                        },
                    },
                    head: {
                    },
                },
            });`;

            const communicationVideoTool = `unlayer.registerTool({
                name: 'communicationVideo',
                label: '${this.$t('communications.video.title')}',
                icon: 'fa-camera',
                supportedDisplayModes: ['email'],
                values: {},
                renderer: {
                    Viewer: unlayer.createViewer({
                        render(values) {
                            ${placeholderCommunicationVideo}
                        },
                    }),
                    exporters: {
                        email(values) {
                            ${placeholderCommunicationVideo}
                        },
                    },
                    head: {
                    },
                },
            });`;

            const unsubscribeBlock = {
                id: 1,
                category: 'Communication',
                tags: [],
                data: {
                    cells: [1],
                    columns: [{
                        contents: [{
                            type: 'text',
                            values: {
                                containerPadding: '10px',
                                textAlign: 'left',
                                lineHeight: '140%',
                                linkStyle: {
                                    inherit: true,
                                    linkColor: '#0000ee',
                                    linkHoverColor: '#0000ee',
                                    linkUnderline: true,
                                    linkHoverUnderline: true,
                                },
                                displayCondition: null,
                                _meta: {
                                    htmlClassNames: 'u_content_text',
                                },
                                selectable: true,
                                draggable: true,
                                duplicatable: true,
                                deletable: true,
                                hideable: true,
                                text: unsubscribeBlockText,
                            },
                        }],
                        values: {
                            _meta: {
                                htmlClassNames: 'u_column',
                            },
                        },
                    }],
                    values: {
                        displayCondition: null,
                        columns: false,
                        backgroundColor: '',
                        columnsBackgroundColor: '',
                        backgroundImage: {
                            url: '',
                            fullWidth: true,
                            repeat: false,
                            center: true,
                            cover: false,
                        },
                        padding: '0px',
                        hideDesktop: false,
                        _meta: {
                            htmlClassNames: 'u_row',
                        },
                        selectable: true,
                        draggable: true,
                        duplicatable: true,
                        deletable: true,
                        hideable: true,
                    },
                },
                displayMode: 'email',
            };

            const subscribeBlock = this.$feature.isEnabled('casl_compliance') ? {
                id: 2,
                category: 'Communication',
                tags: [],
                data: {
                    cells: [1],
                    columns: [{
                        contents: [{
                            type: 'text',
                            values: {
                                containerPadding: '10px',
                                textAlign: 'left',
                                lineHeight: '140%',
                                linkStyle: {
                                    inherit: true,
                                    linkColor: '#0000ee',
                                    linkHoverColor: '#0000ee',
                                    linkUnderline: true,
                                    linkHoverUnderline: true,
                                },
                                displayCondition: null,
                                _meta: {
                                    htmlClassNames: 'u_content_text',
                                },
                                selectable: true,
                                draggable: true,
                                duplicatable: true,
                                deletable: true,
                                hideable: true,
                                text: subscribeBlockText,
                            },
                        }],
                        values: {
                            _meta: {
                                htmlClassNames: 'u_column',
                            },
                        },
                    }],
                    values: {
                        displayCondition: null,
                        columns: false,
                        backgroundColor: '',
                        columnsBackgroundColor: '',
                        backgroundImage: {
                            url: '',
                            fullWidth: true,
                            repeat: false,
                            center: true,
                            cover: false,
                        },
                        padding: '0px',
                        hideDesktop: false,
                        _meta: {
                            htmlClassNames: 'u_row',
                        },
                        selectable: true,
                        draggable: true,
                        duplicatable: true,
                        deletable: true,
                        hideable: true,
                    },
                },
                displayMode: 'email',
            } : '';

            return {
                unsubscribeTool,
                communicationVideoTool,
                unsubscribeBlock,
                subscribeBlock,
                projectId: parseInt(process.env.VUE_APP_UNLAYER_PROJECT_ID, 10),
                designLoaded: false,
                unsubscribeToolUsed: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, {
                authUser: 'authUser',
            }),

            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            emailEditorOptions() {
                return {
                    customCSS: unlayerCSS,
                    customJS: [this.communicationVideoTool, this.unsubscribeTool],
                    tools: {
                        menu: {
                            enabled: false,
                        },
                        html: {
                            enabled: false,
                        },
                        'custom#unsubscribe': {
                            data: {
                                locale: this.locale,
                            },
                        },
                        'custom#communicationVideo': {
                            usageLimit: 1,
                        },
                        button: {
                            properties: {
                                buttonColors: {
                                    value: {
                                        color: '#FFFFFF',
                                        backgroundColor: '#333333',
                                        hoverColor: '#222222',
                                        hoverBackgroundColor: '#333333',
                                    },
                                },
                            },
                        },
                    },
                    features: {
                        stockImages: false,
                        textEditor: {
                            spellChecker: false,
                            tables: false,
                            emojis: false,
                            fontSizes: ['8px', '9px', '10px', '11px', '12px', '13px', '14px', '15px', '16px', '17px', '18px', '19px', '20px', '21px', '22px', '23px', '24px', '26px', '28px', '30px', '32px', '34px', '36px'],
                        },
                    },
                    user: {
                        id: this.contextAccount.id,
                    },
                    translations: {
                        'en-CA': {
                            'labels.merge_tags': 'Attributes',
                        },
                        'fr-CA': {
                            'labels.merge_tags': 'Attributs',
                            'buttons.upload_image': 'Importer une image',
                        },
                    },
                    specialLinks: [
                        {
                            name: this.$t('templates.unsubscribe'),
                            href: '%tag_unsubscribe_url%',
                            target: '_blank',
                        },
                        {
                            name: this.$t('templates.subscribe'),
                            href: '%tag_subscribe_url%',
                            target: '_blank',
                        },
                    ],
                };
            },
        },

        methods: {
            editorLoaded() {
                this.$refs.emailEditor.editor.setMergeTags(this.getMergeTags());

                this.$refs.emailEditor.editor.addEventListener('design:loaded', this.loadDesign);
                this.$refs.emailEditor.editor.addEventListener('design:updated', this.saveDesign);

                this.$refs.emailEditor.editor.registerProvider('blocks', (params, done) => {
                    done([this.unsubscribeBlock, this.subscribeBlock]);
                });

                this.$refs.emailEditor.editor.registerProvider('userUploads', async (params, done) => {
                    const response = await this.$axios.get('v1/templates/uploads', {
                        params: {
                            account: this.contextAccount.id,
                        },
                    });

                    const images = response.data.data.map(image => {
                        return {
                            id: image.id,
                            location: image.url,
                            contentType: image.content_type,
                            source: 'user',
                        };
                    });

                    done(images);
                });

                this.$refs.emailEditor.editor.registerCallback('image', async (files, done) => {
                    const data = new FormData();
                    data.append('account', this.contextAccount.id);

                    forEach(files.attachments, (attachment) => {
                        data.append('files[]', attachment);
                    });

                    const response = await this.$axios.post('v1/templates/uploads', data);
                    response.data.data.forEach(upload => {
                        done(upload);
                    });
                });

                this.$refs.emailEditor.editor.registerCallback('image:removed', async (image, done) => {
                    await this.$axios.delete(`v1/templates/uploads/${image.id}`);

                    done();
                });
            },

            saveDesign(data) {
                this.$wait.start('saving.template');

                this.$refs.emailEditor.editor.exportHtml((data) => {
                    let body = data.html;

                    if (this.contextAccount.isPolestar()) {
                        const elementHtml = document.createElement('html');
                        elementHtml.innerHTML = body;
                        for (const element of elementHtml.getElementsByTagName('span')) {
                            element.style.letterSpacing = '-0.3px';
                        }
                        body = elementHtml.innerHTML;
                    }

                    this.$emit('update-design', data.design);
                    this.$emit('update-body', body);

                    this.$wait.end('saving.template');
                });

                if (data?.item?.type == 'custom' && data.item.slug == 'unsubscribe') {
                    this.unsubscribeToolUsed = data.type == 'content:added';
                }
            },

            loadDesign() {
                if (!this.design) {
                    this.$refs.emailEditor.editor.loadBlank({
                        backgroundColor: '#ffffff',
                    });
                }

                if (this.design && !this.designLoaded) {
                    this.designLoaded = true;
                    this.$refs.emailEditor.editor.loadDesign(this.design);

                    return;
                }

                this.$refs.emailEditor.editor.removeEventListener('design:loaded', this.loadDesign);

                this.saveDesign();
            },

            getMergeTags() {
                const mergeTags = {};
                const payload = {
                    sender: this.authUser,
                    lead: this.lead,
                    locale: this.locale,
                    prefix: '', // @TODO prefix
                    massMailing: this.massMailing,
                    users: this.contextAccount.users,
                    html: false,
                };

                this.templateAttributes.forEach(category => {
                    const categoryTags = {};
                    const categoryName = this.normalizeTagText(category.name);

                    category.tags.forEach(tag => {
                        const tagText = this.normalizeTagText(tag.text);

                        categoryTags[tag['text-value']] = {
                            name: tagText,
                            value: `{${categoryName} - ${tagText}}`,
                            sample: this.lead ? this.getAttributeValue({ group: category, attribute: tag, ...payload }) : tag[`placeholder-${this.locale}`],
                        };
                    });

                    mergeTags[category['name-value']] = {
                        name: categoryName,
                        mergeTags: categoryTags,
                    };
                });

                return mergeTags;
            },
        },

        beforeDestroy() {
            this.$refs.emailEditor.editor.removeEventListener('design:loaded', this.loadDesign);
            this.$refs.emailEditor.editor.removeEventListener('design:updated', this.saveDesign);
        },
    };
</script>
