import DashboardViewSubscription from '@/entities/DashboardViewSubscription.js';
import Model from './Model.js';

export default class DashboardView extends Model {
    static get defaults() {
        return {
            id: null,
            column: {},
            name: '',
            dates: [],
            expand: {},
            filter: {},
            option: {},
            order: {},
            preset_dates: {},
            stats_table_filter: {},
            subscription: DashboardViewSubscription.defaults,
        };
    }
}
