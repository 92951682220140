import { get } from 'lodash-es';
import { globalStore } from '@/pinia/storeHelper.js';
import i18n from '../plugins/vue-i18n.js';
import moment from '../plugins/moment.js';

const INPUT_MICRO_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
const INPUT_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
const DATETIME_INPUT_FORMAT = 'YYYY-MM-DD H:mm:ss';
const DATETIME_INPUT_MICRO_FORMAT = 'YYYY-MM-DD H:mm:ss.SSSSSS';
const DATE_INPUT_FORMAT = 'YYYY-MM-DD';
const MONTH_INPUT_FORMAT = 'YYYY-MM';
const TIME_INPUT_FORMAT = 'H:mm:ss';
const TIME_INPUT_SHORT_FORMAT = 'H:mm';
const DATETIME_OUTPUT_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_OUTPUT_FORMAT = 'YYYY-MM-DD';
const TIME_OUTPUT_FORMAT = 'HH:mm:ss';
const TIME_OUTPUT_SHORT_FORMAT = 'HH:mm';
const TIME_OUTPUT_SHORT_MERIDIEM_FORMAT = 'h:mm a';
const DATETIME_HUMAN_SHORT_FORMAT = 'DD MMM YYYY HH:mm';
const DATETIME_HUMAN_SHORT_MERIDIEM_FORMAT = 'MMM DD, YYYY h:mm a';
const DATE_HUMAN_SHORT_FORMAT = 'DD MMM YYYY';
const DATE_HUMAN_SHORT_MERIDIEM_FORMAT = 'MMM DD, YYYY';
const DATETIME_HUMAN_LONG_FORMAT = 'DD MMM YYYY HH:mm:ss';
const DATETIME_HUMAN_STRING_FORMAT = 'DD MMMM YYYY [à] HH:mm';
const DATETIME_HUMAN_MERIDIEM_STRING_FORMAT = 'MMMM Do, YYYY [at] h:mm a';
const DATE_HUMAN_STRING_FORMAT = 'DD MMMM YYYY';
const DATE_HUMAN_MERIDIEM_STRING_FORMAT = 'MMMM Do, YYYY';
const DEFAULT_TIMEZONE = 'America/Montreal';

const FORMAT_ORDER = [
    INPUT_FORMAT,
    // DATETIME_INPUT_FORMAT,
    TIME_INPUT_FORMAT,
    DATETIME_INPUT_MICRO_FORMAT,
    DATETIME_HUMAN_SHORT_MERIDIEM_FORMAT,
    DATETIME_HUMAN_SHORT_FORMAT,
    DATE_HUMAN_SHORT_MERIDIEM_FORMAT,
    DATE_HUMAN_SHORT_FORMAT,
    DATE_INPUT_FORMAT,
    TIME_INPUT_SHORT_FORMAT,
    INPUT_MICRO_FORMAT,
];

export default class Moment {
    constructor(dateTime, type = 'dateTime', { format = '', timezone = '', defaultNow = false, invariant = false }) {
        if (type === 'time') {
            invariant = true;
        }

        this.type = type;
        this.empty = !dateTime;
        this.invariant = invariant;
        this.dateTime = Moment.parse(dateTime, { format, timezone, defaultNow, invariant });

        if (type === 'date') {
            this.setMidday();
        }
    }

    static get TIME_OUTPUT_FORMAT() {
        return TIME_OUTPUT_FORMAT;
    }

    static get DATE_OUTPUT_FORMAT() {
        return DATE_OUTPUT_FORMAT;
    }

    static get INPUT_FORMAT() {
        return INPUT_FORMAT;
    }

    static get DATETIME_OUTPUT_FORMAT() {
        return DATETIME_OUTPUT_FORMAT;
    }

    static get DATETIME_HUMAN_SHORT_FORMAT() {
        return DATETIME_HUMAN_SHORT_FORMAT;
    }

    static get DATE_HUMAN_SHORT_FORMAT() {
        return DATE_HUMAN_SHORT_FORMAT;
    }

    static get DATE_HUMAN_SHORT_MERIDIEM_FORMAT() {
        return DATE_HUMAN_SHORT_MERIDIEM_FORMAT;
    }

    static get DATETIME_HUMAN_LONG_FORMAT() {
        return DATETIME_HUMAN_LONG_FORMAT;
    }

    static get DATETIME_HUMAN_SHORT_MERIDIEM_FORMAT() {
        return DATETIME_HUMAN_SHORT_MERIDIEM_FORMAT;
    }

    static get timezone() {
        return globalStore().parentAuthUser.timezone || DEFAULT_TIMEZONE;
    }

    static now(type) {
        return new Moment('now', type, {});
    }

    static yesterday(type) {
        return new Moment('yesterday', type, {});
    }

    static tomorrow(type) {
        return new Moment('tomorrow', type, {});
    }

    static init(date, type, options) {
        if (date instanceof Moment) {
            return date.clone();
        }

        return new Moment(date, type, options || {});
    }

    static asLocale(date, key, options) {
        let type;

        switch (get(globalStore().configs.dateCasts, key)) {
            case 'date':
                type = 'date';
                break;

            case 'time':
                type = 'time';
                break;

            default:
                type = 'dateTime';
                break;
        }

        return Moment.init(date, type, options);
    }

    static parse(dateTime, { format = '', timezone = '', defaultNow = false, invariant = false }) {
        let momentDateTime = null;
        let timezoneNormalized = Moment.normalizeTimezone(timezone, invariant);

        if (timezone && !timezoneNormalized) {
            // Might be good to throw exception / show error to user to prevent saving wrong datetimes
            timezoneNormalized = 'UTC';
        }

        if (!dateTime) {
            momentDateTime = null;
        } else if (dateTime instanceof Moment) {
            momentDateTime = moment.tz(dateTime.toDateTimeString(), DATETIME_INPUT_FORMAT, true, timezoneNormalized);
        } else if (moment.isMoment(dateTime)) {
            momentDateTime = moment.tz(
                dateTime.format(DATETIME_OUTPUT_FORMAT),
                DATETIME_INPUT_FORMAT,
                true,
                timezoneNormalized,
            );
        } else if (moment.isDate(dateTime)) {
            momentDateTime = moment.tz(dateTime.toISOString(), INPUT_MICRO_FORMAT, true, timezoneNormalized);
        } else if (format) {
            momentDateTime = moment.tz(dateTime, format, true, timezoneNormalized);
        } else if (dateTime == 'now') {
            momentDateTime = moment.tz(timezoneNormalized);
        } else if (dateTime == 'yesterday') {
            momentDateTime = moment.tz(timezoneNormalized).subtract(1, 'day');
        } else if (dateTime == 'tomorrow') {
            momentDateTime = moment.tz(timezoneNormalized).add(1, 'day');
        } else {
            momentDateTime = moment.tz(dateTime, DATETIME_INPUT_FORMAT, true, 'UTC'); // Used if date comes from backend using simple eloquent

            if (!Moment.isValid(momentDateTime)) {
                for (const format of FORMAT_ORDER) {
                    momentDateTime = moment.tz(dateTime, format, true, timezoneNormalized);

                    if (Moment.isValid(momentDateTime)) {
                        break;
                    }
                }
            }
        }

        if (!Moment.isValid(momentDateTime) && defaultNow) {
            momentDateTime = moment('now', format, true);
        }

        if (!Moment.isValid(momentDateTime)) {
            return null;
        }

        if (!invariant && Moment.timezone) {
            momentDateTime.tz(Moment.timezone);
        }

        return momentDateTime;
    }

    static normalizeTimezone(timezone, invariant = false) {
        if (invariant) {
            return 'UTC';
        }

        return timezone || Moment.timezone;
    }

    static weekdayName(isoWeekday) {
        const date = new Moment(isoWeekday, 'date', { format: 'E' });

        return date.format('dddd', 'en').toLowerCase();
    }

    static humanShortLocalizedFormat(isDateOnly = false, locale = '') {
        locale = locale.toLowerCase() || i18n.locale;

        if (isDateOnly) {
            return locale == 'en' ? DATE_HUMAN_SHORT_MERIDIEM_FORMAT : DATE_HUMAN_SHORT_FORMAT;
        }

        return locale == 'en' ? DATETIME_HUMAN_SHORT_MERIDIEM_FORMAT : DATETIME_HUMAN_SHORT_FORMAT;
    }

    static humanLocalizedStringFormat(isDateOnly = false, locale = '') {
        locale = locale.toLowerCase() || i18n.locale;

        if (isDateOnly) {
            return locale == 'en' ? DATE_HUMAN_MERIDIEM_STRING_FORMAT : DATE_HUMAN_STRING_FORMAT;
        }

        return locale == 'en' ? DATETIME_HUMAN_MERIDIEM_STRING_FORMAT : DATETIME_HUMAN_STRING_FORMAT;
    }

    static timeLocalizedFormat(locale = '') {
        locale = locale.toLowerCase() || i18n.locale;

        return locale == 'en' ? TIME_OUTPUT_SHORT_MERIDIEM_FORMAT : TIME_OUTPUT_SHORT_FORMAT;
    }

    static isValid(dateTime) {
        return moment.isMoment(dateTime) && dateTime.isValid();
    }

    static isValidTime(time) {
        return (
            (time instanceof Moment && time.type == 'time') ||
            (typeof time === 'string' && time.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/) !== null)
        );
    }

    static duration(duration, unit) {
        return moment.duration(duration, unit);
    }

    getObject() {
        return this.dateTime;
    }

    orNow() {
        return this.isValid() ? this : this.asNow();
    }

    asNow() {
        this.dateTime = Moment.now(this.type);

        return this;
    }

    toDate() {
        return this.dateTime.toDate();
    }

    isValid() {
        return Moment.isValid(this.dateTime);
    }

    isEmpty() {
        return this.empty;
    }

    or(fallback) {
        return this.isValid() ? this : this.reset(fallback);
    }

    toTimeShortString() {
        const format = i18n.locale == 'en' ? TIME_OUTPUT_SHORT_MERIDIEM_FORMAT : TIME_OUTPUT_SHORT_FORMAT;

        return this.display(format) || '';
    }

    toTimeString() {
        return this.display(TIME_OUTPUT_FORMAT) || '';
    }

    toDateString() {
        return this.display(DATE_OUTPUT_FORMAT) || '';
    }

    toMonthString() {
        return this.display(MONTH_INPUT_FORMAT) || '';
    }

    toDateTimeString() {
        return this.display(DATETIME_OUTPUT_FORMAT) || '';
    }

    toUtcDateTimeString() {
        return this.clone().utc().toDateTimeString();
    }

    toUtcTimeString() {
        return this.clone().utc().toTimeString();
    }

    display(format, locale = '', timezone = '') {
        if (!this.isValid()) {
            return '';
        }

        if (!format) {
            switch (this.type) {
                case 'date':
                    format = DATE_OUTPUT_FORMAT;
                    break;

                case 'time':
                    format = TIME_OUTPUT_FORMAT;
                    break;

                default:
                    format = DATETIME_OUTPUT_FORMAT;
            }
        }

        const instance = this.clone();

        if (timezone) {
            instance.tz(timezone);
        }

        locale = locale || i18n.locale || 'en';

        return instance.locale(locale).format(format);
    }

    format(format = '', locale = '') {
        if (!this.isValid()) {
            return '';
        }

        let dateTime = this;

        if (locale) {
            dateTime = dateTime.clone().locale(locale);
        }

        return dateTime.dateTime.format(format || DATETIME_OUTPUT_FORMAT);
    }

    humanShort(dateOnly, locale = '') {
        if (typeof dateOnly === 'undefined') {
            if (this.type === 'date') {
                dateOnly = true;
            } else {
                dateOnly = false;
            }
        }

        const format = Moment.humanShortLocalizedFormat(dateOnly, locale);

        return this.display(format, locale);
    }

    humanString(dateOnly, locale = '') {
        if (typeof dateOnly === 'undefined') {
            if (this.type === 'date') {
                dateOnly = true;
            } else {
                dateOnly = false;
            }
        }

        const format = Moment.humanLocalizedStringFormat(dateOnly, locale);

        return this.display(format, locale);
    }

    toJSON() {
        return this.toString();
    }

    toString() {
        if (this.type === 'time') {
            return this.display();
        }

        return this.iso();
    }

    iso(forceUtc = false) {
        if (!this.isValid()) {
            return '';
        }

        const date = forceUtc ? this.clone().utc() : this;

        return date.dateTime.format(INPUT_FORMAT);
    }

    isSameDay(date) {
        return this.isSame(date, 'day');
    }

    isSameMonth(date) {
        return this.isSame(date, 'month');
    }

    isSame(date, unit = '') {
        if (!this.isValid()) {
            return false;
        }

        if (unit) {
            return this.dateTime.isSame(this.asSameType(date).dateTime, unit);
        }

        const [instance, normalizedDate] = this.prepareForComparison(date);

        if (!instance) {
            return false;
        }

        if (moment.isMoment(instance)) {
            return instance.isSame(normalizedDate);
        }

        return instance == normalizedDate;
    }

    isStartOfDay() {
        if (!this.isValid()) {
            return false;
        }

        return this.toTimeString() == '00:00:00';
    }

    isEndOfDay() {
        if (!this.isValid()) {
            return false;
        }

        return this.toTimeString() == '23:59:59';
    }

    isStartOfMonth() {
        if (!this.isValid()) {
            return false;
        }

        return this.isSameDay(this.clone().startOfMonth());
    }

    isEndOfMonth() {
        if (!this.isValid()) {
            return false;
        }

        return this.isSameDay(this.clone().endOfMonth());
    }

    isToday() {
        return this.isSameDay('now');
    }

    isPast(unit = '') {
        if (!this.isValid()) {
            return false;
        }

        return this.isBefore('now', unit);
    }

    isFuture(unit = '') {
        if (!this.isValid()) {
            return false;
        }

        return this.isAfter('now', unit);
    }

    isSameOrBefore(date, unit = '', forceBool = false) {
        return this.isBefore(date, unit, true, forceBool);
    }

    isBefore(date, unit = '', orEqual = false, forceBool = false) {
        if (unit) {
            date = this.asSameType(date);

            if (orEqual) {
                return this.dateTime.isSameOrBefore(date.dateTime, unit);
            }

            return this.dateTime.isBefore(date.dateTime, unit);
        }

        const [instance, normalizedDate] = this.prepareForComparison(date);

        if (!instance) {
            return forceBool ? false : null;
        }

        if (orEqual) {
            return instance <= normalizedDate;
        }

        return instance < normalizedDate;
    }

    isSameOrAfter(date, unit = '', forceBool = false) {
        return this.isAfter(date, unit, true, forceBool);
    }

    isAfter(date, unit = '', orEqual = false, forceBool = false) {
        if (unit) {
            date = this.asSameType(date);

            if (orEqual) {
                return this.dateTime.isSameOrAfter(date.dateTime, unit);
            }

            return this.dateTime.isAfter(date.dateTime, unit);
        }

        const [instance, normalizedDate] = this.prepareForComparison(date);

        if (!instance) {
            return forceBool ? false : null;
        }

        if (orEqual) {
            return instance >= normalizedDate;
        }

        return instance > normalizedDate;
    }

    isBetweenExclusive(date1, date2, forceBool = false) {
        return this.isBetween(date1, date2, true, forceBool);
    }

    isBetween(date1, date2, exclusive = false, forceBool = false) {
        if (!this.isValid()) {
            return forceBool ? false : null;
        }

        let earliest = this.asSameType(date1, '', false);
        let latest = this.asSameType(date2, '', false);

        if (latest.isBefore(earliest, '')) {
            [earliest, latest] = [latest, earliest];
        }

        if (!earliest.isValid() || !latest.isValid()) {
            return forceBool ? false : null;
        }

        return this.isAfter(earliest, '', !exclusive, forceBool) && this.isBefore(latest, '', !exclusive, forceBool);
    }

    prepareForComparison(date) {
        if (!this.isValid()) {
            return [null, null];
        }

        const instance = this.clone();

        if (Moment.isValidTime(instance) || Moment.isValidTime(date)) {
            date = Moment.init(date, 'time');
        } else {
            date = instance.asSameType(date, '');
        }

        if (!date.isValid()) {
            return [null, null];
        }

        if (Moment.isValidTime(instance) || Moment.isValidTime(date)) {
            return [instance.toTimeString(), date.toTimeString()];
        }

        if (instance.isDateOnly() || date.isDateOnly()) {
            return [instance.toDateString(), date.tz(instance.tz()).toDateString()];
        }

        return [instance.toDateTimeString(), date.tz(instance.tz()).toDateTimeString()];
    }

    hasTime() {
        return this.dateTime.hasTime();
    }

    diffInSeconds(date) {
        return this.diff(date, 'seconds');
    }

    diffInMinutes(date) {
        return this.diff(date, 'minutes');
    }

    diffInHours(date) {
        return this.diff(date, 'hours');
    }

    diffInDays(date) {
        return this.diff(date, 'days');
    }

    diffInWeeks(date) {
        return this.diff(date, 'weeks');
    }

    diffInMonths(date) {
        return this.diff(date, 'months');
    }

    diffInYears(date) {
        return this.diff(date, 'years');
    }

    diffFromNow(unit = '') {
        return this.diff(moment(), unit);
    }

    diff(date, unit = '') {
        if (!this.isValid()) {
            return 0;
        }

        if (typeof date === 'undefined') {
            date = 'now';
        }

        // TODO Time diff not really working, not sure if we want to support it
        date = this.asSameType(date, '', false);

        return this.dateTime.diff(date.getObject(), unit);
    }

    fromNow(withoutSuffix = false) {
        if (!this.isValid()) {
            return '';
        }

        return this.dateTime.fromNow(withoutSuffix);
    }

    startOfDay() {
        return this.startOf('day');
    }

    setMidday() {
        return this.setTime('12:00:00');
    }

    endOfDay() {
        return this.endOf('day');
    }

    startOfWeek() {
        return this.startOf('week');
    }

    endOfWeek() {
        return this.endOf('week');
    }

    startOfMonth() {
        return this.startOf('month');
    }

    endOfMonth() {
        return this.endOf('month');
    }

    startOfYear() {
        return this.startOf('year');
    }

    endOfYear() {
        return this.endOf('year');
    }

    startOf(type) {
        if (this.isValid()) {
            this.dateTime.startOf(type);
        }

        return this;
    }

    endOf(type) {
        if (this.isValid()) {
            this.dateTime.endOf(type);
        }

        return this;
    }

    addSeconds(qty = 1) {
        return this.add({ seconds: qty });
    }

    subSeconds(qty = 1) {
        return this.sub({ seconds: qty });
    }

    addMinutes(qty = 1) {
        return this.add({ minutes: qty });
    }

    subMinutes(qty = 1) {
        return this.sub({ minutes: qty });
    }

    addHours(qty = 1) {
        return this.add({ hours: qty });
    }

    subHours(qty = 1) {
        return this.sub({ hours: qty });
    }

    addDays(qty = 1) {
        return this.add({ days: qty });
    }

    subDays(qty = 1) {
        return this.sub({ days: qty });
    }

    addWeeks(qty = 1) {
        return this.add({ weeks: qty });
    }

    subWeeks(qty = 1) {
        return this.sub({ weeks: qty });
    }

    addMonths(qty = 1) {
        return this.add({ months: qty });
    }

    subMonths(qty = 1) {
        return this.sub({ months: qty });
    }

    addYears(qty = 1) {
        return this.add({ years: qty });
    }

    subYears(qty = 1) {
        return this.sub({ years: qty });
    }

    add(params, type) {
        if (this.isValid()) {
            if (type) {
                this.dateTime.add(params, type);
            } else {
                this.dateTime.add(params);
            }
        }

        return this;
    }

    sub(params, type) {
        if (this.isValid()) {
            if (type) {
                this.dateTime.subtract(params, type);
            } else {
                this.dateTime.subtract(params);
            }
        }

        return this;
    }

    second(value) {
        if (this.isValid()) {
            if (typeof value === 'undefined') {
                return this.dateTime.second();
            }

            this.dateTime.second(value);
        }

        return this;
    }

    minute(value) {
        if (this.isValid()) {
            if (typeof value === 'undefined') {
                return this.dateTime.minute();
            }

            this.dateTime.minute(value);
        }

        return this;
    }

    hour(value) {
        if (this.isValid()) {
            if (typeof value === 'undefined') {
                return this.dateTime.hour();
            }

            this.dateTime.hour(value);
        }

        return this;
    }

    date(value) {
        if (this.isValid()) {
            if (typeof value === 'undefined') {
                return this.dateTime.date();
            }

            this.dateTime.date(value);
        }

        return this;
    }

    day(value) {
        if (this.isValid()) {
            if (typeof value === 'undefined') {
                return this.dateTime.day();
            }

            this.dateTime.day(value);
        }

        return this;
    }

    isoWeekday(value) {
        if (this.isValid()) {
            if (typeof value === 'undefined') {
                return this.dateTime.isoWeekday();
            }

            this.dateTime.isoWeekday(value);
        }

        return this;
    }

    weekday(value) {
        if (this.isValid()) {
            if (typeof value === 'undefined') {
                return this.dateTime.weekday();
            }

            this.dateTime.weekday(value);
        }

        return this;
    }

    month(value) {
        if (this.isValid()) {
            if (typeof value === 'undefined') {
                return this.dateTime.month();
            }

            this.dateTime.month(value);
        }

        return this;
    }

    timeHumanShort(locale = '') {
        const format = Moment.timeLocalizedFormat(locale);

        return this.display(format, locale);
    }

    year(value) {
        if (this.isValid()) {
            if (typeof value === 'undefined') {
                return this.dateTime.year();
            }

            this.dateTime.year(value);
        }

        return this;
    }

    timeToNow() {
        return this.setTime('now');
    }

    setTime(time) {
        if (this.isValid()) {
            time = Moment.init(time, 'time');

            if (time.isValid()) {
                this.dateTime.set({
                    hour: time.hour(),
                    minute: time.minute(),
                    second: time.second(),
                });
            }
        }

        return this;
    }

    clearTime() {
        if (this.isValid()) {
            this.dateTime.set({
                hour: 0,
                minute: 0,
                second: 0,
            });
        }

        return this;
    }

    timestamp() {
        if (!this.isValid()) {
            return '';
        }

        return this.dateTime.unix();
    }

    durationFrom(date) {
        if (!this.isValid()) {
            return {};
        }

        return this.dateTime.duration(date);
    }

    daysInMonth() {
        if (!this.isValid()) {
            return 0;
        }

        return this.dateTime.daysInMonth();
    }

    utc() {
        return this.tz('utc');
    }

    tz(timezone) {
        if (!this.isValid()) {
            return timezone ? this : '';
        }

        if (!timezone) {
            return this.dateTime.tz();
        }

        this.dateTime.tz(timezone);

        return this;
    }

    locale(locale) {
        if (!locale) {
            return this.dateTime.locale();
        }

        this.dateTime.locale(locale);

        return this;
    }

    clone() {
        return this.asSameType(this.dateTime);
    }

    reset(dateTime) {
        this.dateTime = Moment.parse(dateTime, { invariant: this.invariant });

        return this;
    }

    clear() {
        return this.reset(null);
    }

    asSameType(date, format = '', clone = true) {
        if (date instanceof Moment) {
            return clone ? date.clone() : date;
        }

        return Moment.init(date, this.type, { format });
    }

    isDateOnly() {
        return this.type === 'date';
    }
}
