<template>
    <div class="overflow-hidden">
        <div class="text-grey-600 h-full truncate mr-4 text-lg" v-show="accountOnly">
            {{ contextAccount.name }}
        </div>
        <template v-if="dropdownIsRendered">
            <group-account-select
                :account-change-disabled-routes="accountChangeDisabledRoutes"
                :active-accounts="activeAccounts"
                :child-accounts="childAccounts"
                :child-users="childUsers"
                :inactive-accounts="inactiveAccounts"
                :groups="sortedGroups"
                :parent-accounts="parentAccounts"
                :parent-user="parentUser"
                v-show="!accountOnly"
            />
        </template>
    </div>
</template>

<script>
    import { get, map, isEmpty } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import { sort } from '../utils/index.js';
    import GroupAccountSelect from './GroupAccountSelect.vue';
    import { useContextStore } from '../store/modules/context/store.js';
    import { useGlobalStore } from '../store/store.js';
    import { useUserCardStore } from '../store/modules/userCard/store.js';

    export default {
        components: {
            GroupAccountSelect,
        },

        data() {
            return {
                accountChangeDisabledRoutes: [
                    'account.index',
                    'account.update',
                    'account.store',
                    'nioText',
                    'users.update',
                    'schedule',
                    'search',
                    'duplicates',
                    'imports.index',
                    'imports.match',
                    'automations.index',
                    'automations.store',
                    'automations.update',
                    'flow',
                    'flow.webBoost',
                    'flow.phoneUp',
                    'flow.leadXpress',
                    'users.index',
                    'users.store',
                    'objectives.saleTable',
                    'objectives.activityReport',
                    'inTurn.show',
                    'inTurn.management',
                    'templates.index',
                    'templates.store',
                    'templates.update',
                    'calendar',
                ],
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['groups', 'accounts', 'parentAuthUser', 'authUser']),
            ...mapState(useUserCardStore, {
                currentEditedUser: 'currentForm',
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextUser: 'user',
            }),

            dropdownIsRendered() {
                return !this.accountOnly || this.$route.name == 'leads.update';
            },

            accountOnly() {
                let accountOnly = true;

                if (this.parentAuthUser.isAdmin()) {
                    accountOnly = false;
                }

                if (
                    this.parentAuthUser.isDirector() &&
                    (!isEmpty(this.parentAuthUser.groups) || !isEmpty(this.parentAuthUser.children))
                ) {
                    if (!this.contextUser.id && (!isEmpty(this.activeAccounts) || !isEmpty(this.inactiveAccounts))) {
                        accountOnly = false;
                    }
                }

                if (!isEmpty(this.childAccounts)) {
                    accountOnly = false;
                }

                if (
                    this.cannotChangeAccount ||
                    (this.$route.name == 'users.update' && !this.contextAccountIsParentOrChild) ||
                    (this.$route.name == 'accounts.update' && !this.contextAccountIsParentOrChild)
                ) {
                    accountOnly = true;
                }

                if (
                    !(this.parentAuthUser.account.account_manager || this.authUser.isAdmin()) &&
                    this.accountChangeDisabledRoutes.includes(this.$route.name)
                ) {
                    accountOnly = true;
                }

                return accountOnly;
            },

            sortedGroups() {
                return sort(this.groups, 'name');
            },

            sortedAccounts() {
                return sort(this.accounts, 'name');
            },

            activeAccounts() {
                return this.sortedAccounts.filter(account => {
                    return (
                        account.active && !account.account_manager && !this.childAccounts.find(a => a.id == account.id)
                    );
                });
            },

            inactiveAccounts() {
                return this.sortedAccounts.filter(account => !account.active && !account.account_manager);
            },

            parentAccounts() {
                if (!this.parentAuthUser.account.account_manager && !this.authUser.isAdmin()) {
                    return [];
                }

                if (this.$route.name == 'users.update') {
                    if (!isEmpty(this.parentUser)) {
                        return [this.parentUser.account];
                    }
                }

                if (this.sortedAccounts.length) {
                    return this.sortedAccounts.filter(account => account.account_manager && account.active);
                }

                if (this.parentAuthUser.account.account_manager) {
                    return [this.authUser.account];
                }

                return [];
            },

            parentUser() {
                if (!isEmpty(this.currentEditedUser)) {
                    if (!isEmpty(this.currentEditedUser.parent)) {
                        return this.currentEditedUser.parent;
                    }

                    if (this.currentEditedUser.account.account_manager) {
                        return this.currentEditedUser;
                    }
                }

                return {};
            },

            childAccounts() {
                let accounts = [];

                if (this.$route.name == 'users.update') {
                    if (!isEmpty(this.currentEditedUser)) {
                        accounts = map(this.childUsers, 'account');
                    }
                } else if (this.$route.name == 'accounts.update') {
                    for (const parentAccount of this.parentAccounts) {
                        if (isEmpty(parentAccount.children)) {
                            continue;
                        }

                        accounts = accounts.concat(parentAccount.children);
                    }
                } else {
                    const childUsers = get(this.parentAuthUser, 'children', []);

                    accounts = childUsers.map(child => {
                        return {
                            id: child.account.id,
                            name: child.account.name,
                            active: child.account.active,
                        };
                    });
                }

                return sort(
                    accounts.filter(account => account.active),
                    'name',
                );
            },

            childUsers() {
                if (!isEmpty(this.currentEditedUser)) {
                    if (!isEmpty(this.currentEditedUser.parent)) {
                        return this.currentEditedUser.parent.children;
                    }

                    if (this.currentEditedUser.account.account_manager) {
                        return this.currentEditedUser.children;
                    }
                }

                return [];
            },

            contextAccountIsParentOrChild() {
                return !!this.parentAccounts.concat(this.childAccounts).find(a => a.id == this.contextAccount.id);
            },

            cannotChangeAccount() {
                return get(this.$route.meta, 'accountSelect.disabled', true);
            },
        },

        watch: {
            'contextAccount.id': {
                immediate: true,
                handler(newValue) {
                    this.manageAccessToAccount(newValue);
                },
            },

            accountOnly: {
                immediate: true,
                handler(newValue) {
                    if (newValue) {
                        this.manageAccessToAccount(this.contextAccount.id);
                    }
                },
            },

            '$route.name': {
                immediate: true,
                handler() {
                    this.manageAccessToAccount(this.contextAccount.id);
                },
            },
        },

        methods: {
            ...mapActions(useContextStore, ['setContextAccountAction']),

            manageAccessToAccount(accountId, routeName = this.$route.name) {
                if (
                    accountId &&
                    this.accountChangeDisabledRoutes.includes(routeName) &&
                    !this.authUser.hasAccessToEditAccount(accountId)
                ) {
                    this.setContextAccountAction(this.authUser.account_id);
                }
            },
        },
    };
</script>
