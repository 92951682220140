import {
    addDays,
    addMinutes,
    addMonths,
    addSeconds,
    differenceInDays,
    differenceInHours,
    differenceInMilliseconds,
    differenceInMinutes,
    differenceInMonths,
    differenceInSeconds,
    differenceInWeeks,
    endOfDay,
    endOfMonth,
    formatDistanceToNow,
    getDaysInMonth,
    getMonth,
    getUnixTime,
    getYear,
    isAfter,
    isBefore,
    isEqual,
    isSameDay,
    isWithinInterval,
    getQuarter,
    parseISO,
    setDate,
    setHours,
    setMinutes,
    setSeconds,
    startOfDay,
    startOfMonth,
    subDays,
    subMinutes,
    subMonths,
    subSeconds,
} from 'date-fns';
import { format, toDate, utcToZonedTime } from 'date-fns-tz';
import fr from 'date-fns/locale/fr/index.js';
import en from 'date-fns/locale/en-US/index.js';
import { globalStore } from '@/pinia/storeHelper.js';
import i18n from '../plugins/vue-i18n.js';

const locales = { fr, en };

const DEFAULT_TIMEZONE = 'America/Montreal';
const DATE_FORMAT = 'yyyy-MM-dd';
const DATE_HUMAN_SHORT_FORMAT = 'dd MMM yyyy';
const DATE_HUMAN_SHORT_MERIDIEM_FORMAT = 'MMM dd, yyyy';
const DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX";
const DATETIME_HUMAN_SHORT_FORMAT = 'dd MMM yyyy HH:mm';
const DATETIME_HUMAN_SHORT_MERIDIEM_FORMAT = 'MMM dd, yyyy h:mm a';
const TIME_FORMAT = 'HH:mm:ss';
const TIME_HUMAN_SHORT_FORMAT = 'HH:mm';
const TIME_HUMAN_SHORT_MERIDIEM_FORMAT = 'h:mm a';

export default class ActivixDate {
    constructor(dateTime, type = 'dateTime') {
        if (!dateTime) {
            this.dateTime = null;
            return;
        }

        if (dateTime.length === 10) {
            type = 'date';
        }

        this.type = type;

        if (dateTime instanceof Date) {
            this.dateTime = dateTime;
            return;
        }

        if (dateTime === 'now') {
            this.dateTime = new Date();
        } else if (type === 'date') {
            // Parse the string to convert it correctly
            this.dateTime = parseISO(dateTime);
        } else {
            // Convert to Date instance (We assume the given string is in UTC)
            this.dateTime = toDate(dateTime, { timeZone: 'UTC' });

            // Convert from UTC to user timezone
            this.dateTime = utcToZonedTime(this.dateTime, ActivixDate.timezone);
        }

        // Set midday to prevent date change when converting timezone
        if (type === 'date') {
            this.dateTime = setHours(this.dateTime, 12);
            this.dateTime = setMinutes(this.dateTime, 0);
            this.dateTime = setSeconds(this.dateTime, 0);
        }
    }

    static parseDate(value, name = 'dateTime') {
        if (value instanceof ActivixDate) {
            value = value.toDate();
        }

        if (!(value instanceof Date)) {
            throw new Error(`The parameter "${name}" must be a Date instance.`);
        }

        return value;
    }

    static get timezone() {
        return globalStore().parentAuthUser.timezone || DEFAULT_TIMEZONE;
    }

    clone() {
        return new ActivixDate(this.dateTime);
    }

    or(fallback) {
        if (this.isEmpty()) {
            return new ActivixDate(fallback);
        }

        return this.clone();
    }

    orNow() {
        return this.or('now');
    }

    startOfDay() {
        if (this.isEmpty()) {
            return this;
        }

        return new ActivixDate(startOfDay(this.dateTime), this.type);
    }

    startOfMonth() {
        if (this.isEmpty()) {
            return this;
        }

        return new ActivixDate(startOfMonth(this.dateTime), this.type);
    }

    endOfDay() {
        if (this.isEmpty()) {
            return this;
        }

        return new ActivixDate(endOfDay(this.dateTime), this.type);
    }

    endOfMonth() {
        if (this.isEmpty()) {
            return this;
        }

        return new ActivixDate(endOfMonth(this.dateTime), this.type);
    }

    addSeconds(amount = 1) {
        if (this.isEmpty()) {
            return this;
        }

        return new ActivixDate(addSeconds(this.dateTime, amount), this.type);
    }

    addMinutes(amount = 1) {
        if (this.isEmpty()) {
            return this;
        }

        return new ActivixDate(addMinutes(this.dateTime, amount), this.type);
    }

    addDays(amount = 1) {
        if (this.isEmpty()) {
            return this;
        }

        return new ActivixDate(addDays(this.dateTime, amount), this.type);
    }

    addMonths(amount = 1) {
        if (this.isEmpty()) {
            return this;
        }

        return new ActivixDate(addMonths(this.dateTime, amount), this.type);
    }

    subSeconds(amount = 1) {
        if (this.isEmpty()) {
            return this;
        }

        return new ActivixDate(subSeconds(this.dateTime, amount), this.type);
    }

    subMinutes(amount = 1) {
        if (this.isEmpty()) {
            return this;
        }

        return new ActivixDate(subMinutes(this.dateTime, amount), this.type);
    }

    subDays(amount = 1) {
        if (this.isEmpty()) {
            return this;
        }

        return new ActivixDate(subDays(this.dateTime, amount), this.type);
    }

    subMonths(amount = 1) {
        if (this.isEmpty()) {
            return this;
        }

        return new ActivixDate(subMonths(this.dateTime, amount), this.type);
    }

    format(formatString) {
        if (this.isEmpty()) {
            return '';
        }

        return format(this.dateTime, formatString, {
            locale: locales[i18n.locale],
            timeZone: ActivixDate.timezone,
        });
    }

    diffInMilliseconds(dateTime) {
        dateTime = ActivixDate.parseDate(dateTime);

        if (this.isEmpty()) {
            return 0;
        }

        return differenceInMilliseconds(this.dateTime, dateTime);
    }

    diffInSeconds(dateTime) {
        dateTime = ActivixDate.parseDate(dateTime);

        if (this.isEmpty()) {
            return 0;
        }

        return differenceInSeconds(this.dateTime, dateTime);
    }

    diffInMinutes(dateTime) {
        dateTime = ActivixDate.parseDate(dateTime);

        if (this.isEmpty()) {
            return 0;
        }

        return differenceInMinutes(this.dateTime, dateTime);
    }

    diffInHours(dateTime) {
        dateTime = ActivixDate.parseDate(dateTime);

        if (this.isEmpty()) {
            return 0;
        }

        return differenceInHours(this.dateTime, dateTime);
    }

    diffInDays(dateTime) {
        dateTime = ActivixDate.parseDate(dateTime);

        if (this.isEmpty()) {
            return 0;
        }

        return differenceInDays(this.dateTime, dateTime);
    }

    diffInWeeks(dateTime) {
        dateTime = ActivixDate.parseDate(dateTime);

        if (this.isEmpty()) {
            return 0;
        }

        return differenceInWeeks(this.dateTime, dateTime);
    }

    diffInMonths(dateTime) {
        dateTime = ActivixDate.parseDate(dateTime);

        if (this.isEmpty()) {
            return 0;
        }

        return differenceInMonths(this.dateTime, dateTime);
    }

    formatDistanceToNow() {
        if (this.isEmpty()) {
            return '';
        }

        return formatDistanceToNow(this.dateTime, {
            locale: locales[i18n.locale],
            addSuffix: true,
        });
    }

    getQuarter() {
        return getQuarter(this.month);
    }

    isEmpty() {
        return this.dateTime === null;
    }

    isNotEmpty() {
        return !this.isEmpty();
    }

    isBefore(dateTime) {
        dateTime = ActivixDate.parseDate(dateTime);

        if (this.isEmpty()) {
            return false;
        }

        return isBefore(this.dateTime, dateTime);
    }

    isAfter(dateTime) {
        dateTime = ActivixDate.parseDate(dateTime);

        if (this.isEmpty()) {
            return false;
        }

        return isAfter(this.dateTime, dateTime);
    }

    isBetween(start, end) {
        if (!start || !end) {
            return false;
        }

        start = ActivixDate.parseDate(start, 'start');
        end = ActivixDate.parseDate(end, 'end');

        if (this.isEmpty()) {
            return false;
        }

        return isWithinInterval(this.dateTime, { start, end });
    }

    isSame(dateTime) {
        dateTime = ActivixDate.parseDate(dateTime);

        if (this.isEmpty()) {
            return false;
        }

        return isEqual(this.dateTime, dateTime);
    }

    isSameDay(dateTime) {
        dateTime = ActivixDate.parseDate(dateTime);

        if (this.isEmpty()) {
            return false;
        }

        return isSameDay(this.dateTime, dateTime);
    }

    isSameOrAfter(dateTime) {
        return this.isSame(dateTime) || this.isAfter(dateTime);
    }

    isSameOrBefore(dateTime) {
        return this.isSame(dateTime) || this.isBefore(dateTime);
    }

    isToday() {
        return this.isSameDay(new Date());
    }

    isPast() {
        return this.isBefore(new Date());
    }

    isFuture() {
        return this.isAfter(new Date());
    }

    isStartOfDay() {
        if (this.isEmpty()) {
            return false;
        }

        return this.toTimeString() == '00:00:00';
    }

    isEndOfDay() {
        if (this.isEmpty()) {
            return false;
        }

        return this.toTimeString() == '23:59:59';
    }

    toDate() {
        return this.dateTime;
    }

    toHumanShort(withTime = true) {
        let format = i18n.locale == 'en' ? DATE_HUMAN_SHORT_MERIDIEM_FORMAT : DATE_HUMAN_SHORT_FORMAT;

        if (this.type === 'dateTime' && withTime) {
            format = i18n.locale == 'en' ? DATETIME_HUMAN_SHORT_MERIDIEM_FORMAT : DATETIME_HUMAN_SHORT_FORMAT;
        }

        return this.format(format);
    }

    toHumanTime() {
        const format = i18n.locale == 'en' ? TIME_HUMAN_SHORT_MERIDIEM_FORMAT : TIME_HUMAN_SHORT_FORMAT;

        return this.format(format);
    }

    toTimeString() {
        return this.format(TIME_FORMAT);
    }

    toDateString() {
        return this.format(DATE_FORMAT);
    }

    toDateTimeString() {
        return this.format(DATETIME_FORMAT);
    }

    toString() {
        if (this.type === 'time') {
            return this.toTimeString();
        }

        if (this.type === 'date') {
            return this.toDateString();
        }

        return this.toDateTimeString();
    }

    setSeconds(seconds) {
        if (this.isEmpty()) {
            return this;
        }

        return new ActivixDate(setSeconds(this.dateTime, seconds), this.type);
    }

    setMinutes(minutes) {
        if (this.isEmpty()) {
            return this;
        }

        return new ActivixDate(setMinutes(this.dateTime, minutes), this.type);
    }

    setHours(hours) {
        if (this.isEmpty()) {
            return this;
        }

        return new ActivixDate(setHours(this.dateTime, hours), this.type);
    }

    setDate(date) {
        if (this.isEmpty()) {
            return this;
        }

        return new ActivixDate(setDate(this.dateTime, date), this.type);
    }

    get month() {
        if (this.isEmpty()) {
            return null;
        }

        return getMonth(this.dateTime);
    }

    get year() {
        if (this.isEmpty()) {
            return null;
        }

        return getYear(this.dateTime);
    }

    get timestamp() {
        if (this.isEmpty()) {
            return null;
        }

        return getUnixTime(this.dateTime);
    }

    get daysInMonth() {
        if (this.isEmpty()) {
            return null;
        }

        return getDaysInMonth(this.dateTime);
    }
}
