import Vue from 'vue';
import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';// eslint-disable-line camelcase
import qs from 'qs';
import { reactive } from '@vue/composition-api';
import { globalStore } from '@/pinia/storeHelper.js';
import { apolloLogout, apolloLogin } from '@/plugins/vue-apollo.js';
import axios from './axios.js';
import i18n from './vue-i18n.js';
import ActivixDate from '../value-objects/ActivixDate.js';
import { showWarning, showUnauthenticated } from '../utils/toastr.js';

const authClient = new auth0.WebAuth({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    redirectUri: `${window.location.origin}/auth/callback`,
    clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
    responseType: 'token',
    scope: 'openid internal-access api manage-leads view-leads room:create',
});

const TOKEN_KEY = 'auth_access_token';
const TOKEN_EXPIRATION_KEY = 'auth_access_token_expire';
const IMPERSONATE_TOKEN_KEY = 'auth_impersonate_token';
const IMPERSONATING_KEY = 'auth_impersonnating';

class Auth {
    constructor() {
        this.data = reactive({
            accessToken: localStorage.getItem(TOKEN_KEY),
            expiration: localStorage.getItem(TOKEN_EXPIRATION_KEY) || 0,
            impersonating: localStorage.getItem(IMPERSONATING_KEY) == 'true',
            impersonateToken: localStorage.getItem(IMPERSONATE_TOKEN_KEY),
            profile: null,
            abilities: null,
            featurePreviews: null,
        });
    }

    /**
     * Auth actions
     */
    handshake() {
        const param = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        if (!this.isAccessTokenValid() && param.tk) {
            this.storeAuthenticationTokens({
                accessToken: param.tk,
                expiresIn: param.expires_in,
                singleUseToken: true,
            });

            return;
        }

        const location = window.location;

        if (
            location.pathname === '/auth/error' ||
            location.pathname === '/auth/callback' ||
            location.pathname === '/new-password' ||
            location.pathname.startsWith('/landing') ||
            location.pathname.startsWith('/sandbox')
        ) {
            return;
        }

        const now = new ActivixDate('now');
        const expiration = new ActivixDate(new Date(parseInt(this.data.expiration, 10)));
        const refreshTreshold = expiration.subMinutes(30);

        if (now.isBetween(refreshTreshold, expiration) || !this.isAuthenticated()) {
            this.handleRefreshToken(true, true).catch(() => {});
        } else {
            this.setAxiosToken();
        }
    }

    redirectToLogin(customState) {
        const payload = {
            appState: customState,
            connection: process.env.VUE_APP_AUTH0_CONNECTION,
        };

        if (localStorage.getItem('currentLocale')) {
            payload.ui_locales = localStorage.getItem('currentLocale');
        }

        authClient.authorize(payload);
    }

    handleRefreshToken(autoRedirect = false, handshake = false) {
        return new Promise((resolve, reject) => {
            if (!handshake && this.impersonating()) {
                this.unimpersonate({ updateBackend: false });
                resolve();
                return;
            }

            authClient.checkSession({}, (error, authResult) => {
                if (error) {
                    this.clear();

                    if (autoRedirect) {
                        this.redirectToLogin({ target: `${location.pathname}${location.search}` });
                    } else {
                        showUnauthenticated();
                    }

                    reject(error);
                    return;
                }

                this.storeAuthenticationTokens(authResult, true);
                resolve();
            });
        });
    }

    handleAuthentication() {
        authClient.parseHash((error, authResult) => {
            if (error) {
                Vue.router.push({
                    name: 'auth.error',
                    query: {
                        error: error.errorDescription,
                    },
                });
                return;
            }

            this.storeAuthenticationTokens(authResult);
        });
    }

    setAxiosToken() {
        axios.defaults.headers.common.Authorization = `Bearer ${this.token()}`;
        if (this.impersonating()) {
            axios.defaults.headers.common.UnmaskedAuthorization = `Bearer ${this.unmaskedToken()}`;
        }
    }

    storeAuthenticationTokens(authResult, redirect = false) {
        const expiration = new ActivixDate('now').addSeconds(authResult.expiresIn).timestamp * 1000; // Convert expiration to milliseconds

        this.data.accessToken = authResult.accessToken;
        this.data.expiration = expiration;

        localStorage.setItem(TOKEN_KEY, authResult.accessToken);
        localStorage.setItem(TOKEN_EXPIRATION_KEY, expiration);

        this.setAxiosToken();

        if (authResult.singleUseToken) {
            return;
        }

        this.fetchAndStoreProfile();

        if (!redirect) {
            this.redirectToPreviousState(authResult);
        }
    }

    async fetchAndStoreProfile() {
        const userProfile = await Vue.api.authUser.getProfile();
        const userAbilities = await Vue.api.authUser.getAbilities();
        const featurePreviews = await Vue.api.authUser.getFeaturePreviews();
        this.data.profile = userProfile;
        this.data.abilities = userAbilities;
        this.data.featurePreviews = featurePreviews;
    }

    redirectToPreviousState(authResult) {
        const appState = authResult.appState || {};
        let target = appState.target || '/';

        if (target === '/auth/logout') {
            target = '/';
        }

        Vue.router.push({ path: target });
    }

    async impersonate({ data }) {
        try {
            const response = await axios.post('/v1/auth/impersonate', data);

            this.data.impersonating = true;
            this.data.impersonateToken = response.data.access_token;

            this.setAxiosToken();

            await localStorage.setItem(IMPERSONATE_TOKEN_KEY, this.data.impersonateToken);
            await localStorage.setItem(IMPERSONATING_KEY, this.data.impersonating);

            await apolloLogin();

            const userProfile = await Vue.api.authUser.getProfile();
            this.data.profile = userProfile;

            const userAbilities = await Vue.api.authUser.getAbilities();
            this.data.abilities = userAbilities;

            const featurePreviews = await Vue.api.authUser.getFeaturePreviews();
            this.data.featurePreviews = featurePreviews;

            Vue.eventBus.$emit('load-initial-data');
        } catch (e) {
            // An error occurred can't impersonate
            showWarning(i18n.t('toastr.cantImpersonate'));
        }
    }

    async unimpersonate({ updateBackend = true } = {}) {
        if (!this.impersonating()) {
            return;
        }

        const impersonatedId = this.data.profile?.id || null;

        if (updateBackend) {
            await axios.post('/v1/auth/unimpersonate');
        }

        this.data.impersonating = false;
        this.data.impersonateToken = null;

        this.setAxiosToken();
        localStorage.removeItem(IMPERSONATE_TOKEN_KEY);
        localStorage.removeItem(IMPERSONATING_KEY);
        await apolloLogout();

        const userProfile = await Vue.api.authUser.getProfile();
        this.data.profile = userProfile;

        const userAbilities = await Vue.api.authUser.getAbilities();
        this.data.abilities = userAbilities;

        const featurePreviews = await Vue.api.authUser.getFeaturePreviews();
        this.data.featurePreviews = featurePreviews;

        Vue.nextTick(() => {
            Vue.router.push({
                name: 'users.update',
                params: { id: impersonatedId },
            });
            Vue.eventBus.$emit('load-initial-data');
        });
    }

    clear() {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(TOKEN_EXPIRATION_KEY);
        localStorage.removeItem(IMPERSONATE_TOKEN_KEY);
        localStorage.removeItem(IMPERSONATING_KEY);

        this.data.accessToken = null;
        this.data.expiration = null;
        this.data.profile = null;
        this.data.abilities = null;
        this.data.featurePreviews = null;
        this.data.impersonating = null;
        this.data.impersonateToken = null;

        globalStore().resetState();
        apolloLogout();
        Vue.ls.remove('context');
    }

    async logout() {
        await axios.post('logout');
        await apolloLogout();
        this.redirectToLogout();
    }

    async fetch() {
        if (this.isAuthenticated()) {
            this.fetchAndStoreProfile();
        }
    }

    redirectToLogout() {
        this.clear();

        authClient.logout({
            returnTo: `${window.location.origin}/auth/logout`,
        });
    }

    isAccessTokenValid() {
        if (!this.data.accessToken && !this.data.expiration) {
            return false;
        }

        return (
            this.data.accessToken &&
            this.data.expiration &&
            new ActivixDate('now').isBefore(new ActivixDate(new Date(parseInt(this.data.expiration, 10)) || null))
        );
    }

    isAuthenticated() {
        if (this.impersonating()) {
            return true;
        }

        if (!this.data.accessToken && !this.data.expiration) {
            return false;
        }

        if (
            new ActivixDate('now').isSameOrAfter(
                new ActivixDate(new Date(parseInt(this.data.expiration, 10)) || null),
            )
        ) {
            return false;
        }

        return true;
    }

    user() {
        return this.data.profile || {};
    }

    abilities() {
        return this.data.abilities || {};
    }

    featurePreviews() {
        return this.data.featurePreviews || [];
    }

    waitForUser() {
        return new Promise((resolve, reject) => {
            let count = 0;
            const isUserAvailable = () => {
                count++;

                if (this.ready()) {
                    resolve(this.user());
                    return;
                }

                // Retry for max 10 seconds.
                if (count >= 1000) {
                    reject();
                    return;
                }

                setTimeout(() => {
                    isUserAvailable();
                }, 10);
            };

            isUserAvailable();
        });
    }

    check() {
        return this.isAuthenticated();
    }

    ready() {
        return this.isAuthenticated() && this.data.profile;
    }

    token() {
        if (this.impersonating()) {
            return this.data.impersonateToken;
        }

        if (!this.isAccessTokenValid()) {
            return null;
        }

        return this.data.accessToken;
    }

    unmaskedToken() {
        if (!this.isAccessTokenValid()) {
            return null;
        }

        return this.data.accessToken;
    }

    audienceIsInSync() {
        const token = this.token();

        if (!token) {
            return false;
        }

        const tokenPayload = jwtDecode(token);
        const tokenAudience = tokenPayload?.aud || [];

        return tokenAudience.includes(process.env.VUE_APP_AUTH0_AUDIENCE);
    }

    impersonating() {
        return this.data.impersonating;
    }
}

export default Auth;
