var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-notifications',{attrs:{"width":_vm.notificationWidth,"duration":6000},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var ref_item = ref.item;
var type = ref_item.type;
var title = ref_item.title;
var text = ref_item.text;
var data = ref_item.data;
var close = ref.close;
return [_c('div',{class:type},[_c('div',{staticClass:"flex items-center flex-1 p-6 overflow-hidden"},[(data.icon)?_c('div',{staticClass:"mr-6"},[_c('icon',{staticClass:"w-7 h-7",class:data.iconClass,attrs:{"name":data.icon}})],1):_vm._e(),_c('div',{staticClass:"flex-1 overflow-hidden"},[(data.notification)?[(title)?_c('div',{staticClass:"font-bold truncate"},[(['taskSent', 'eventSent'].includes(data.notification.type))?_c('router-link',{attrs:{"to":{ name: 'calendar' }}},[_vm._v(" "+_vm._s(title)+" ")]):[_vm._v(" "+_vm._s(title)+" ")]],2):_vm._e(),(text)?_c('div',{domProps:{"innerHTML":_vm._s(text)}}):_vm._e(),(data.notification.leadUrl)?_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(_vm.$t('notificationCenter.lead'))+" : "),_c('router-link',{attrs:{"to":data.notification.leadUrl}},[_vm._v(" "+_vm._s(data.notification.leadName)+" ")]),(data.notification.date)?_c('span',[_vm._v("- "+_vm._s(data.notification.date))]):_vm._e()],1):_vm._e()]:[(title)?_c('div',{staticClass:"font-bold truncate"},[_vm._v(" "+_vm._s(title)+" ")]):_vm._e(),(text)?_c('div',{domProps:{"innerHTML":_vm._s(text)}}):_vm._e()]],2)]),_c('button',{staticClass:"close | absolute w-6 h-6 mr-2 mt-2 right-0 top-0 leading-none text-2xl",attrs:{"type":"button"},on:{"click":close}},[_vm._v(" × ")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }