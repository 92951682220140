export default {
    dms: 'DMS',
    email: 'Lead Web',
    event: 'Événement',
    loyalty: 'Loyauté',
    phone: 'Téléphone',
    preBooking: 'Pré-booking',
    renewal: '@.capitalize:lexicon.renewal.singular',
    sms: 'SMS',
    walkIn: '@.capitalize:lexicon.walkIn.singular',
    webOrder: 'Commande Web',

    abbreviations: {
        dms: 'DMS',
        email: 'Lead Web',
        event: 'Év.',
        loyalty: 'Loyauté',
        phone: 'Tél.',
        preBooking: 'Pré-b.',
        renewal: '@.capitalize:lexicon.renewal.abr',
        sms: 'SMS',
        walkIn: '@.capitalize:lexicon.walkIn.singular',
        webOrder: 'Commande Web',
    },
};
