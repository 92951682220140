import DashboardType from '@/entities/DashboardType.js';
import { mapActions, mapState } from 'pinia';
import { showConnectionTimeout } from '../../utils/toastr.js';

import { useDashboardStore } from '../../store/modules/dashboard/store.js';
import { useGlobalStore } from '../../store/store.js';
import { useContextStore } from '../../store/modules/context/store.js';

export default {
    render: () => null,

    computed: {
        ...mapState(useDashboardStore, {
            tableLeads: 'tableLeads',
            dashboardType: 'dashboardType',
        }),
        ...mapState(useContextStore, ['contextLead']),
    },

    methods: {
        ...mapActions(useGlobalStore, [
            'commentCreated',
            'commentDeleted',
            'commentUpdated',
            'communicationCreated',
            'communicationDeleted',
            'communicationUpdated',
            'bulkLeadCommunicatingStarted',
            'bulkLeadCommunicatingEnded',
            'leadEmailCreated',
            'leadEmailDeleted',
            'leadEmailUpdated',
            'leadPhoneCreated',
            'leadPhoneDeleted',
            'leadPhoneUpdated',
            'leadUpdatedAction',
            'leadVehicleCreated',
            'leadVehicleDeleted',
            'leadVehicleUpdated',
            'reloadLead',
            'taskEventCreated',
            'taskEventDeleted',
            'taskEventUpdated',
            'updateWebOrderBadgeCount',
            'deleteLead',
        ]),
        ...mapActions(useContextStore, [
            'deleteCustomerPhone',
            'deleteCustomerEmail',
            'reloadCustomerVehicle',
            'updateCustomer',
            'updateCustomerPhone',
            'updateCustomerEmail',
        ]),

        showEmailError(leadId) {
            let message = this.$t('toastr.errorMessages.sendingEmail');

            if (leadId) {
                const route = this.$router.resolve({
                    name: 'leads.update',
                    params: { id: leadId },
                });

                message += `
                    <a class="btn btn-warning" href="${route.href}" target="_blank">
                        ${this.$t('toastr.goToLead')}
                    </a>
                `;
            }

            this.$notify.warning({
                duration: -1,
                title: this.$t('toastr.error'),
                text: message,
            });
        },

        listenToGlobal() {
            if (!this.$broadcasting.channels.global) {
                return;
            }

            this.$broadcasting.channels.global
                .listen('.reload', () => {
                    showConnectionTimeout();
                })
                .listen('NetlifyDeployed', ({ buildId }) => {
                    if (buildId != process.env.VUE_APP_BUILD_ID) {
                        window.reloadNextPage = true;
                    }
                });
        },

        listenToAccount() {
            if (!this.$broadcasting.channels.account) {
                return;
            }

            // Lead
            this.$broadcasting.channels.account
                .listen('Lead.LeadUpdatedBroadcast', async ({ leadId }) => {
                    this.reloadLead(leadId);
                    this.updateDashboardLead(leadId);
                })
                .listen('Lead.LeadDeletedBroadcast', event => {
                    const lead = JSON.parse(event.lead);

                    this.updateDashboardLead(lead.id, true);
                    this.deleteLead(lead.id);
                });

            // Communication
            this.$broadcasting.channels.account
                .listen('Communication.CommunicationCreatedBroadcast', event => {
                    const communication = JSON.parse(event.communication);

                    this.updateDashboardLead(communication.lead_id);
                    this.communicationCreated(communication);
                })
                .listen('Communication.CommunicationUpdatedBroadcast', event => {
                    const communication = JSON.parse(event.communication);

                    this.updateDashboardLead(communication.lead_id);
                    this.communicationUpdated(communication);
                })
                .listen('Communication.CommunicationDeletedBroadcast', event => {
                    const communication = JSON.parse(event.communication);

                    this.updateDashboardLead(communication.lead_id);
                    this.communicationDeleted(communication);
                });

            // Communicating
            this.$broadcasting.channels.account
                .listen('LeadCommunicatingStarted', event => {
                    this.bulkLeadCommunicatingStarted(event.leads);
                })
                .listen('LeadCommunicatingEnded', event => {
                    this.bulkLeadCommunicatingEnded(event.leads);
                });

            // Customer
            this.$broadcasting.channels.account
                .listen('Customer.CustomerUpdatedBroadcast', async (customer) => {
                    this.updateCustomer(customer);
                });

            // Customer Phone
            this.$broadcasting.channels.account
                .listen('CustomerPhone.CustomerPhoneSavedBroadcast', async (customerPhone) => {
                    this.updateCustomerPhone(customerPhone);
                })
                .listen('CustomerPhone.CustomerPhoneDeletedBroadcast', async ({ customerPhoneId, customerId }) => {
                    this.deleteCustomerPhone(customerPhoneId, customerId);
                });

            // Customer Email
            this.$broadcasting.channels.account
                .listen('CustomerEmail.CustomerEmailSavedBroadcast', async (customerEmail) => {
                    this.updateCustomerEmail(customerEmail);
                })
                .listen('CustomerEmail.CustomerEmailDeletedBroadcast', async ({ customerEmailId, customerId }) => {
                    this.deleteCustomerEmail(customerEmailId, customerId);
                });

            // Customer Vehicles
            this.$broadcasting.channels.account
                .listen('CustomerVehicle.CustomerVehicleUpdatedBroadcast', async ({ customerVehicleId }) => {
                    this.reloadCustomerVehicle(customerVehicleId);
                })
                .listen('CustomerVehicleLead.CustomerVehicleUpdatedBroadcast', async ({ customerVehicleId }) => {
                    this.reloadCustomerVehicle(customerVehicleId);
                });

            // Lead Phone
            this.$broadcasting.channels.account
                .listen('LeadPhone.LeadPhoneCreatedBroadcast', event => {
                    const leadPhone = JSON.parse(event.leadPhone);

                    this.updateDashboardLead(leadPhone.lead_id);
                    this.leadPhoneCreated(leadPhone);
                })
                .listen('LeadPhone.LeadPhoneUpdatedBroadcast', event => {
                    const leadPhone = JSON.parse(event.leadPhone);

                    this.updateDashboardLead(leadPhone.lead_id);
                    this.leadPhoneUpdated(leadPhone);
                })
                .listen('LeadPhone.LeadPhoneDeletedBroadcast', event => {
                    const leadPhone = JSON.parse(event.leadPhone);

                    this.updateDashboardLead(leadPhone.lead_id);
                    this.leadPhoneDeleted(leadPhone);
                });

            // Lead Email
            this.$broadcasting.channels.account
                .listen('LeadEmail.LeadEmailCreatedBroadcast', event => {
                    const leadEmail = JSON.parse(event.leadEmail);

                    this.updateDashboardLead(leadEmail.lead_id);
                    this.leadEmailCreated(leadEmail);
                })
                .listen('LeadEmail.LeadEmailUpdatedBroadcast', event => {
                    const leadEmail = JSON.parse(event.leadEmail);

                    this.updateDashboardLead(leadEmail.lead_id);
                    this.leadEmailUpdated(leadEmail);
                })
                .listen('LeadEmail.LeadEmailDeletedBroadcast', event => {
                    const leadEmail = JSON.parse(event.leadEmail);

                    this.updateDashboardLead(leadEmail.lead_id);
                    this.leadEmailDeleted(leadEmail);
                });

            // Lead Vehicle
            this.$broadcasting.channels.account
                .listen('LeadVehicle.LeadVehicleCreatedBroadcast', event => {
                    const leadVehicle = JSON.parse(event.leadVehicle);

                    this.updateDashboardLead(leadVehicle.lead_id);
                    this.leadVehicleCreated(leadVehicle);
                })
                .listen('LeadVehicle.LeadVehicleUpdatedBroadcast', async ({ leadVehicleId }) => {
                    const leadVehicle = await this.$api.leadVehicles.show(leadVehicleId, {
                        with: {
                            suppliers: '*',
                            customFields: [
                                'custom_fields.id',
                            ],
                        },
                    });

                    this.updateDashboardLead(leadVehicle.lead_id);
                    this.leadVehicleUpdated(leadVehicle);
                })
                .listen('LeadVehicle.LeadVehicleDeletedBroadcast', leadVehicle => {
                    this.updateDashboardLead(leadVehicle.lead_id);
                    this.leadVehicleDeleted(leadVehicle);
                });

            // Comment
            this.$broadcasting.channels.account
                .listen('Comment.CommentCreated', ({ comment }) => {
                    this.updateDashboardLead(comment.lead_id);
                    this.commentCreated(comment);
                })
                .listen('Comment.CommentUpdated', ({ comment }) => {
                    this.updateDashboardLead(comment.lead_id);
                    this.commentUpdated(comment);
                })
                .listen('Comment.CommentDeleted', ({ comment }) => {
                    this.updateDashboardLead(comment.lead_id);
                    this.commentDeleted(comment);
                });

            // TaskEvents
            this.$broadcasting.channels.account
                .listen('TaskEvent.TaskEventCreatedBroadcast', taskEvent => {
                    this.updateDashboardLead(taskEvent.lead_id);
                    this.taskEventCreated(taskEvent);
                })
                .listen('TaskEvent.TaskEventUpdatedBroadcast', taskEvent => {
                    this.updateDashboardLead(taskEvent.lead_id);
                    this.taskEventUpdated(taskEvent);
                })
                .listen('TaskEvent.TaskEventDeletedBroadcast', ({ taskEventId, leadId }) => {
                    this.updateDashboardLead(leadId);
                    this.taskEventDeleted({ id: taskEventId, lead_id: leadId });
                });

            // In turn
            this.$broadcasting.channels.account.listen('UpdateInTurn', () => {
                this.$eventBus.$emit('update-in-turn');
            });

            //
            this.$broadcasting.channels.account.listen('WebOrderBadgeCountUpdated', ({ badgeCount }) => {
                this.updateWebOrderBadgeCount(badgeCount);
            });
        },

        listenToUser() {
            if (!this.$broadcasting.channels.user) {
                return;
            }

            this.$broadcasting.channels.user
                .notification(notification => {
                    switch (notification.type) {
                        case 'App\\Notifications\\NextInTurn':
                        case 'App\\Notifications\\NoUserInTurn':
                        case 'App\\Notifications\\InTurnRemove':
                            this.$eventBus.$emit('notification-in-turn', notification);
                            break;
                    }
                })
                .listen('LeadXpressEmailError', data => {
                    const leadId = data.leadId != this.contextLead.id ? data.leadId : null;

                    this.showEmailError(leadId);
                })
                .listen('Notification.NewNotification', ({ notification }) => {
                    this.$eventBus.$emit('new-notification', notification);
                })
                .listen('Notification.NotificationSeen', ({ notification }) => {
                    this.$eventBus.$emit('notification-seen', notification);
                })
                .listen('Notification.NotificationGroupSeen', ({ group }) => {
                    this.$eventBus.$emit('notification-group-seen', group);
                })
                .listen('ExportLeadError', data => {
                    this.$eventBus.$emit('export-lead-error', data);
                })
                .listen('CallEnded', async event => {
                    const lead = await this.$api.leads.show(event.leadId);

                    this.$eventBus.$emit('show-next-step', {
                        lead,
                        afterCallNextStep: true,
                    });
                })
                .listen('ScreenPopOpen', async event => {
                    this.$eventBus.$emit('screen-pop-open', event.leadId, event.type);
                })
                .listen('ScreenPopClose', () => {
                    this.$eventBus.$emit('screen-pop-close');
                })
                .listen('ScreenPopVideoConferenceOpen', async event => {
                    this.$eventBus.$emit('screen-pop-video-conference-open', event);
                })
                .listen('ScreenPopVideoConferenceClose', () => {
                    this.$eventBus.$emit('screen-pop-video-conference-close');
                })
                .listen('UserSuspended', () => {
                    window.reloadNextPage = true;
                })
                .listen('BulkActionCompleted', async event => {
                    this.bulkActionCompleted(event);
                });

            this.$broadcasting.channels.user.listenForWhisper('closeAllTabs', () => {
                this.$eventBus.$emit('close-task-event');
            });
        },

        updateDashboardLead(id, showIndicator = false) {
            if (this.dashboardType == DashboardType.ACTIVITY) {
                return;
            }

            if (showIndicator && this.tableLeads.some(l => l.id == id)) {
                this.$wait.start('indicator.leadTable');
            }

            this.$eventBus.$emit('fetch-stats', { passive: true, background: true });
        },

        bulkActionCompleted(event) {
            let message = '';
            const route = this.$router.resolve({
                name: 'leads.update',
                params: { id: event.leadId },
            });

            switch (event.type) {
                case 'associated':
                case 'merge':
                    message += `
                        <button class="btn btn-warning" data-vue-router="${route.href}">
                            ${this.$t('toastr.goToLead')}
                        </button>
                    `;
                    this.reloadLead(event.leadId);

                    break;
                case 'failed':
                    this.$notify.error({
                        title: this.$t('toastr.error'),
                        text: event.message,
                    });

                    return;
            }

            message = event.message + message;

            this.$notify.success({
                title: this.$t('toastr.success'),
                text: message,
            });

            this.$eventBus.$emit('fetch-table-leads');
        },
    },

    created() {
        this.listenToGlobal();
        this.listenToAccount();
        this.listenToUser();

        this.$broadcasting.$on('global-channel-ready', this.listenToGlobal);
        this.$broadcasting.$on('account-channel-ready', this.listenToAccount);
        this.$broadcasting.$on('user-channel-ready', this.listenToUser);
    },

    beforeDestroy() {
        this.$broadcasting.$off('global-channel-ready', this.listenToGlobal);
        this.$broadcasting.$off('account-channel-ready', this.listenToAccount);
        this.$broadcasting.$off('user-channel-ready', this.listenToUser);
    },
};
