import { isEmpty } from 'lodash-es';

import { contextStore } from '@/pinia/storeHelper';
import { useContextStore } from '../store/modules/context/store.js';
import i18n from '../plugins/vue-i18n.js';

import ActivixDate from '../value-objects/ActivixDate.js';
import Lead from './Lead.js';
import LeadVehicleFilter from './LeadVehicleFilter.js';
import LeadVehicleModality from './LeadVehicleModality.js';
import LeadVehicleType from './LeadVehicleType.js';
import Model from './Model.js';
import Supplier from './Supplier.js';

export default class LeadVehicle extends Model {
    static get defaults() {
        return {
            custom_fields: [],
            suppliers: [],
        };
    }

    static get exportableSuppliers() {
        return [Supplier.VAUTO, Supplier.AUTOPROPULSION, Supplier.QUICKTRADE];
    }

    set lead(lead) {
        this._lead = lead;
    }

    get lead() {
        if (!this.relationLoaded('lead') && this.lead_id) {
            const contextLead = contextStore().contextLead;

            if (contextLead.id === this.lead_id) {
                const leadValues = Object.fromEntries(
                    Object.entries(contextLead).filter(([key, value]) => {
                        return typeof value !== 'object' ||
                            ['customer', 'suppliers'].includes(key);
                    }),
                );

                this._lead = new Lead(leadValues);
            } else {
                this._lead = new Lead(this._lead);
            }
        }

        return this._lead;
    }

    get exportSupplier() {
        const suppliers = this.suppliers.filter(suppliable => {
            return LeadVehicle.exportableSuppliers.includes(suppliable.supplier_id || suppliable.id);
        });

        if (!suppliers.length) {
            return null;
        }

        return suppliers[0];
    }

    get isExported() {
        const supplier = this.exportSupplier;

        if (!supplier) {
            return false;
        }

        if (supplier.pivot) {
            return !!supplier.exported;
        }

        if (supplier.state) {
            return !!supplier.state.exported;
        }

        return false;
    }

    get isImported() {
        const supplier = this.exportSupplier;

        if (!supplier) {
            return false;
        }

        if (supplier.pivot) {
            return !!supplier.pivot.imported;
        }

        if (supplier.state) {
            return !!supplier.state.imported;
        }

        return false;
    }

    get supplierWithRelatedId() {
        return this.suppliers.find(supplier => supplier.related_id !== null);
    }

    get isExchange() {
        return this.type === LeadVehicleType.EXCHANGE;
    }

    get isWanted() {
        return this.type === LeadVehicleType.WANTED;
    }

    get isPolestarDeposited() {
        return this.custom_fields.some(customField => customField?.fixed_field === 'custom_field_order_deposit' && customField?.pivot?.value?.[0] === true);
    }

    get isPolestarOrder() {
        return this.suppliers.some(supplier => supplier?.state?.additional_information?.type === LeadVehicleFilter.ORDER);
    }

    get isPolestarTestDrive() {
        return this.suppliers.some(supplier => supplier?.state?.additional_information?.type === LeadVehicleFilter.TEST_DRIVE);
    }

    get isPolestarTestDriveAYL() {
        const testDriveSubTypeCustomField = this.custom_fields.find(customField => customField?.fixed_field === 'custom_field_sub_type');
        const subTypeValue = testDriveSubTypeCustomField?.pivot?.value?.[0]?.toLowerCase();

        return subTypeValue === 'drive out';
    }

    get isPolestarTestDriveTDM() {
        const testDriveSubTypeCustomField = this.custom_fields.find(customField => customField?.fixed_field === 'custom_field_sub_type');
        const subTypeValue = testDriveSubTypeCustomField?.pivot?.value?.[0]?.toLowerCase();

        return subTypeValue === 'event';
    }

    get totalPrice() {
        const price = parseFloat(this.price) || 0;
        const preparation = parseFloat(this.preparation) || 0;
        const accessories = parseFloat(this.accessories) || 0;
        const documentation = parseFloat(this.documentation) || null;

        return price + preparation + accessories + documentation;
    }

    get eligibleForRenewal() {
        return (
            new ActivixDate(this.end_contract_date).isEmpty() &&
            this.modality !== LeadVehicleModality.CASH &&
            !this.term
        );
    }

    static validType(type) {
        return LeadVehicleType.exists(type);
    }

    static isConsideredEmpty(vehicle, ignoreFields = []) {
        ignoreFields = [
            ...ignoreFields,
            'id',
            'lead_id',
            'type',
            'created_at',
            'created_by',
            'updated_at',
            'updated_by',
            'deleted_at',
            'deleted_by',
        ];

        for (const [key, value] of Object.entries(vehicle)) {
            if (!ignoreFields.includes(key) && !isEmpty(value) && value) {
                return false;
            }
        }

        return true;
    }

    static calculateVehicleValue(vehicle) {
        if (empty(vehicle.vehicle_value) || !vehicle.apply_cbb_value) {
            return vehicle.value ? parseFloat(vehicle.value) : 0;
        }

        let condition = vehicle.condition || useContextStore().account.renewal_alert_options.equity_base;

        if (condition == 'extra_clean') {
            condition = 'xclean';
        }

        const base = vehicle.vehicle_value[`base_${condition}`];
        const mileage = vehicle.vehicle_value[`mileage_${condition}`];

        return parseFloat(base) + parseFloat(mileage);
    }

    static calculateEquity(vehicle) {
        if (!vehicle.value) {
            return null;
        }

        if (!vehicle.balance && (!vehicle.payment || !vehicle.frequency)) {
            return null;
        }

        if (!vehicle.residual && vehicle.modality == LeadVehicleModality.LEASING) {
            return null;
        }

        let value = parseFloat(vehicle.calculator_value);
        if (!value) {
            value = vehicle.value ? parseFloat(vehicle.value) : 0;
        }

        const balance = vehicle.balance;
        const residual = vehicle.residual ? parseFloat(vehicle.residual) : 0;

        return value - balance - residual;
    }

    static calculateMonthsRemaining(endContractDate, displayMonths = false) {
        endContractDate = new ActivixDate(endContractDate);

        const diffInMonths = endContractDate.diffInMonths(new ActivixDate('now'));
        const monthRemaining = diffInMonths > 0 ? diffInMonths : 0;

        if (displayMonths) {
            return `${monthRemaining} ${i18n.t('clientCard.vehicles.months')}`;
        }

        return monthRemaining;
    }

    paymentRemaining() {
        if (!this.end_contract_date || !this.frequency) {
            return 0;
        }

        const endContractDate = new ActivixDate(this.end_contract_date);

        let payment = 0;

        if (this.modality == LeadVehicleModality.FINANCING) {
            payment += 1;
        }

        switch (this.frequency) {
            case 'two_weeks':
                payment += Math.floor(endContractDate.diffInWeeks(new ActivixDate('now')) / 2);
                break;

            case 'weekly':
                payment += endContractDate.diffInWeeks(new ActivixDate('now'));
                break;

            case 'bi_monthly':
                payment += Math.floor(endContractDate.diffInDays(new ActivixDate('now')) / 15.2);
                break;

            case 'monthly':
                payment += endContractDate.diffInMonths(new ActivixDate('now'));
                break;

            case 'one_payment':
                payment = 0;
                break;
        }

        return payment;
    }

    calculateEquity() {
        return LeadVehicle.calculateEquity(this);
    }

    calculateVehicleValue() {
        return LeadVehicle.calculateVehicleValue(this);
    }

    calculateBalance() {
        return this.balance;
    }

    isConsideredEmpty(ignoreFields = []) {
        return LeadVehicle.isConsideredEmpty(this, ignoreFields);
    }

    toString() {
        return [(this.make || '').trim(), (this.model || '').trim(), (this.year || '').trim()].filter(v => v).join(' ');
    }

    static hasField(field, vehicleType, account, user = null) {
        switch (vehicleType) {
            case LeadVehicleType.EXCHANGE:
                return this.hasExchangeField(field, account, user);
            case LeadVehicleType.WANTED:
                return this.hasWantedField(field, account, user);
            default:
                return false;
        }
    }

    static hasExchangeField(field, account, user = null) {
        if (!account.hasClientCardField(field, LeadVehicleType.EXCHANGE)) {
            switch (field) {
                case 'end_contract_date':
                case 'modality':
                    return account.auto_renewal;

                default:
                    return false;
            }
        }

        switch (field) {
            case 'accidented_damage_cost':
            case 'actual_value':
            case 'tire_type':
            case 'trade_notes':
            case 'trade_type':
                return account.trade_report && (!user || user.trade_report || user.isAdmin());

            case 'end_warranty_date':
                return account.service || account.equity_alert;

            case 'extended_warranty':
            case 'license_plate':
            case 'purchase_date':
            case 'recall':
                return account.service;

            default:
                return true;
        }
    }

    static hasWantedField(field, account) {
        if (!account.hasClientCardField(field, LeadVehicleType.WANTED)) {
            switch (field) {
                case 'end_contract_date':
                case 'modality':
                    return account.auto_renewal;

                case 'verified_by_id':
                    return account.verified_sale;

                default:
                    return false;
            }
        }

        return true;
    }
}
