const MOBILE_APP_PACKAGE_NAME = 'ca.activix.mobile.scanner';

const Browser = {
    install(Vue) {
        if (this.installed) {
            return;
        }

        this.installed = true;

        const state = {
            isMobileWebView: null,
            isAndroid: null,
            isIOS: null,
            messageListeners: [],
        };

        const plugin = {
            isMobileWebView: () => {
                if (typeof state.isMobileWebView !== 'boolean') {
                    state.isMobileWebView = navigator.userAgent.includes(MOBILE_APP_PACKAGE_NAME);
                }

                return state.isMobileWebView;
            },

            isAndroid: () => {
                if (typeof state.isAndroid !== 'boolean') {
                    state.isAndroid = navigator.appVersion.includes('Android');
                }

                return state.isAndroid;
            },

            isIOS: () => {
                if (typeof state.isIOS !== 'boolean') {
                    state.isIOS = navigator.appVersion.includes('iPhone') || navigator.appVersion.includes('iPad');
                }

                return state.isIOS;
            },

            mobileApp: {
                postMessage: (type, data) => {
                    if (!window.ReactNativeWebView || !plugin.isMobileWebView()) {
                        return;
                    }

                    window.ReactNativeWebView.postMessage(JSON.stringify({ [type]: data }));
                },

                addMessageListener: (name, callback) => {
                    state.messageListeners.push({ name, callback });

                    return {
                        remove: () => {
                            state.messageListeners = state.messageListeners
                                .filter(listener => listener.name !== name && listener.callback !== callback);
                        },
                    };
                },
            },
        };

        (plugin.isAndroid() ? document : window).addEventListener('message', (e) => {
            const name = e.data;

            state.messageListeners.forEach(listener => {
                if (listener.name !== name) {
                    return;
                }

                listener.callback();
            });
        });

        Vue.browser = plugin;
        Vue.prototype.$browser = plugin;
    },
};

export default Browser;
