/**
 * Base on the following repository:
 * @link https://github.com/yuanqing/autosize-input
 */
const GHOST_ELEMENT_ID = '__autosizeInputGhost';

const characterEntities = {
    ' ': 'nbsp',
    '<': 'lt',
    '>': 'gt',
};

const mapSpecialCharacterToCharacterEntity = specialCharacter => {
    return `&${characterEntities[specialCharacter]};`;
};

const escapeSpecialCharacters = string => {
    return string.replace(/\s|<|>/g, mapSpecialCharacterToCharacterEntity);
};

class AutoSizeInput {
    constructor(element, options) {
        this.element = element;
        this.options = options;
        this.init();
    }

    init() {
        this.destroy();

        const elementStyle = window.getComputedStyle(this.element);

        this.elementCssText = `
            box-sizing: ${elementStyle.boxSizing};
            border-left: ${elementStyle.borderLeftWidth} solid red;
            border-right: ${elementStyle.borderRightWidth} solid red;
            font-family: ${elementStyle.fontFamily};
            font-feature-settings: ${elementStyle.fontFeatureSettings};
            font-kerning: ${elementStyle.fontKerning};
            font-size: ${elementStyle.fontSize};
            font-stretch: ${elementStyle.fontStretch};
            font-style: ${elementStyle.fontStyle};
            font-variant: ${elementStyle.fontVariant};
            font-variant-caps: ${elementStyle.fontVariantCaps};
            font-variant-ligatures: ${elementStyle.fontVariantLigatures};
            font-variant-numeric: ${elementStyle.fontVariantNumeric};
            font-weight: ${elementStyle.fontWeight};
            letter-spacing: ${elementStyle.letterSpacing};
            margin-left: ${elementStyle.marginLeft};
            margin-right: ${elementStyle.marginRight};
            padding-left: ${elementStyle.paddingLeft};
            padding-right: ${elementStyle.paddingRight};
            text-indent: ${elementStyle.textIndent};
            text-transform: ${elementStyle.textTransform}
        `;

        if (this.options && this.options.onInput) {
            this.element.addEventListener('input', this.setWidth);
        }

        const width = this.setWidth();

        // Set "min-width" only if "options.minWidth" was set, and only if the initial width is non-zero.
        if (this.options && this.options.minWidth && width !== '0px') {
            this.element.style.minWidth = width;
        }
    }

    // Unbind the event listener and remove the "ghostElement".
    destroy() {
        if (this.options && this.options.onInput) {
            this.element.removeEventListener('input', this.setWidth);
        }

        const ghostElement = document.getElementById(GHOST_ELEMENT_ID);

        if (ghostElement) {
            ghostElement.parentNode.removeChild(ghostElement);
        }
    }

    setWidth(minWidth) {
        // Assigns an appropriate width to the given "element" based on its contents.
        const string = this.element.value || this.element.getAttribute('placeholder') || '';

        // Check if the "ghostElement" exists. If no, create it.
        const ghostElement = document.getElementById(GHOST_ELEMENT_ID) || this.createGhostElement();

        // Copy all width-affecting styles to the "ghostElement".
        ghostElement.style.cssText += this.elementCssText;
        ghostElement.innerHTML = escapeSpecialCharacters(string);

        // Copy the width of "ghostElement" to "element".
        let width = window.getComputedStyle(ghostElement).width;

        // Set minimum width
        if (minWidth !== null && parseInt(width, 10) < minWidth) {
            width = `${minWidth}px`;
        }

        this.element.style.width = width;

        return width;
    }

    // Create "ghostElement", with inline styles to hide it and ensure that the text is all on a single line.
    createGhostElement() {
        const ghostElement = document.createElement('div');

        ghostElement.id = GHOST_ELEMENT_ID;
        ghostElement.style.cssText = `
            display: inline-block;
            height: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            visibility: hidden;
            white-space: nowrap;
        `;

        document.body.appendChild(ghostElement);

        return ghostElement;
    }
}

export default function (element, options) {
    return new AutoSizeInput(element, options);
}
