<template>
    <div>
        <div class="alert alert-warning | text-center" v-if="leadBelongToSomeoneElse">
            {{ $t('responseLeadXpress.leadBelongToSomeoneElse', [userFullName]) }}
        </div>

        <div class="alert alert-warning | text-center" v-if="communicatingUser && !ownCommunication">
            <template v-if="communicatingType === CommunicationMethod.PHONE">
                {{ $t('responseLeadXpress.calling', [getFullName(communicatingUser)]) }}
            </template>
            <template v-else-if="communicatingType === CommunicationMethod.EMAIL">
                {{ $t('responseLeadXpress.sendingEmail', [getFullName(communicatingUser)]) }}
            </template>
            <template v-else-if="communicatingType === CommunicationMethod.SMS">
                {{ $t('responseLeadXpress.sendingSms', [getFullName(communicatingUser)]) }}
            </template>
        </div>

        <div class="alert alert-warning | text-center" v-if="recentCommunication">
            <template v-if="recentCommunication.communication_method_id == CommunicationMethod.PHONE">
                {{
                    $t('responseLeadXpress.alreadyCalled', [
                        getFullName(recentCommunication.user),
                        as_locale(recentCommunication.created_at, 'created_at').fromNow(),
                    ])
                }}
            </template>
            <template v-else>
                {{
                    $t('responseLeadXpress.alreadySentEmail', [
                        getFullName(recentCommunication.user),
                        as_locale(recentCommunication.created_at, 'created_at').fromNow(),
                    ])
                }}
            </template>
        </div>

        <div class="alert alert-grey | text-center" v-if="mostRecentCommunication">
            <div class="mb-3" v-html="alreadyCommunicatedText" />

            <activix-tooltip :content="$t('responseLeadXpress.ignoreTooltip')">
                <activix-button type="white" @click="dismiss">
                    {{ $t('responseLeadXpress.ignore') }}
                </activix-button>
            </activix-tooltip>
            <activix-tooltip
                :content="$t('responseLeadXpress.notifyTooltip', [mostRecentCommunication.user.first_name])"
            >
                <activix-button class="ml-2" type="white" @click="notify">
                    {{ $t('responseLeadXpress.notify') }}
                </activix-button>
            </activix-tooltip>
        </div>

        <!-- Mass mailing alert -->
        <div
            class="alert alert-info | text-center"
            v-if="massMailingLeadsRemoved && massMailingLeadsRemoved.length > 0"
        >
            <h4 v-if="massMailingLeadsRemoved.length > 1">
                {{ $t('mailClient.removedFromMassMailingPlural', [massMailingLeadsRemoved.length]) }}
            </h4>
            <h4 v-else>
                {{ $t('mailClient.removedFromMassMailing', [massMailingLeadsRemoved.length]) }}
            </h4>

            <a data-toggle="collapse" href="#collapse-removed-from-list">
                {{ massMailingLeadsRemoved.length > 1 ? $t('mailClient.showLeads') : $t('mailClient.showLead') }}
            </a>

            <span id="collapse-removed-from-list" class="panel-collapse collapse">
                <div :key="index" v-for="(massMailingLead, index) in massMailingLeadsRemoved">
                    {{ massMailingLead.name }} - {{ $t('mailClient.contactedBy', [massMailingLead.user]) }}
                </div>
            </span>
        </div>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { mapActions, mapState } from 'pinia';

    // Entities
    import Lead from '../entities/Lead.js';
    import Role from '../entities/Role.js';
    import CommunicationMethod from '../entities/CommunicationMethod.js';
    import { useGlobalStore } from '../store/store.js';

    export default {
        components: {},

        props: {
            lead: {
                type: Lead,
                required: true,
            },
            communicatingType: {
                type: Number,
                default: null,
            },
            communicatingUser: {
                default: null,
            },
            recentCommunication: {
                default: null,
            },
            mostRecentCommunication: {
                default: null,
            },
            massMailingLeadsRemoved: {
                type: Array,
                default() {
                    return [];
                },
            },
        },

        data() {
            return {
                CommunicationMethod,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'parentAuthUser']),

            ownCommunication() {
                return this.communicatingUser.id === this.parentAuthUser?.id || this.communicatingUser.id === this.authUser.id;
            },

            alreadyCommunicatedText() {
                if (!this.mostRecentCommunication) return '';

                const route = this.$router.resolve({
                    name: 'leads.update',
                    params: { id: this.mostRecentCommunication.lead_id },
                });

                const key =
                    this.mostRecentCommunication.communication_method_id == CommunicationMethod.PHONE
                        ? 'alreadyCalledLink'
                        : 'alreadyEmailedLink';

                return this.$t(`responseLeadXpress.${key}`, {
                    userName: this.getFullName(this.mostRecentCommunication.user),
                    date: as_locale(this.mostRecentCommunication.created_at, 'created_at').fromNow(),
                    link: route.href,
                });
            },

            currentUser() {
                const authUser = (this.parentAuthUser.children || []).find(
                    child => child.account_id === this.lead.account_id,
                );

                return authUser || this.authUser;
            },

            leadBelongToSomeoneElse() {
                if (
                    this.lead.user_id &&
                    this.currentUser.isAdvisor() &&
                    !this.currentUser.isSameUser(this.lead.user_id)
                ) {
                    return true;
                }

                if (
                    this.lead.bdc_user_id &&
                    this.currentUser.isBdc() &&
                    !this.currentUser.isSameUser(this.lead.bdc_user_id)
                ) {
                    return true;
                }

                return false;
            },

            userFullName() {
                if (this.currentUser.isAdvisor()) {
                    return this.lead.user.fullName;
                }

                if (this.currentUser.isBdc()) {
                    return this.lead.bdc_user.fullName;
                }

                return '';
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            dismiss() {
                this.$emit('dismiss');
            },

            notify() {
                const data = {};

                if (this.mostRecentCommunication.user.role_id == Role.ADVISOR) {
                    data.user_id = this.mostRecentCommunication.user_id;
                } else if (this.mostRecentCommunication.user.role_id == Role.AGENT) {
                    data.bdc_user_id = this.mostRecentCommunication.user_id;
                } else if (this.mostRecentCommunication.user.role_id == Role.COMMERCIAL) {
                    data.commercial_id = this.mostRecentCommunication.user_id;
                } else {
                    data.user_id = this.mostRecentCommunication.user_id;
                }

                this.updateLeadAction(this.lead.id, data);

                this.$emit('close');
            },
        },
    };
</script>
