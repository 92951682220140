export default {
    addModel: 'Créer un nouveau modèle de lettre',
    advisorCell: '# Cellulaire conseiller',
    advisorName: 'Nom du conseiller',
    advisorPost: 'Poste du conseiller',
    alerts: {
        index: {
            error: 'Échec de la récupération des modèles',
        },
        store: {
            error: 'Échec de la création du modèle',
            success: 'Modèle créé avec succès',
        },
        show: {
            error: 'Échec de la récupération du modèle',
        },
        update: {
            error: 'Échec de la mise à jour du modèle',
            success: 'Modèle mis à jour avec succès',
        },
        destroy: {
            error: 'Échec de la suppression du modèle',
            success: 'Modèle supprimé avec succès',
        },
        duplicate: {
            error: 'Échec lors de la duplication du modèle',
            success: 'Modèle dupliqué avec succès',
        },
        favorite: {
            error: 'Échec pour ajouter le modèle dans la liste de favoris.',
            success: 'Modèle ajouté à votre liste de favoris.',
        },
        unFavorite: {
            error: 'Échec pour retirer le modèle de votre liste de favoris.',
            success: 'Modèle retiré de votre liste de favoris.',
        },
    },
    askDeleteTemplate: 'Voulez-vous supprimer la traduction ? Cette action est irréversible.',
    assignedOnly: 'Assigné seulement',
    assignedInfoCc: "Utilise les CC par défaut uniquement si ce courriel est celui d'un utilisateur assigné au lead.",
    assignedInfoBcc: "Utilise les CCI par défaut uniquement si ce courriel est celui d'un utilisateur assigné au lead.",
    attachments: 'Fichiers',
    attributsList: 'Attributs',
    category: 'Catégorie',
    content: 'Contenu',
    copyTemplate: 'Copier vers {0}',
    create: 'Nouveau',
    createdAt: 'Date de création',
    date: 'Date personnalisée',
    dealerName: 'Nom de la concession',
    deleted: 'Supprimé',
    delete: 'Supprimer',
    deleteModal: 'Voulez-vous vraiment supprimer ce modèle ?',
    division: 'Division',
    duplicate: 'Dupliquer',
    edit: 'Modifier',
    editModel: 'Modifier un modèle de lettre',
    email: 'Courriel',
    body: {
        email: 'Corps',
        notification: 'Corps courriel',
    },
    subject: {
        email: 'Sujet',
        notification: 'Sujet',
    },
    preview: {
        email: 'Prévisualisation courriel',
        notification: 'Prévisualisation écran et mobile',
    },
    leadName: 'Nom du lead',
    excerpt: 'Corps écran et mobile',
    emptyTemplate: 'Ajouter {0} vide',
    empty: {
        choose: 'Choisissez le type d\'éditeur souhaité.',
        basic: 'Éditeur de base',
        basicDescription: 'Éditeur de texte rapide et typique. Permet la manipulation de base du texte et des images. <strong class="font-semibold">Préférable pour les réponses automatiques et les conversations interhumaines</strong>.',
        advanced: 'Éditeur avancé',
        advancedDescription: 'Permet une grande quantité de manipulations dont l\'ajout de colonnes, de couleurs d\'arrière-plan, de vues réactives et d\'autres outils spécialisés. <strong class="font-semibold">Préférable pour les courriels marketing et promotionnels</strong>.',
        alert: 'Le passage d\'un type d\'éditeur à l\'autre effacera le contenu.',
    },
    en: 'Anglais',
    errorUploading: "Une erreur est survenue lors du téléversement de l'image.",
    unFavoriteModal: 'Voulez-vous retirer ce modèle de votre liste de favoris?',
    fr: 'Français',
    fullscreen: 'Plein écran',
    gender: 'Genre',
    goToBasic: 'Accéder à l\'éditeur de base',
    goToAdvanced: 'Accéder à l\'éditeur avancé',
    interestedBy: 'Intéressé par',
    leadPhone: '# Téléphone',
    locales: {
        en: {
            name: 'Anglais',
            realName: 'English',
        },
        fr: {
            name: 'Français',
            realName: 'Français',
        },
    },
    name: 'Nom',
    noSelection: 'Aucune sélection',
    notification: 'Notification Interne',
    overwriteTemplate: 'Écraser depuis {0}',
    phrase: 'Phrase',
    prefix: 'Civilité',
    previewMessage: 'Aperçu de votre message',
    price: 'Prix',
    result: 'Résultat',
    selectAccountWarning: 'Vous devez sélectionner un compte dans le menu déroulant ci-dessus.',
    selectTemplate: 'Cliquez sur un des liens ci-dessous pour modifier le modèle',
    submit: 'Soumettre',
    doYouWantToSave: 'Voulez-vous sauvegarder?',
    sureSetMassMailing:
        'Assurez-vous que 100% des destinataires ont les informations dans tous les champs des attributs sélectionnés pour éviter des messages avec des informations manquantes ou inadéquates.<br><br>@:general.wishToProceed',
    templateTitle: {
        email: 'Titre du modèle de lettre',
        notification: 'Titre du modèle de notification',
        tooltip: 'Le titre doit faire au moins 10 charactères',
    },
    templateTitleDuplicate: 'Le titre du modèle existe déjà',
    title: 'Modèles de lettre',
    toogleAdvanced: 'Changer le mode d\'édition entraine la suppression de tout le contenu. Cette action est irréversible. Voulez-vous continuer?',
    type: 'Type',
    unsubscribe: 'Se désabonner',
    subscribe: 'Confirmer votre adhésion',
    options: 'Paramètres',
    textAlign: 'Alignement',
    vehicle: 'Véhicule',
    vehicleLink: 'Lien du véhicule',
};
