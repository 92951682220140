<template>
    <div class="inline-flex" :class="[sizeClass, colorClass, { 'switcher-disabled': disabled }]" @click.stop>
        <label class="flex items-center font-normal m-0 text-grey-800">
            <input
                :id="computedId"
                class="switcher-toggle | hidden z-0"
                type="checkbox"
                :disabled="disabled"
                :checked="value"
                @change="onChange"
            />
            <activix-tooltip :content="disabled ? disabledTooltip : ''">
                <div
                    class="switcher-toggle__indicator | flex-shrink-0"
                    :class="disabled ? 'cursor-not-allowed opacity-75' : 'cursor-pointer'"
                />
            </activix-tooltip>
            <div
                class="ml-2 truncate"
                :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"
                v-if="$slots.default"
            >
                <slot />
            </div>
        </label>
    </div>
</template>

<script>
    import { uniqueId } from 'lodash-es';

    export default {
        name: 'ActivixSwitcher',

        props: {
            id: {
                type: String,
                default: '',
            },
            disabledTooltip: {
                type: String,
                default: '',
            },
            value: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            color: {
                type: String,
                default: 'blue',
            },
            size: {
                type: String,
                default: 'medium',
            },
        },

        computed: {
            computedId() {
                return this.id || uniqueId('switcher-');
            },

            sizeClass() {
                if (this.size == 'x-small') {
                    return 'switcher-xs';
                }

                if (this.size == 'small') {
                    return 'switcher-sm';
                }

                return 'switcher-md';
            },

            colorClass() {
                return `switcher-${this.color}`;
            },
        },

        methods: {
            onChange(event) {
                this.$emit('input', event.target.checked);
                event.target.checked = !event.target.checked;
            },
        },
    };
</script>

<style lang="less">
    @switcher-size-md: 18px;
    @switcher-size-sm: 16px;
    @switcher-size-xs: 14px;

    .switcher-toggle {
        & + .switcher-toggle__indicator {
            @apply relative rounded-full bg-grey-300;
            user-select: none;

            &:before,
            &:after {
                @apply block absolute;
                top: 1px;
                left: 1px;
                bottom: 1px;
                content: '';
            }

            &:before {
                @apply rounded-full bg-grey-200;
                right: 1px;
                transition: background 0.4s;
            }

            &:after {
                @apply bg-white rounded-full;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
                transition: margin 0.4s;
            }
        }
    }

    // Colors
    .switcher-toggle:checked {
        & + .switcher-toggle__indicator:before {
            .switcher-blue & {
                @apply bg-blue-500;
            }

            .switcher-blue.switcher-disabled & {
                @apply bg-blue-400;
            }

            .switcher-green & {
                @apply bg-green-500;
            }

            .switcher-green.switcher-disabled & {
                @apply bg-green-400;
            }

            .switcher-orange & {
                @apply bg-orange-500;
            }

            .switcher-orange.switcher-disabled & {
                @apply bg-orange-400;
            }

            .switcher-grey & {
                @apply bg-grey-600;
            }

            .switcher-grey.switcher-disabled & {
                @apply bg-grey-500;
            }
        }
    }

    // Sizes
    &.switcher-xs {
        .switcher-toggle + .switcher-toggle__indicator {
            width: @switcher-size-xs * 2;
            height: @switcher-size-xs;
        }

        .switcher-toggle + .switcher-toggle__indicator:after {
            width: @switcher-size-xs - 2;
        }

        .switcher-toggle:checked + .switcher-toggle__indicator:after {
            margin-left: @switcher-size-xs;
        }
    }

    &.switcher-sm {
        .switcher-toggle + .switcher-toggle__indicator {
            width: @switcher-size-sm * 2;
            height: @switcher-size-sm;
        }

        .switcher-toggle + .switcher-toggle__indicator:after {
            width: @switcher-size-sm - 2;
        }

        .switcher-toggle:checked + .switcher-toggle__indicator:after {
            margin-left: @switcher-size-sm;
        }
    }

    &.switcher-md {
        .switcher-toggle + .switcher-toggle__indicator {
            width: @switcher-size-md * 2;
            height: @switcher-size-md;
        }

        .switcher-toggle + .switcher-toggle__indicator:after {
            width: @switcher-size-md - 2;
        }

        .switcher-toggle:checked + .switcher-toggle__indicator:after {
            margin-left: @switcher-size-md;
        }
    }
</style>
