<template>
    <div :class="{ loading: communicating }" class="-ml-4 flex justify-center" v-if="!callInProgress && !loading">
        <a
            :href="`tel:${phoneLead.value}`"
            :data-lead-id="phoneLead.lead_id"
            class="ml-4 text-gray-700 w-1/3 bg-gray-200 shadow rounded-lg overflow-hidden | hover:bg-gray-300 hover:text-gray-800 hover:shadow-md | transition duration-300"
            :key="phoneLead.id"
            v-for="phoneLead in leadPhoneOptions"
        >
            <p class="p-1 mb-0">
                <icon class="text-inherit mr-1 mb-1" :name="getIcon(phoneLead)" />
                {{ $t(`call.${phoneLead.type}`) }}
            </p>
            <div class="bg-white p-3">
                <div class="text-grey-700">{{ phoneLead.label }}</div>
                <div class="flex justify-end items-center text-xs mt-2 -mb-2" v-if="sbkDialerDetected">
                    <span class="text-gray-700 mr-1">{{ $t('call.callWith') }}</span>
                    <img class="w-6" src="/img/supplier/sbk_dialer_logo.png" />
                </div>
            </div>
        </a>
    </div>
</template>

<script>
    export default {
        props: {
            leadPhoneOptions: {
                type: Array,
                required: true,
            },
            communicating: {
                type: Boolean,
                required: true,
            },
            callInProgress: {
                type: Boolean,
                required: true,
            },
            loading: {
                type: Boolean,
                required: true,
            },
        },

        computed: {
            sbkDialerDetected() {
                return this.$chromeExtensions.includes('sbkDialer');
            },
        },

        methods: {
            getIcon(item) {
                return {
                    home: 'regular/house-chimney-2',
                    work: 'regular/briefcase',
                    cell: 'regular/mobile-phone',
                }[item.type];
            },
        },
    };
</script>
