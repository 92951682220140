<template>
    <transition
        enter-active-class="transition duration-300 transform"
        enter-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transition duration-300 transform"
        leave-class="translate-x-0"
        leave-to-class="translate-x-full"
        @enter="visible = true"
        @after-leave="onHidden"
    >
        <!-- We must use v-show because it listens to an event. -->
        <aside
            class="z-20 fixed right-0 inset-y-0 w-full max-w-full bg-grey-150 shadow-l-lg mt-18 | md:w-80 | xl:w-96 | 2xl:w-128"
            :style="{ paddingTop: `${bodySpacingTop}px` }"
            v-show="opened"
        >
            <span v-show="activeSidebar === 'addLead'">
                <add-lead :visible="visible" />
            </span>
            <span v-if="activeSidebar === 'interAccountLeads'">
                <inter-account-leads />
            </span>
        </aside>
    </transition>
</template>

<script>
    import AddLead from '@/components/add_lead/AddLead.vue';
    import InterAccountLeads from '@/components/inter_account_leads/InterAccountLeads.vue';
    import { mapActions, mapState } from 'pinia';
    import { useLayoutStore } from '../store/modules/layout/store.js';

    export default {
        components: {
            AddLead,
            InterAccountLeads,
        },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            push: {
                type: Boolean,
                required: true,
            },
        },

        data: () => ({
            visible: false,
        }),

        computed: {
            ...mapState(useLayoutStore, ['activeSidebar', 'bodySpacingTop']),

            shouldHideNavigation() {
                return (
                    this.$route.name === 'leads.update' &&
                    this.$mq !== '3xl' &&
                    this.opened
                );
            },
        },

        watch: {
            '$route.name': {
                immediate: true,
                handler(routeName) {
                    if (routeName === 'leads.update') {
                        this.$emit('update:push', true);
                        this.hideNavigation();
                    } else {
                        this.$emit('update:push', false);
                        this.showNavigation();
                    }
                },
            },
        },

        methods: {
            ...mapActions(useLayoutStore, ['setActiveSidebar']),

            onHidden() {
                this.visible = false;
                this.setActiveSidebar('addLead');
            },

            show(activeSidebar) {
                if (activeSidebar === 'interAccountLeads' && this.activeSidebar === 'interAccountLeads') {
                    this.hide();
                    return;
                }

                this.setActiveSidebar(activeSidebar);
                this.$emit('update:opened', true);
                this.hideNavigation();
            },

            hide() {
                this.$emit('update:opened', false);
                this.showNavigation();
            },

            async showNavigation() {
                await this.$nextTick();

                if (!this.shouldHideNavigation) {
                    this.$eventBus.$emit('show-nav');
                }
            },

            async hideNavigation() {
                await this.$nextTick();

                if (this.shouldHideNavigation) {
                    this.$eventBus.$emit('hide-nav');
                }
            },
        },

        created() {
            this.hide();

            this.$eventBus.$on('show-right-sidebar', this.show);
            this.$eventBus.$on('hide-right-sidebar', this.hide);
        },

        beforeDestroy() {
            this.$eventBus.$off('show-right-sidebar', this.show);
            this.$eventBus.$off('hide-right-sidebar', this.hide);
        },
    };
</script>
