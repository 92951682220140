export default {
    activated: 'Activée',
    active: 'Active',
    applyAndReload: 'Appliquer et rafraîchir',
    autoActivated: 'Activée automatiquement',
    availableSince: 'Disponible depuis le {0}',
    browserNotSupportingVideoPlaying: 'Votre navigateur ne supporte pas la lecture vidéo.',
    deactivated: 'Désactivée',
    giveFeedback: 'Donner votre avis',
    newTag: 'Nouveau',
    notAutoActivated: 'N\'est pas activée automatiquement',
    projectedAdoptionOn: 'Adoption projetée au {0}',
    requestFeedback: 'Souhaitez-vous nous faire part de vos commentaires sur la fonctionnalité <strong>{0}</strong>?',
    title: 'Aperçu des fonctionnalités',
    willBe: 'Sera',

    newLeadPage: {
        feature1: 'Refonte entière de la fiche client',
        feature2: 'Interface plus conviviale',

        img1Placeholder: 'Nouveau design',
    },
};
