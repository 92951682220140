<script>
    import detectIt from 'detect-it';
    import { isFunction } from 'lodash-es';

    export default {
        name: 'ActivixTooltip',

        props: {
            content: {
                type: [String, Function],
                default: '',
            },
            placement: {
                type: String,
                default: 'top',
            },
            trigger: {
                type: String,
                default: 'mouseenter focus',
            },
            prerender: {
                type: Boolean,
                default: true,
            },
            onlyOnTruncate: {
                type: Boolean,
                default: false,
            },
            showOnMobile: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                instance: null,
            };
        },

        watch: {
            content() {
                if (!this.instance) {
                    this.init();
                    return;
                }

                const content = this.getContent();

                if (!content) {
                    this.destroy();
                } else {
                    this.instance.setContent(content);
                }
            },
        },

        methods: {
            onMouseEnter() {
                if (this.instance) {
                    this.instance.setContent(this.getContent());
                } else {
                    this.init(true);
                }

                this.$nextTick(() => {
                    if (this.getContent()) {
                        this.instance.show();
                    }
                });
            },

            onMouseLeave() {
                if (this.instance) {
                    this.instance.hide();
                }
            },

            getContent() {
                if (isFunction(this.content)) {
                    return this.content();
                }

                return this.content ? this.content.trim() : '';
            },

            getTrigger() {
                if (!this.prerender) {
                    return 'manual';
                }

                return this.trigger;
            },

            isOnlyOnTruncate() {
                return this.onlyOnTruncate && !(this.$el.offsetWidth < this.$el.scrollWidth);
            },

            async init(forceRender = false) {
                if (!this.showOnMobile && (this.lgLayout || detectIt.deviceType === 'touchOnly')) {
                    return;
                }

                if (!this.prerender && !forceRender) {
                    return;
                }

                if (this.isOnlyOnTruncate()) {
                    return;
                }

                const content = this.getContent();
                const trigger = this.getTrigger();

                if (!content) {
                    return;
                }

                await this.destroy();

                this.instance = this.$tooltip.init(this.$el, {
                    content,
                    placement: this.placement,
                    trigger,
                });
            },

            async destroy() {
                if (!this.instance) {
                    return;
                }

                if (typeof this.instance.destroy === 'function') {
                    this.instance.destroy(true);
                    this.instance = null;
                }

                await this.$nextTick();
            },
        },

        async mounted() {
            await this.$nextTick();

            this.init();

            if (!this.prerender) {
                this.$el.addEventListener('mouseenter', this.onMouseEnter);
                this.$el.addEventListener('mouseleave', this.onMouseLeave);
            }
        },

        beforeDestroy() {
            this.destroy();

            if (!this.prerender) {
                this.$el.removeEventListener('mouseenter', this.onMouseEnter);
                this.$el.removeEventListener('mouseleave', this.onMouseLeave);
            }
        },

        render() {
            return this.$slots.default;
        },
    };
</script>

<style>
    .tippy-tooltip {
        @apply transition shadow;
        background-color: theme('colors.grey.700');
    }
</style>
