import { mapState } from 'pinia';
import { useGlobalStore } from '@/store/store';

export default {
    computed: {
        ...mapState(useGlobalStore, ['configs']),
    },

    methods: {
        async getActivixExpUrl() {
            const schoolName = this.configs.locale == 'fr' ? 'activix' : 'activixen';
            const jwt = await this.$api.activixExp.sso();

            return `https://${schoolName}.thinkific.com/api/sso/v2/sso/jwt?jwt=${jwt}`;
        },

        async openActivixExp(inNewTab = true) {
            const url = await this.getActivixExpUrl();

            if (inNewTab) {
                window.open(url, '_blank');
            } else {
                window.location.replace(url);
            }
        },
    },
};
