export default {
    currentCommunication: {},
    currentCommunicationId: null,
    vehicles: [],
    vehicleMakes: [],
    vehicleModels: {},
    vehicleTrims: {},
    vehicleMakeWarranties: {},
    replyingEmail: false,
};
