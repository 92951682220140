<template>
    <div
        class="nav-item"
        :class="{ 'nav-opened': isOpened, 'nav-active': isActive && showActive }"
    >
        <router-link :to="to" :class="moduleActive ? 'nav-link' : 'nav-link-inactive'">
            <div class="flex-1 flex items-center">
                <icon class="nav-icon" :class="{ 'flip-x': flipIcon }" :name="icon" />
                <div class="relative flex items-center space-x-2">
                    <span v-text="title" />
                    <activix-tooltip :content="badgeTooltip" v-if="badge">
                        <div class="badge badge-danger | h-4 min-w-4 text-xs">
                            {{ badge > 99 ? '99+' : badge }}
                        </div>
                    </activix-tooltip>
                </div>
            </div>
            <icon
                class="text-xs text-white"
                :class="{ 'rotate-1/4': !isOpened }"
                :name="$icons.expand"
                @click.prevent="opened = !opened"
                v-if="$slots.default"
            />
        </router-link>
        <div :class="isOpened ? 'block' : 'hidden'" v-if="$slots.default">
            <slot />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        props: {
            to: {
                type: [Object, String],
                required: true,
            },
            icon: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            forceOpened: {
                type: Boolean,
                default: false,
            },
            forceActive: {
                type: Boolean,
                default: false,
            },
            moduleActive: {
                type: Boolean,
                default: true,
            },
            flipIcon: {
                type: Boolean,
                default: false,
            },
            badge: {
                type: Number,
                default: 0,
            },
            badgeTooltip: {
                type: String,
                default: '',
            },
            showActive: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                opened: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['parentAuthUser']),

            isActive() {
                return this.$route.name === this.to.name || this.forceActive;
            },

            isOpened() {
                return this.opened || this.forceOpened;
            },
        },

        watch: {
            '$route.name'() {
                this.opened = false;
            },
        },
    };
</script>

<style lang="less">
    .nav-item {
        @apply relative;

        .nav-icon {
            @apply mr-3 text-grey-500;
        }

        .nav-link {
            @apply flex items-center text-white px-8 py-4;

            &:hover {
                @apply text-grey-400;
            }
        }

        .nav-link-inactive {
            @apply flex items-center text-grey-500 px-8 py-4;

            &:hover {
                @apply text-grey-600;
            }
        }

        &.nav-opened {
            .nav-link, .nav-link-inactive {
                @apply bg-grey-750;
            }
        }

        &.nav-active {
            .nav-link, .nav-link-inactive {
                @apply text-blue-500 font-bold;
            }

            .nav-icon {
                @apply text-blue-500;
            }
        }
    }
</style>
