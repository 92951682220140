<template>
    <activix-modal
        size="sm"
        :opened="opened"
        :portal="portal"
        :closable="false"
        @close="close"
        @opened="onOpened"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ title }}
            </h4>
        </template>

        <template slot="body">
            <p class="text-center">
                {{ question }}
            </p>

            <textarea
                class="form-control"
                rows="5"
                ref="textarea"
                v-model="message"
            />
        </template>

        <template slot="footer">
            <activix-button :disabled="$wait.is('sending.feedback')" @click="close">
                {{ $t('general.cancel') }}
            </activix-button>
            <activix-button
                type="primary"
                :loading="$wait.is('sending.feedback')"
                :disabled="!isValid"
                @click="approve"
            >
                {{ $t('general.submit') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import axios from 'axios';
    import { mapState } from 'pinia';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        props: {
            additionalData: {
                type: Object,
                default: () => ({}),
            },
            opened: {
                type: Boolean,
                required: true,
            },
            portal: {
                type: String,
                default: 'modal-1',
            },
            sentiment: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                message: '',
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),

            isValid() {
                return this.message.trim().length > 5;
            },

            title() {
                if (this.sentiment === 'neutral') {
                    return this.$t('feedback.modal.suggestions');
                }

                return this.$t('feedback.modal.comments');
            },

            question() {
                switch (this.sentiment) {
                    case 'negative':
                        return this.$t('feedback.modal.negativeQuestion');

                    case 'positive':
                        return this.$t('feedback.modal.positiveQuestion');

                    default:
                        return this.$t('feedback.modal.neutralQuestion');
                }
            },
        },

        methods: {
            getUserRoute() {
                const route = this.$router.resolve({
                    name: 'users.update',
                    params: { id: this.authUser.id },
                });

                return `${window.location.origin}${route.href}`;
            },

            async approve() {
                if (!this.isValid) {
                    return;
                }

                this.$wait.start('sending.feedback');

                await this.sendFeedback();

                this.$wait.end('sending.feedback');

                this.$emit('approve');
                this.close();
            },

            async sendFeedback() {
                const doorbellAppId = process.env.VUE_APP_DOORBELL_ID;
                const doorbellKey = process.env.VUE_APP_DOORBELL_KEY;
                const doorbellUrl = `https://doorbell.io/api/applications/${doorbellAppId}/submit?key=${doorbellKey}`;

                const messageParts = [
                    this.message,
                    `Utilisateur: ${this.getUserRoute()}`,
                ];

                Object.entries(this.additionalData)
                    .forEach(([key, value]) => messageParts.push(`${key}: ${value}`));

                return axios.post(doorbellUrl, {
                    email: this.authUser.email,
                    name: this.authUser.fullName,
                    sentiment: this.sentiment,
                    message: messageParts.join('\n\n\t\t'),
                });
            },

            onOpened() {
                if (this.$refs.textarea) {
                    this.$refs.textarea.focus();
                }
            },

            onClosed() {
                this.$emit('closed');
                this.message = '';
            },

            close() {
                this.$emit('update:opened', false);
            },
        },
    };
</script>
