<template>
    <div @click="editField" v-if="phoneLayout">
        <div class="text-grey-600 flex items-center" :class="{ 'text-blue-500': hasSelection }" v-if="icon">
            <icon :class="[iconSize]" :name="icon" v-if="icon" />
            <span class="ml-1 text-lg" ref="selectionText" v-if="hasSelection">{{ selectedLabels.length }}</span>
        </div>

        <div
            class="link-grey-dark flex items-center"
            :class="{ disabled: disabled, 'form-control': !externalTrigger }"
            v-else
        >
            <span class="flex-1 truncate text-left" ref="selectionText">{{ selectionText }}</span>
            <icon class="text-grey-700" name="regular/arrow-down-1" v-if="!externalTrigger" />
        </div>

        <select-picker-modal
            :allow-empty="allowEmpty"
            :auto-apply="autoApply"
            :identifier="identifier"
            :label="label"
            :custom-label="customLabel"
            :multiple="multiple"
            :opened.sync="modalOpened"
            :options="currentOptions"
            @cancel="$emit('cancel')"
            @input="triggerUpdate"
            v-model="currentSelection"
        />
    </div>

    <el-select-custom
        :id="computedId"
        class="flex"
        :automatic-dropdown="automaticDropdown"
        :autosize="autosize"
        :class="{ highlighted: enableHighlight, outline: outline, white: white, transparent: transparent }"
        :clearable="clearable && allowEmpty"
        :collapse-tags="needToCollapse"
        :default-first-option="searchable"
        :disabled="disabled"
        :display-limit-text="limitText"
        :filterable="searchable"
        :loading="loading"
        :multiple-limit="limit"
        :limit-selected-visible="limitSelectedVisible"
        :multiple="multiple"
        :name="name"
        :no-data-text="$t('multiselect.noResult')"
        :no-match-text="$t('multiselect.noResult')"
        :placeholder="defaultText"
        :size="size"
        :tabindex="disabled ? '-1' : '0'"
        :taggable="taggable"
        ref="select"
        @mouseenter.native="render = true"
        v-on="inputListeners"
        v-model="currentSelection"
        v-else
    >
        <template #prefix v-if="$slots.prefix">
            <slot name="prefix" />
        </template>

        <template v-if="render || automaticRender">
            <el-option
                :class="{ 'opacity-50': isDisabled(item), 'el-dropdown-menu__item--divided': item.divided }"
                :disabled="isDisabled(item)"
                :label="getOptionLabel(item)"
                :value="item[key]"
                :key="item[key]"
                v-for="item in currentOptions"
            >
                <div class="flex items-center">
                    <activix-checkbox
                        size="xs"
                        :value="isChecked(item)"
                        @click.native.prevent
                        v-if="multiple"
                    >
                        {{ getOptionLabel(item) }}
                    </activix-checkbox>

                    <div class="flex items-center w-4" :class="multiple ? 'ml-2' : 'mr-2'" v-if="item.icon">
                        <div class="flex items-center" v-html="item.icon" v-if="item.icon.includes('svg')" />
                        <icon :class="item.iconStyle" :name="item.icon" v-else-if="item.icon.includes('/')" />
                        <i :class="item.icon" v-else />
                    </div>

                    <div :class="{ 'font-bold': item.bold }" v-if="!multiple">
                        {{ getOptionLabel(item) }}
                    </div>

                    <span class="w-4 ml-2" v-if="item.suffixIcon">
                        <span v-html="item.suffixIcon" v-if="item.suffixIcon.includes('svg')" />
                    </span>
                </div>
            </el-option>
        </template>
        <template v-else>
            <el-option
                :label="getOptionLabel(item)"
                :value="item[key]"
                :key="item[key]"
                v-for="item in selectedOptions"
            />
        </template>
    </el-select-custom>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { isEmpty, castArray, uniqueId } from 'lodash-es';
    import ElSelectCustom from './ElSelectCustom.vue';
    import i18n from '../../plugins/vue-i18n.js';
    import SelectPickerModal from '../lead/inputs/SelectPickerModal.vue';

    export default {
        name: 'ActivixMultiselect',

        components: {
            SelectPickerModal,
            ElSelectCustom,
        },

        model: {
            event: 'update',
        },

        props: {
            name: {
                type: String,
                default: '',
            },
            size: {
                type: String,
                default: '',
            },
            automaticFocus: {
                type: Boolean,
                default: false,
            },
            automaticDropdown: {
                type: Boolean,
                default: false,
            },
            automaticRender: {
                type: Boolean,
                default: false,
            },
            clearable: {
                type: Boolean,
                default: false,
            },
            limit: {
                type: Number,
                default: 99999,
            },
            limitText: {
                type: Function,
                default: count => i18n.t('selectpicker.xSelected', [count]),
            },
            limitSelectedVisible: {
                type: Number,
                required: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            minWidth: {
                type: String,
                default: '158',
            },
            options: {
                type: Array,
                required: true,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            selected: {
                default: null,
            },
            identifier: {
                type: String,
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
            searchable: {
                type: Boolean,
                default: true,
            },
            placeholder: {
                type: String,
                default: '',
            },
            allowEmpty: {
                type: Boolean,
                default: true,
            },
            resetAfter: {
                type: Boolean,
                default: false,
            },
            closeOnSelect: {
                type: Boolean,
                default: true,
            },
            customLabel: {
                type: Function,
                default(option, label) {
                    if (option) return option[label];
                    return label ? option[label] : option;
                },
            },
            taggable: {
                type: Boolean,
                default: false,
            },
            id: {
                default: null,
            },
            enableHighlight: {
                type: Boolean,
                default: false,
            },
            autosize: {
                type: Boolean,
                default: false,
            },
            outline: {
                type: Boolean,
                default: false,
            },
            white: {
                type: Boolean,
                default: false,
            },
            transparent: {
                type: Boolean,
                default: false,
            },
            selectAll: {
                type: Boolean,
                default: true,
            },
            withNone: {
                type: Boolean,
                default: false,
            },
            noneSelected: {
                type: Boolean,
                default: false,
            },
            noneLabel: {
                type: String,
                default: '',
            },
            autoApply: {
                type: Boolean,
                default: false,
            },
            externalTrigger: {
                type: Boolean,
                default: false,
            },
            icon: {
                type: String,
                default: '',
            },
            iconSize: {
                type: String,
                default: 'text-base',
            },
        },

        data() {
            return {
                currentSelection: this.multiple ? [] : '',
                currentOptions: [],
                currentCheckState: {},
                modalOpened: false,
                transmissionType: 'string',
                selectionText: '',
                render: false,
            };
        },

        computed: {
            computedId() {
                return this.id || uniqueId('multiselect-');
            },

            inputListeners() {
                return {
                    ...this.$listeners,
                    ...{
                        change: this.triggerUpdate,
                    },
                };
            },

            showSelectAll() {
                return !this.taggable && this.selectAll && this.multiple && this.options.length > 1;
            },

            needToCollapse() {
                return false;
            },

            key() {
                if (this.identifier && !empty(this.identifier)) {
                    return this.identifier;
                }

                return 'id';
            },

            displayLabel() {
                if (this.label && !empty(this.label)) {
                    return this.label;
                }

                return 'label';
            },

            defaultText() {
                if (!this.placeholder) {
                    return this.$t('multiselect.selectOption');
                }

                return this.placeholder;
            },

            selectedOptions() {
                const currentSelectionArray = castArray(this.currentSelection);
                return this.currentOptions.filter(option => currentSelectionArray.includes(option[this.key]));
            },

            selectedLabels() {
                return this.selectedOptions.map(option => this.getOptionLabel(option));
            },

            hasSelection() {
                return !!this.selectedLabels.length;
            },
        },

        watch: {
            selectedLabels: {
                immediate: true,
                handler() {
                    this.setSelectionText();
                },
            },

            selected() {
                this.formatOptions();
            },

            options() {
                this.formatOptions();
            },

            automaticDropdown() {
                if (this.automaticDropdown) {
                    this.modalOpened = true;
                }
            },

            mdLayout() {
                if (this.mdLayout) {
                    this.setSelectionText();
                }
            },
        },

        methods: {
            async setSelectionText() {
                await this.$nextTick();

                if (!this.$refs.selectionText) {
                    return;
                }

                if (!this.selectedLabels.length) {
                    this.selectionText = this.defaultText;
                    return;
                }

                this.selectionText = this.selectedLabels.join(', ');

                if (
                    this.selectedLabels.length > (this.limitSelectedVisible || 1) ||
                    this.$refs.selectionText.offsetWidth < this.$refs.selectionText.scrollWidth
                ) {
                    this.selectionText = this.$t('selectpicker.xSelected', [this.selectedLabels.length]);
                }
            },

            assignCheckState(key, value) {
                this.currentCheckState[key] = value;
            },

            formatOptions() {
                this.clear();

                (this.options || []).forEach((element, index) => {
                    if (typeof element === 'string' || typeof element === 'number') {
                        this.transmissionType = 'string';

                        const option = {};
                        option[this.key] = index;
                        option[this.displayLabel] = element;

                        this.currentOptions.push(option);
                        this.assignCheckState(option[this.key], false);
                    } else if (typeof element === 'object') {
                        this.transmissionType = 'object';

                        const option = element;

                        if (!option.hasOwnProperty(this.key)) {
                            option[this.key] = index;
                        }

                        this.currentOptions.push(option);
                        this.assignCheckState(option[this.key], false);
                    }
                });

                if (this.withNone) {
                    this.currentOptions.unshift({
                        [this.key]: 'none',
                        [this.displayLabel]: this.noneLabel || this.$t('general.none'),
                    });

                    this.assignCheckState('none', false);
                }

                if (this.showSelectAll) {
                    if (this.currentOptions.length) {
                        this.currentOptions[0].divided = true;
                    }

                    this.currentOptions.unshift({
                        [this.key]: 'all',
                        [this.displayLabel]: this.$t('selectpicker.all'),
                    });

                    this.assignCheckState('all', false);
                }

                this.affectSelection();
            },

            affectSelection() {
                if (this.multiple) {
                    if (this.selected instanceof Array) {
                        const selections = this.selected.map((element, index) => {
                            if (!element.hasOwnProperty(this.key)) {
                                this.assignCheckState(index, true);
                                return index;
                            }

                            this.assignCheckState(element[this.key], true);
                            return element[this.key];
                        });

                        const everyOptionsSelected = !this.options.some(o => !selections.includes(o[this.key]));

                        if (everyOptionsSelected && this.showSelectAll) {
                            selections.push('all');
                            this.assignCheckState('all', true);
                        }

                        if (this.noneSelected) {
                            selections.push('none');
                            this.assignCheckState('none', true);
                        }

                        this.currentSelection = selections;
                    }
                } else {
                    const selection = this.currentOptions.find(element => {
                        if (this.selected === null && empty(this.selected)) {
                            return false;
                        }
                        if (this.transmissionType === 'string') {
                            return element[this.displayLabel] == this.selected;
                        }

                        return element[this.key] === this.selected[this.key];
                    });

                    if (selection && !empty(selection)) {
                        this.currentSelection = selection[this.key];
                    }

                    if (this.noneSelected) {
                        this.currentSelection = 'none';
                    }
                }
            },

            triggerUpdate(key) {
                if (this.multiple) {
                    if (isEmpty(this.currentSelection) && ['number', 'string'].includes(typeof this.currentSelection)) {
                        this.$emit('update', [], this.id);
                        return;
                    }

                    if (this.currentSelection.some(f => f == 'all') && !this.currentCheckState.all) {
                        const options = this.currentOptions.filter(option => option[this.key] != 'all');

                        this.$emit('update', options, this.id);
                    } else if (
                        !this.currentSelection.some(f => f == 'all') &&
                        this.currentCheckState.all &&
                        !this.phoneLayout
                    ) {
                        this.$emit('update', [], this.id);
                    } else {
                        const items = this.currentOptions.filter(element => {
                            if (element[this.key] == 'all') {
                                return false;
                            }

                            if (this.currentSelection.includes(element[this.key])) {
                                this.assignCheckState(element[this.key], true);
                                return true;
                            }

                            this.assignCheckState(element[this.key], false);

                            return false;
                        });

                        this.$emit('update', items, this.id);
                    }
                } else {
                    const item = this.currentOptions.find(element => element[this.key] === key);
                    let output;

                    switch (this.transmissionType) {
                        case 'string':
                            output = item ? item[this.displayLabel] : null;
                            break;
                        default:
                            output = item;
                            break;
                    }

                    this.$emit('update', output || null, this.id);
                }

                if (this.resetAfter) {
                    this.clear();
                }
            },

            getOptionLabel(option) {
                if (typeof option === 'object' && option !== null) {
                    return this.customLabel(option, this.displayLabel) || option[this.displayLabel] || '';
                }

                return option ? this.customLabel(option) : '';
            },

            isChecked(item) {
                return this.currentCheckState[item[this.key]];
            },

            isDisabled(item) {
                return (
                    item.disabled ||
                    (this.multiple && !this.allowEmpty && this.isChecked(item) && this.selected.length <= 1)
                );
            },

            clear() {
                this.currentSelection = this.multiple ? [] : '';
                this.currentOptions = [];
                this.currentCheckState = {};
            },

            editField() {
                if (this.disabled) {
                    return;
                }

                this.modalOpened = true;
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.formatOptions();

                this.$nextTick(() => {
                    if (!this.automaticFocus) {
                        return;
                    }

                    if (this.mdLayout) {
                        this.modalOpened = true;
                    } else {
                        this.$refs.select.focus();
                    }
                });
            });
        },
    };
</script>
