/* eslint-disable import/first */
global.process = process; // DO NOT DELETE...REQUIRED FOR STUPID WORKERS.

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import * as Sentry from '@sentry/vue';
import fr from '@/locales/fr/index.js';
import axios from 'axios';

Vue.use(VueI18n);

let locale = 'fr';

if (typeof localStorage !== 'undefined') {
    locale = localStorage.getItem('currentLocale');

    if (!locale || !['fr', 'en'].includes(locale)) {
        locale = navigator.language.includes('fr') ? 'fr' : 'en';
    }

    localStorage.setItem('currentLocale', locale);
}

const numberFormats = {
    USD: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'narrowSymbol',
    },
    CAD: {
        style: 'currency',
        currency: 'CAD',
        currencyDisplay: 'narrowSymbol',
    },
    decimal: {
        style: 'decimal',
    },
    km: {
        style: 'unit',
        unit: 'kilometer',
    },
    mi: {
        style: 'unit',
        unit: 'mile',
    },
    hr: {
        style: 'unit',
        unit: 'hour',
    },
};

const i18n = new VueI18n({
    locale,
    fallbackLocale: 'fr',
    messages: {
        fr,
    },
    numberFormats: {
        fr: numberFormats,
        en: numberFormats,
    },
    missing: (lang, key) => {
        const message = `Missing translation: ${key} (${lang})`;

        console.warn(message); // eslint-disable-line no-console

        Sentry.captureMessage(message, 'info');
    },
});

let loadedLanguages = ['fr'];

function setI18nLanguage (lang) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    if(typeof document !== 'undefined'){
        document.querySelector('html').setAttribute('lang', lang);
    }
    return lang
}

export function loadLanguageAsync(lang) {
    // If the same language
    if (i18n.locale === lang && loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    // If the language hasn't been loaded yet
    return import(`@/locales/${lang}/index.js`).then(
        messages => {
            i18n.setLocaleMessage(lang, messages.default)
            loadedLanguages.push(lang)
            return setI18nLanguage(lang)
        }
    )
}


// Hot Reloading
if (module.hot) {
    module.hot.accept(['../locales/fr', '../locales/en'], () => {
        loadedLanguages = [i18n.locale];
        i18n.setLocaleMessage(i18n.locale, loadLanguageAsync(i18n.locale));
    });
}

export default i18n;
