import axios from '../../../plugins/axios.js';
import { sort } from '../../../utils/index.js';
import { useGlobalStore } from '../../store.js';

export default {
    async setVehicles() {
        if (this.vehicles.length) {
            return;
        }

        try {
            // @TODO Only fetch power sports
            const response = await axios.get('v1/vehicles', {
                params: {
                    powerSportsOnly: true,
                },
            });

            const vehicles = Object.keys(response.data.data).map(make => {
                // We only need power sports
                const models = response.data.data[make].filter(model => {
                    return model.type !== 'vehicle';
                });

                return {
                    make,
                    models: sort(models, 'model'),
                };
            });

            this.vehicles = Object.freeze(sort(vehicles, 'make'));
        } catch (error) {
            useGlobalStore().appendNewError({
                code: '0039',
                display: true,
                error,
            });

            throw error;
        }
    },
    async setVehicleMakes() {
        if (this.vehicleMakes.length) {
            return;
        }

        const vehicleMakes = await useGlobalStore().fetchVehicleMakes();

        this.vehicleMakes = Object.freeze(vehicleMakes);
    },
};
