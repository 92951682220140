<template>
    <div
        class="sub-nav-item"
        :class="{ 'sub-nav-active': active }"
    >
        <router-link :to="to" class="sub-nav-link">
            <icon class="sub-nav-icon" :name="icon" v-if="icon" />
            <span v-text="title" />
        </router-link>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useDashboardStore } from '../../store/modules/dashboard/store.js';

    export default {
        props: {
            to: {
                type: [Object, String],
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            icon: {
                type: String,
                default: '',
            },
            active: {
                type: Boolean,
                required: true,
            },
        },
        computed: {
            ...mapState(useDashboardStore, ['viewId']),
        },
    };
</script>

<style lang="less">
    .sub-nav-item {
        @apply relative;

        .sub-nav-icon {
            @apply mr-3 text-grey-500;
        }

        .sub-nav-link {
            @apply flex items-center text-white pl-16 pr-8 py-4 bg-grey-750;

            &:hover {
                @apply text-grey-400;
            }
        }

        &.sub-nav-active {
            .sub-nav-link {
                @apply font-bold text-blue-500;
            }

            .sub-nav-icon {
                @apply text-blue-500;
            }
        }
    }
</style>
