<template>
    <div
        class="fixed h-18 w-full flex items-center justify-center z-50 text-lg shadow-md | md:h-12"
        :style="{ top: `${bodyMarginTop + existingPadding}px` }"
        :class="bannerClass"
    >
        <div class="w-full max-w-screen-xl">
            <div class="flex flex-col justify-center px-14 | md:flex-row">
                <span class="flex justify-center items-center font-medium text-white overflow-hidden">
                    <slot />
                </span>
                <span class="text-center | md:ml-1 md:text-left">
                    <slot name="link" />
                </span>
            </div>

            <div class="absolute inset-y-0 right-0 pr-3 flex items-start flex items-center">
                <button
                    type="button"
                    class="-mr-1 flex p-2 rounded-md | transition ease-in-out duration-150"
                    :class="closeButtonClass"
                    @click="$emit('close')"
                >
                    <icon class="text-xl text-white" name="regular/remove" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useLayoutStore } from '@/store/modules/layout/store.js';
    import { randomString } from '@/utils/index.js';

    let instances = [];

    export default {
        name: 'ActivixBanner',

        props: {
            color: {
                type: String,
                default: 'gray',
            },
        },

        data: () => ({
            id: randomString(),
            existingPadding: 0,
        }),

        computed: {
            ...mapState(useLayoutStore, ['bodyMarginTop']),

            bannerClass() {
                switch (this.color) {
                    case 'red':
                        return 'bg-red-500';

                    case 'orange':
                        return 'bg-orange-500';

                    case 'gray':
                    default:
                        return 'bg-gradient-to-r from-gray-750 to-gray-700';
                }
            },

            closeButtonClass() {
                switch (this.color) {
                    case 'red':
                        return 'hover:bg-red-600 focus:bg-red-600';

                    case 'orange':
                        return 'hover:bg-orange-600 focus:bg-orange-600';

                    case 'gray':
                    default:
                        return 'hover:bg-gray-650 focus:bg-gray-650';
                }
            },
        },

        methods: {
            setPaddingOnBody() {
                const paddingTop = instances.reduce((value, instance) => {
                    return value + instance.height;
                }, 0);

                document.body.style.paddingTop = `${paddingTop}px`;
            },
        },

        mounted() {
            this.existingPadding = parseInt(document.body.style.paddingTop || 0, 10);

            instances.push({
                id: this.id,
                height: this.$el.offsetHeight,
            });

            this.setPaddingOnBody();
        },

        beforeDestroy() {
            instances = instances.filter(instance => {
                return instance.id !== this.id;
            });

            this.setPaddingOnBody();
        },
    };
</script>
