import Vue from 'vue';
import ActivixUi, { ActivixPills, ActivixTabs } from '@activix/ui';
import '@activix/ui/dist/activix-ui.css';

import ActivixAccordion from '../components/elements/ActivixAccordion.vue';
import ActivixAccordionItem from '../components/elements/ActivixAccordionItem.vue';
import ActivixAlert from '../components/elements/ActivixAlert.vue';
import ActivixAvatar from '../components/elements/ActivixAvatar.vue';
import ActivixBanner from '../components/elements/ActivixBanner.vue';
import ActivixBaseButton from '../components/elements/ActivixBaseButton.vue';
import ActivixButton from '../components/elements/ActivixButton.vue';
import ActivixCalendar from '../components/elements/ActivixCalendar.vue';
import ActivixCheckbox from '../components/elements/ActivixCheckbox.vue';
import ActivixConfirmModal from '../components/elements/ActivixConfirmModal.vue';
import ActivixHelperNotification from '../components/elements/ActivixHelperNotification.vue';
import ActivixIcon from '../components/elements/ActivixIcon.vue';
import ActivixIllustrations from '../components/elements/ActivixIllustrations.vue';
import ActivixInput from '../components/elements/ActivixInput.vue';
import ActivixLink from '../components/elements/ActivixLink.vue';
import ActivixMaskedInput from '../components/elements/ActivixMaskedInput.vue';
import ActivixMultiSelect from '../components/elements/ActivixMultiselect.vue';
import ActivixRichRadio from '../components/elements/ActivixRichRadio.vue';
import ActivixRadio from '../components/elements/ActivixRadio.vue';
import ActivixReload from '../components/elements/ActivixReload.vue';
import ActivixRoundButton from '../components/elements/ActivixRoundButton.vue';
import ActivixSelect from '../components/elements/ActivixSelect.vue';
import ActivixSpinner from '../components/elements/ActivixSpinner.vue';
import ActivixSummernote from '../components/elements/ActivixSummernote.vue';
import ActivixSwitcher from '../components/elements/ActivixSwitcher.vue';
import ActivixTabsMobile from '../components/elements/ActivixTabsMobile.vue';
import ActivixTimePicker from '../components/elements/ActivixTimePicker.vue';
import ActivixTooltip from '../components/elements/ActivixTooltip.vue';
import ActivixUpload from '../components/elements/ActivixUpload.vue';

// Legacy Components
Vue.component(ActivixAccordion.name, ActivixAccordion);
Vue.component(ActivixAccordionItem.name, ActivixAccordionItem);
Vue.component(ActivixAlert.name, ActivixAlert);
Vue.component(ActivixAvatar.name, ActivixAvatar);
Vue.component(ActivixBanner.name, ActivixBanner);
Vue.component(ActivixBaseButton.name, ActivixBaseButton);
Vue.component(ActivixButton.name, ActivixButton);
Vue.component(ActivixCalendar.name, ActivixCalendar);
Vue.component(ActivixCheckbox.name, ActivixCheckbox);
Vue.component(ActivixConfirmModal.name, ActivixConfirmModal);
Vue.component(ActivixHelperNotification.name, ActivixHelperNotification);
Vue.component(ActivixIcon.name, ActivixIcon);
Vue.component(ActivixIllustrations.name, ActivixIllustrations);
Vue.component(ActivixInput.name, ActivixInput);
Vue.component(ActivixLink.name, ActivixLink);
Vue.component(ActivixMaskedInput.name, ActivixMaskedInput);
Vue.component(ActivixMultiSelect.name, ActivixMultiSelect);
Vue.component(ActivixRichRadio.name, ActivixRichRadio);
Vue.component(ActivixRadio.name, ActivixRadio);
Vue.component(ActivixReload.name, ActivixReload);
Vue.component(ActivixRoundButton.name, ActivixRoundButton);
Vue.component(ActivixSelect.name, ActivixSelect);
Vue.component(ActivixSpinner.name, ActivixSpinner);
Vue.component(ActivixSummernote.name, ActivixSummernote);
Vue.component(ActivixSwitcher.name, ActivixSwitcher);
Vue.component(ActivixTabsMobile.name, ActivixTabsMobile);
Vue.component(ActivixTimePicker.name, ActivixTimePicker);
Vue.component(ActivixTooltip.name, ActivixTooltip);
Vue.component(ActivixUpload.name, ActivixUpload);

// New Components
Vue.component(ActivixPills.name, ActivixPills);
Vue.component(ActivixTabs.name, ActivixTabs);

Vue.use(ActivixUi, { oldFontScale: true });
