// jQuery UI
import 'jquery-ui/ui/core.js';
import 'jquery-ui/ui/widgets/draggable.js';

// Boostrap (CSS is in bootstrap.less)
// @TODO Remove usage of Bootstrap JS
import '../node_modules/bootstrap/js/transition.js';
import '../node_modules/bootstrap/js/modal.js';
import '../node_modules/bootstrap/js/tooltip.js';
import '../node_modules/bootstrap/js/popover.js';
import '../node_modules/bootstrap/js/collapse.js';
import '../node_modules/bootstrap/js/tab.js'; // Required for summernote
import '../node_modules/bootstrap/js/dropdown.js'; // Required for summernote

// Emojione
import '../node_modules/emojione/assets/css/emojione.css';

// Ajaxq
import '../node_modules/ajaxq/ajaxq.js';

// Bootstrap DateRangePicker
import '../node_modules/@activix/bootstrap-daterangepicker/daterangepicker-bs3.css';
import '../node_modules/@activix/bootstrap-daterangepicker/daterangepicker.js';

// Bootstrap DateTimePicker
import '../node_modules/@activix/bootstrap-datetimepicker/css/bootstrap-datetimepicker.css';
import '../node_modules/@activix/bootstrap-datetimepicker/js/bootstrap-datetimepicker.js';
import '../node_modules/@activix/bootstrap-datetimepicker/js/locales/bootstrap-datetimepicker.fr.js';

// Summernote
import '../node_modules/summernote/dist/summernote.css';
import '../node_modules/summernote/dist/summernote.js';
import '../node_modules/summernote/dist/lang/summernote-fr-FR.js';
import '../node_modules/@activix/summernote-image-attributes/summernote-image-attributes.js';
import '../node_modules/@activix/summernote-image-attributes/lang/fr-FR.js';

// iFrameResizer
import '../node_modules/iframe-resizer/js/iframeResizer.js';

// Summernote fix to prevent douple line breaks
$.summernote.dom.emptyPara = '<div><br></div>';
