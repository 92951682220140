export default {
    account: {
        singular: 'space',
    },
    civility: {
        singular: 'titre',
    },
    presented: {
        singular: 'space visit',
    },
    province: {
        CA: 'province / État',
    },
    renewal: {
        singular: 'rétention',
        plural: 'rétentions',
        prefix: 'la rétention',
        abr: 'réten.',
    },
    renewed: {
        infinitive: 'retenir',
        singular: 'retenu',
        plural: 'retenus',
    },
    result: {
        singular: 'contacté',
    },
    sale: {
        singular: 'commande',
        plural: 'commandes',
        prefix: 'une commande',
    },
    sold: {
        singular: 'commandé',
        plural: 'commandés',
    },
    version: {
        singular: 'variante',
    },
    walkIn: {
        singular: 'space visit',
        plural: 'space visits',
        prefix: 'space visit',
        abr: 'space',
    },

};
