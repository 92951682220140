import Vue from 'vue';
import { find, forEach, get } from 'lodash-es';
import { gql } from 'apollo-boost';

import defaultLexiconFr from '@/locales/fr/lexicon/default.js';
import defaultLexiconEn from '@/locales/en/lexicon/default.js';
import polestarLexiconFr from '@/locales/fr/lexicon/polestar.js';
import polestarLexiconEn from '@/locales/en/lexicon/polestar.js';

// Plugins
import wait from '@/plugins/vue-wait';
import { apolloClient } from '@/plugins/vue-apollo';
import i18n from '@/plugins/vue-i18n';
import axios from '@/plugins/axios';

import { useGlobalStore } from '../../store.js';

// Utils
import { showLeadDeleted, showLeadNotFound } from '../../../utils/toastr.js';
import { isValidNumber } from '../../../utils/numbers.js';
import { merge } from '../../../utils/index.js';

// Entities
import Account from '../../../entities/Account.js';
import Customer from '../../../entities/Customer.js';
import Group from '../../../entities/Group.js';
import Lead from '../../../entities/Lead.js';
import User from '../../../entities/User.js';

const updateLocalStorage = newContext => {
    if (Vue.ls) {
        const context = Vue.ls.get('context', {});

        Vue.ls.set('context', { ...context, ...newContext });
    }
};

const fetchContextAccount = async accountId => {
    if (!accountId) {
        return null;
    }

    if (Vue.feature.isEnabled('graphql-context-account')) {
        return fetchContextAccountGraphQl(accountId);
    }

    return fetchContextAccountRest(accountId);
};

const fetchContextAccountGraphQl = async accountId => {
    const contextAccountQuery = apolloClient.query({
        query: gql`query account($id: Int) {
            account(id: $id) {
                ...accountDetails
                children {
                    ...accountDetails
                }

                parents {
                    ...accountDetails
                }
                lead_forms {
                    id
                    name
                    account_id
                }
                teams {
                    account_id
                    active
                    created_at
                    id
                    name
                    number
                    updated_at
                }
                flows {
                    id
                    account_id
                    communication_method_id
                    communication_type_id
                    division_id
                    lead_form_id
                    notify_precedent_priorities
                    created_at
                    updated_at
                }
                campaigns {
                    created_at
                    end_date
                    id
                    name
                    slug
                    start_date
                    type
                    updated_at
                }
            }
        }

        fragment accountDetails on Account {
            id
            created_at
            updated_at
            active
            name
            access_all_leads_communications
            account_manager
            activation_date
            activity_report
            address
            assigned_lead
            auto_assign_associate
            auto_recorded_date
            auto_renewal
            auto_renewal_new
            auto_renewal_used
            automation
            bdc_advisor
            billed
            billed_quarterly
            calendar_options
            city
            client_card_fields
            client_number
            commercial
            confirmation_appt_sale
            confirmation_appt_service
            country
            credit
            csi
            csi_used
            default_deliveryman_user_id
            delivered_by
            delivery_guest_advisor
            delivery_guest_group_id
            delivery_timeframe
            disable_communication_audio
            disable_manual_duplicate
            display_approved_sources
            duplicates
            email_client
            email_domain
            equity_alert
            event
            facebook_id
            favicon_url
            fiscal_year_date
            full_address
            guest_action
            in_turn
            in_turn_director_management
            leads_other_division
            leadxpress
            leadxpress_option
            limited_audio_access
            locale
            logo
            logo_en
            loyalty
            mandatory_coordinate
            manually_status
            marketing_domain_active
            merge_rule
            month_start_day
            niotext
            niotext_appointment
            niotext_campaign
            niotext_id
            niotext_phone
            phone
            phone_provider
            phone_up
            postal_code
            power_sport
            province
            recreative_special
            renewal
            renewal_alert_options
            restrict_to_activix
            restricted_notifications
            result_date_validation
            sale_accessories
            sale_by_phone
            sale_date_month
            sale_table
            sale_table_options
            sale_table_badge_active
            sale_table_badge_field
            sale_validation
            scan
            service
            sid
            stock_required_for_sale
            take_over_director
            timezone
            trade_report
            transactional_domain_active
            twilio_sid
            type
            unrestricted_assignment
            unsubscribe
            untreated_lead
            vehicle_model_text
            vehicle_text
            verified_sale
            video_conference
            vin_decoder
            vin_manual_decoder
            waiting_sale_date
            waiting_sale_option
            walk_in
            web_order
            webboost
            sources {
                ...sourceDetails
            }
            phone_providers {
                communication_type_id
                created_at
                division_id
                id
                incoming_caller_id
                ivr
                phone
                phone_provider
                phone_provider_id
                press_to_talk
                recording_message
                updated_at
                user_id
            }
            suppliers {
                account_supplier_id
                company
                contact_email
                contact_phone
                created_at
                display_name
                id
                inbound
                integration_name
                logo
                name
                outbound
                related_id
                updated_at
                url
                user_fullname
                user_id
                user_setting
            }
        }
        fragment sourceDetails on Source {
            id
            account_id
            lead_type_id
            scraper_id
            provider_id
            source_id
            approved_source_id
            display_on_listing
            name
            active
            provider {
                id
                name
                display_name_fr
                display_name_en
            }
            approved_source {
                id
                name
                provider_id
            }
        }
        `,
        variables: {
            id: parseInt(accountId, 10),
        },
    });

    const productsQuery = apolloClient.query({
        query: gql`query accountProducts($id: Int) {
            account(id: $id) {
                id
                products {
                    ...productDetails
                }
                children {
                    id
                    products {
                        ...productDetails
                    }
                }
                parents {
                    id
                    products {
                        ...productDetails
                    }
                }
            }
        }

        fragment productDetails on AccountProduct {
            id
            account_id
            category
            created_at
            default_minutes
            default_price
            name
            order
            type
            updated_at
            visible
        }
        `,
        variables: {
            id: parseInt(accountId, 10),
        },
    });

    const responseTemplateQuery = apolloClient.query({
        query: gql`query responseTemplates($id: Int) {
            account(id: $id) {
                id
                response_templates {
                    ...responseTemplateDetails
                }
                parents {
                    id
                    response_templates {
                        ...responseTemplateDetails
                    }
                }
            }
        }

        fragment responseTemplateDetails on ResponseTemplate {
            account_id
            bcc
            bcc_assigned
            cc
            cc_assigned
            created_at
            division_id
            id
            is_advanced
            is_favorite
            title
            translations {
                id
                response_template_id
                locale
                title
                subject
                body
                excerpt
                design
                attachments {
                    id
                    created_at
                    updated_at
                    response_template_translation_id
                    path
                    name
                    size
                }
            }
            attachments {
                id
                created_at
                updated_at
                response_template_translation_id
                path
                name
                size
            }
            type
            updated_at
        }
        `,
        variables: {
            id: parseInt(accountId, 10),
        },
    });

    const usersQuery = apolloClient.query({
        query: gql`query accountUsers($id: Int) {
            account(id: $id) {
                id
                users {
                    ...userDetails
                    children {
                        ...userDetails
                    }
                    parent {
                        ...userDetails
                    }
                    divisions {
                        ...divisionDetails
                    }
                    phones {
                        ...userPhoneDetails
                    }
                    post {
                        id
                        role_id
                        locale_specific
                        sex
                        name
                    }
                }
                children {
                    id
                    users {
                        ...userDetails
                    }
                }
                parents {
                    id
                    users {
                        ...userDetails
                    }
                }
            }
        }

        fragment userDetails on UserInterface {
            access_token
            account_id
            active
            allow_event_creation
            allow_export_access
            allow_import_access
            analyst_access
            api_user
            appointment_counter
            automation_access
            automation_advanced_access
            availability_status
            bdc_super_access
            bell_event_option
            block_lead_info
            calculator_value_visible
            confidentiality_agreement
            confirmation_timeframe
            created_at
            custom_permissions
            default_dashboard
            do_not_disturb
            email
            end_contract_required
            first_name
            freeze_financial_data
            hide_fi_profits
            hide_in_user_select
            id
            in_turn_edit
            last_mobile_heartbeat_at
            last_name
            locale
            mass_mailing
            module_access
            newer_than
            next_step
            next_step_mandatory
            niotext
            niotext_id
            no_reassignation_notification
            older_than
            parent_user_id
            post_id
            profile_picture
            reminder_preferences
            role_id
            sensitive_access
            service_super_access
            sex
            supplier_id
            suspended
            team_id
            timezone
            trade_report
            updated_at
        }

        fragment divisionDetails on Division {
            id
            name
        }

        fragment userPhoneDetails on UserPhone {
            id
            created_at
            updated_at
            user_id
            number
            extension
            is_mobile
            is_preferred
        }

        `,
        variables: {
            id: parseInt(accountId, 10),
        },
    });

    const customFieldsQuery = apolloClient.query({
        query: gql`query accountCustomField($id: Int) {
            account(id: $id) {
                id
                custom_fields {
                    ...customFieldDetails
                }
                parents {
                    id
                    custom_fields {
                        ...customFieldDetails
                    }
                }
            }
        }

        fragment customFieldDetails on CustomField {
            account_id
            created_at
            default
            deleted_at
            display
            field
            id
            name
            order
            section
            select_picker_options
            type
            updated_at
            visible
        }

        `,
        variables: {
            id: parseInt(accountId, 10),
        },
    });
    const guestGroupQuery = apolloClient.query({
        query: gql`query accountGuestGroup($id: Int) {
            account(id: $id) {
                id
                guest_groups {
                    ...guestGroupDetails
                }
                children {
                    id
                    guest_groups {
                        ...guestGroupDetails
                    }
                }
            }
        }

        fragment guestGroupDetails on AccountGuestGroup {
            id
            created_at
            updated_at
            account_id
            name
            roles
            users
            posts
        }

        `,
        variables: {
            id: parseInt(accountId, 10),
        },
    });

    const accountData = (await contextAccountQuery).data.account;

    const foreigns = {
        products: (await productsQuery),
        users: (await usersQuery),
        custom_fields: (await customFieldsQuery),
        guest_groups: (await guestGroupQuery),
        response_templates: (await responseTemplateQuery),
    };

    forEach(foreigns, (foreign, foreignName) => {
        const foreignData = foreign.data.account;
        accountData[foreignName] = foreignData[foreignName];

        if (foreignData.parents) {
            forEach(foreignData.parents, (parentAccount) => {
                find(accountData.parents, ({ id }) => {
                    return id === parentAccount.id;
                })[foreignName] = parentAccount[foreignName];
            });
        }

        if (foreignData.children) {
            forEach(foreignData.children, (childrenAccount) => {
                find(accountData.children, ({ id }) => id === childrenAccount.id)[foreignName] = childrenAccount[foreignName];
            });
        }
    });

    return accountData;
};

/** @deprecated */
const fetchContextAccountRest = async accountId => {
    const response = await axios.get(`v1/accounts/${accountId}`, {
        params: {
            include: [
                'campaigns',
                'children',
                'children.sources',
                'children.sources.provider',
                'children.sources.approvedSource',
                'children.phoneProviders',
                'children.users',
                'children.suppliers',
                'children.guestGroups',
                'customFields',
                'flows',
                'flows.activeUsers',
                'guestGroups',
                'leadForms',
                'parents',
                'parents.users',
                'parents.suppliers',
                'parents.customFields',
                'parents.responseTemplates',
                'parents.responseTemplates.account',
                'parents.responseTemplates.responseTemplateTranslations',
                'parents.responseTemplates.responseTemplateTranslations.responseTemplateAttachments',
                'phoneProviders',
                'products',
                'sources',
                'sources.provider',
                'sources.approvedSource',
                'responseTemplates.account',
                'responseTemplates.responseTemplateTranslations',
                'responseTemplates.responseTemplateTranslations.responseTemplateAttachments',
                'suppliers',
                'teams',
                'users',
                'users.children',
                'users.divisions',
                'users.parent',
                'users.userPhones',
                'users.post',
            ],
        },
    });

    return response.data.data;
};

const fetchContextCustomer = async customerId => {
    if (!customerId) {
        return null;
    }

    return Vue.api.customers.show(customerId, {
        include: [
            'account',
            'emails',
            'latestUser',
            'latestBdcUser',
            'latestCommercial',
            'phones',
        ],
    });
};

const fetchContextUser = async userId => {
    if (!userId) {
        return null;
    }

    const response = await axios.get(`v1/users/${userId}`, {
        params: {
            include: [
                'account',
                'account.campaigns',
                'account.children',
                'account.children.sources',
                'account.children.users',
                'account.children.users.role',
                'account.children.users.userPhones',
                'account.phoneProviders',
                'account.sources',
                'account.sources.provider',
                'account.suppliers',
                'account.teams',
                'account.users',
                'account.users.children',
                'account.users.divisions',
                'account.users.role',
                'account.users.userPhones',
                'children',
                'children.account',
                'divisions',
                'parent',
                'parent.account',
                'parent.children',
                'parent.children.account',
                'post',
            ],
        },
    });
    return response.data.data;
};

const fetchContextGroup = async groupId => {
    if (!groupId) {
        return null;
    }

    return Vue.api.groups.show(groupId, {
        include: ['accounts.sources:id,name'],
    });
};

const fetchContextLead = async (leadId) => {
    if (!leadId) {
        return null;
    }

    try {
        return await Vue.api.leads.show(leadId);
    } catch (error) {
        if (!error.response) {
            throw error;
        }

        // 5xx error
        if (error.response.status.toString().startsWith(5)) {
            dispatch('appendNewError', {
                code: '0047',
                display: false,
                error,
                payload: { id },
            });
        }

        if (error.response.status == 404) {
            const mergedId = get(error.response, 'data.errors.mergedId');
            const deletedBy = get(error.response, 'data.errors.deletedBy');
            const currentRoute = Vue.router.currentRoute;

            if (deletedBy) {
                showLeadDeleted(deletedBy);
                Vue.router.replace('/');

                return null;
            }

            if (!mergedId) {
                showLeadNotFound();
                Vue.router.replace('/');

                return null;
            }

            if (currentRoute.name == 'leads.update' && currentRoute.params.id == id) {
                Vue.router.replace({
                    name: 'leads.update',
                    params: {
                        id: error.response.data.errors.mergedId,
                    },
                });
            }
        }

        throw error;
    }
};

const fetchWebOrderBadgeCount = async accountId => {
    if (!accountId) {
        return null;
    }

    const response = await axios.get(`v1/accounts/${accountId}/web-order-badge-count`);

    return response.data.badgeCount;
};

export default {
    async reloadContextAccount() {
        const accountId = this.account.id;

        if (!accountId) {
            return;
        }

        const account = await fetchContextAccount(accountId);

        this.setContextAccount(account);

        useGlobalStore().fetchTemplateAttributes(accountId);
    },
    async setContextUserAction(userId) {
        const tmpAuthUser = useGlobalStore().authUser;

        if (!userId) {
            if (tmpAuthUser.hasSimpleAccess()) {
                await this.setContextUserAction(tmpAuthUser.id);
            } else {
                this.setContextUser(null);
                this.setContextUserId(null);
            }

            return;
        }

        if (userId == this.userId) {
            return;
        }

        wait.start('fetching.contextUser');

        this.setContextUserId(userId);

        try {
            const user = await fetchContextUser(userId);

            this.setContextUser(user);

            await this.setContextAccountAction(user.account_id);
            await this.setContextGroupAction();

            wait.end('fetching.contextUser');
        } catch (error) {
            wait.end('fetching.contextUser');

            if (tmpAuthUser.hasSimpleAccess()) {
                await this.setContextUserAction(tmpAuthUser.id);
            }
        }
    },
    async setContextGroupAction(groupId) {
        if (!groupId) {
            this.setContextGroup(null);
            this.setContextGroupId(null);
            return;
        }

        if (groupId == this.groupId) {
            return;
        }

        wait.start('fetching.contextGroup');

        this.setContextGroupId(groupId);

        try {
            const group = await fetchContextGroup(groupId);

            this.setContextGroup(group);

            if (!this.accountId) {
                await this.setContextAccountAction(useGlobalStore().authUser.account_id);
            }
            await this.setContextUserAction();

            wait.end('fetching.contextGroup');
        } catch (error) {
            wait.end('fetching.contextGroup');

            this.setContextGroup(null);
            this.setContextGroupId(null);
        }
    },
    async setContextAccountAction(accountId) {
        const tmpAuthUser = useGlobalStore().parentAuthUser;

        if (!isValidNumber(accountId)) {
            const forceAccountId = tmpAuthUser.isAdmin() ? Account.DEMO : tmpAuthUser.account_id;
            await this.setContextAccountAction(forceAccountId);

            return;
        }

        if (accountId == this.accountId) {
            return;
        }

        if (!tmpAuthUser.hasAccessToSeeAccount(accountId)) {
            await this.setContextAccountAction(tmpAuthUser.account_id);

            return;
        }

        wait.start('fetching.contextAccount');

        this.setContextAccountId(accountId);

        try {
            const account = await fetchContextAccount(accountId);

            this.setContextAccount(account);
            this.setContextTeamId(null);
            this.setContextLexicon();

            if (tmpAuthUser.hasAccessTo('dashboards.webOrder')) {
                const badgeCount = await fetchWebOrderBadgeCount(accountId);

                useGlobalStore().updateWebOrderBadgeCount(badgeCount);
            }

            useGlobalStore().fetchTemplateAttributes(accountId);

            wait.end('fetching.contextAccount');
        } catch (error) {
            wait.end('fetching.contextAccount');

            await this.setContextAccountAction(tmpAuthUser.account_id);
        }
    },
    setContextLexicon() {
        const replaceLexicon = (locale, messages) => {
            const localeMessages = i18n.getLocaleMessage(locale);
            localeMessages.lexicon = messages;

            i18n.setLocaleMessage(locale, localeMessages);
        };

        if (this.account.isPolestar()) {
            replaceLexicon('fr', polestarLexiconFr);
            replaceLexicon('en', polestarLexiconEn);
        } else {
            replaceLexicon('fr', defaultLexiconFr);
            replaceLexicon('en', defaultLexiconEn);
        }
    },
    async setContextLeadIdAction(leadId, force = false) {
        if (!leadId) {
            this.setContextLeadId(null);
            return;
        }

        if (leadId == this.leadId) {
            return;
        }

        const existingLead = useGlobalStore().leads.find(l => l.id == leadId);

        if (existingLead && !force) {
            this.setContextLeadId(existingLead.id);
            return;
        }

        wait.start('fetching.lead');

        try {
            const lead = new Lead(await fetchContextLead(leadId));

            if (Vue.router.currentRoute.name !== 'calendar') {
                await this.setContextAccountAction(lead.account_id);
            }

            useGlobalStore().addLead(lead);
            this.setContextLeadId(lead.id);
        } catch (error) {
            this.setContextLeadId(null);

            if (error.response) {
                const mergedId = error.response.data.errors ? error.response.data.errors.mergedId : null;

                if (error.response.status == 404 && mergedId) {
                    Vue.router.replace({
                        name: 'leads.update',
                        params: {
                            id: mergedId,
                        },
                    });
                } else if ([403, 404].includes(error.response.status)) {
                    Vue.router.replace('/');
                } else {
                    useGlobalStore().appendNewError({
                        code: '0105',
                        display: false,
                        error,
                        payload: { leadId },
                    });

                    Vue.router.replace('/');
                }
            }

            if (!this.accountId) {
                await this.setContextAccountAction();
            }
        }

        wait.end('fetching.lead');
    },
    async setContextCustomerAction(customerId, force = false) {
        if (!customerId) {
            this.setContextCustomerId(null);
            this.setContextCustomer(null);

            return;
        }

        if (customerId === this.customerId && !force) {
            return;
        }

        wait.start('fetching.contextCustomer');

        this.setContextCustomerId(customerId);

        try {
            const customer = await fetchContextCustomer(customerId);

            await this.setContextAccountAction(customer.account_id);

            this.setContextCustomer(customer);

            wait.end('fetching.contextCustomer');
        } catch (error) {
            this.setContextCustomer(null);
            this.setContextCustomerId(null);

            wait.end('fetching.contextCustomer');
        }
    },
    async reloadCustomer(customerId) {
        if (!customerId || this.customerId !== customerId) {
            return;
        }

        const vehicles = this.customer.vehicles || [];

        await this.setContextCustomerAction(customerId, true);

        this.setContextCustomerVehicles(vehicles);
    },
    async updateCustomer(customer) {
        useGlobalStore().updateLeadCustomer(customer);

        if (customer.id !== this.customer.id) {
            return;
        }

        const vehicles = this.customer.vehicles || [];

        merge(this.customer, customer);

        this.setContextCustomerVehicles(vehicles);
    },
    async updateCustomerPhone(customerPhone) {
        const currentCustomerPhone = this.customer.phones.find(phone => phone.id === customerPhone.id);

        if (currentCustomerPhone) {
            merge(currentCustomerPhone, customerPhone);
        } else {
            this.customer.phones.push(customerPhone);
        }
    },
    async updateCustomerEmail(customerEmail) {
        const currentCustomerEmail = this.customer.emails.find(email => email.id === customerEmail.id);

        if (currentCustomerEmail) {
            merge(currentCustomerEmail, customerEmail);
        } else {
            this.customer.emails.push(customerEmail);
        }
    },
    async deleteCustomerPhone(customerPhoneId, customerId) {
        if (this.customer.id !== customerId) {
            return;
        }

        const customerPhoneIndex = this.customer.phones.findIndex(phone => phone.id === customerPhoneId);

        if (customerPhoneIndex === -1) {
            return;
        }

        this.customer.phones.splice(customerPhoneIndex, 1);
    },
    async deleteCustomerEmail(customerEmailId, customerId) {
        if (this.customer.id !== customerId) {
            return;
        }
        const customerEmailIndex = this.customer.emails.findIndex(email => email.id === customerEmailId);

        if (customerEmailIndex === -1) {
            return;
        }

        this.customer.emails.splice(customerEmailIndex, 1);
    },
    async reloadCustomerVehicles(customerId) {
        if (!customerId || this.customerId !== customerId) {
            return;
        }

        const vehicles = await Vue.api.customers.vehicles(customerId, {
            include: [
                'customerVehicleLead.lead',
                'customerVehicleLead.leadVehicleSuppliers',
            ],
        });

        this.setContextCustomerVehicles(vehicles);
    },
    async reloadCustomerVehicle(customerVehicleId) {
        if (!this.customer.vehicles.some(v => v.id === customerVehicleId)) {
            return;
        }

        const vehicle = await Vue.api.customers.vehicle(this.customerId, customerVehicleId, {
            include: [
                'customerVehicleLead.lead',
                'customerVehicleLead.leadVehicleSuppliers',
            ],
        });

        this.replaceContextCustomerVehicle(vehicle);
    },
    setContextDivisionId(divisionId) {
        this.divisionId = parseInt(divisionId, 10) || null;
        updateLocalStorage({ divisionId: this.divisionId });
    },

    setContextLeadId(leadId) {
        this.leadId = parseInt(leadId, 10) || null;
        updateLocalStorage({ leadId: this.leadId });
    },

    setContextTeamId(teamId) {
        const teamExists = this.account.teams.some(team => team.id == teamId);

        if (!teamExists) {
            teamId = null;
        }

        this.teamId = parseInt(teamId, 10) || null;
        updateLocalStorage({ teamId: this.teamId });
    },

    setContextAccountId(accountId) {
        this.accountId = parseInt(accountId, 10) || null;
        updateLocalStorage({ accountId: this.accountId });
    },

    setContextCustomerId(customerId) {
        this.customerId = parseInt(customerId, 10) || null;
        updateLocalStorage({ customerId: this.customerId });
    },

    setContextGroupId(groupId) {
        this.groupId = parseInt(groupId, 10) || null;
        updateLocalStorage({ groupId: this.groupId });
    },

    setContextUserId(userId) {
        this.userId = parseInt(userId, 10) || null;
        updateLocalStorage({ userId: this.userId });
    },

    setContextAccount(account) {
        this.account = new Account(account);
    },

    setContextCustomer(customer) {
        this.customer = new Customer(customer);
    },

    setContextCustomerVehicles(vehicles) {
        this.customer.vehicles = vehicles;
    },

    replaceContextCustomerVehicle(vehicle) {
        const index = this.customer.vehicles.findIndex(v => v.id === vehicle.id);

        if (index === -1) {
            return;
        }

        Vue.set(this.customer.vehicles, index, vehicle);
    },

    setContextGroup(group) {
        this.group = new Group(group);
    },

    setContextUser(user) {
        this.user = new User(user);
    },
};
