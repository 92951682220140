/* eslint-disable camelcase */
import { formatPhone } from '@/utils';

import LeadPhoneType from '@/entities/LeadPhoneType';
import Model from '@/entities/Model.js';

export default class CustomerPhone extends Model {
    static get defaults() {
        return {};
    }

    get isValid() {
        return !!this.number && !!this.valid;
    }

    get isWorkType() {
        return this.type === LeadPhoneType.WORK;
    }

    format(withCountryCode = false) {
        return formatPhone(this.number, withCountryCode);
    }
}
