<template>
    <div>
        <div class="alert | text-center text-white bg-grey-600 border-grey-600" v-if="nextAutomationJob">
            <i18n path="responseLeadXpress.nextAutomationJob" tag="span">
                <a @click="previewModalOpened = true">{{ date }}</a>
            </i18n>
        </div>

        <automation-preview :lead="lead" :opened.sync="previewModalOpened" :job="nextAutomationJob" />
    </div>
</template>

<script>
    // Utils
    import { orderBy } from 'lodash-es';

    // Entities
    import AutomationActionType from '../../entities/AutomationActionType.js';

    // Value Objects
    import ActivixDate from '../../value-objects/ActivixDate.js';

    // Components
    import AutomationPreview from '../modals/AutomationPreview.vue';

    export default {
        components: {
            AutomationPreview,
        },

        props: {
            lead: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                previewModalOpened: false,
            };
        },

        computed: {
            nextAutomationJob() {
                const jobs = this.lead.automation_jobs.filter(job => {
                    return (
                        job.action.action == AutomationActionType.SEND_EMAIL && !job.execution_started_at && job.active
                    );
                });

                if (!jobs.length) {
                    return null;
                }

                return orderBy(jobs, ['execution_date'], ['asc'])[0];
            },

            date() {
                if (!this.nextAutomationJob) {
                    return '';
                }

                return new ActivixDate(this.nextAutomationJob.execution_date).toHumanShort();
            },
        },
    };
</script>
