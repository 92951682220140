import { defineStore } from 'pinia';
import state from './state.js';
import actions from './actions.js';

export const useLeadStore = defineStore({
    id: 'lead',
    state() {
        return state;
    },
    actions,
});
