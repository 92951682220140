export default {
    acura_canada: 'Acura Canada',
    activix: 'Activix CRM',
    api: 'API',
    auto_renewal: '@.capitalize:lexicon.renewal.singular automatique',
    bmw_canada: 'BMW Canada',
    cdk: 'CDK',
    cloning: 'Dupliquer',
    ct_wizard: 'CTWizard',
    dealer_vu: 'DealerVu',
    easydeal: 'EasyDeal',
    f_c_a_digital: 'FCA Digital',
    ford_direct: 'FordDirect',
    honda_canada: 'Honda Canada',
    kia_digital: 'Kia Digital',
    m_b_c_a_digital: 'MBCA Digital',
    manual: 'Manuel',
    manual_import: 'Import manuel',
    n_c_i_digital: 'NCI Digital',
    niotext: 'NioText',
    phone_system: 'Sys. Tél.',
    porsche_digital: 'Porsche Digital',
    porsche_finder: 'Porsche Finder',
    polaris_digital: 'Polaris Digital',
    harley_davidson_digital: 'Harley Davidson Digital',
    maserati_digital: 'Maserati Digital',
    scan: 'Scan Xpress',
    s_c_i_marketview: 'Taq Auto',
    taq_auto: 'Taq Auto',
    scraper: 'Intégrateur',
    serti: 'Serti',
    undefined: '-',
    messenger: 'Messenger',
    polestar: 'Polestar',
    mitsubishi: 'Mitsubishi',
    prebooking: 'Pré-booking automatique',
};
