<template>
    <div>
        <email-accordion-item
            :class="{ 'border-t': index !== 0 }"
            :communication="communication"
            :lead="lead"
            :parent="parent"
            :is-loading="loadingBody == communication.id && !communication.email_body"
            :key="communication.id"
            @load="loadEmailBody(communication)"
            v-for="(communication, index) in orderedConnectedCommunications"
        />
    </div>
</template>

<script>
    import { orderBy } from 'lodash-es';
    import EmailAccordionItem from '@/components/lead/EmailAccordionItem.vue';

    // Pinia
    import { mapActions, mapState } from 'pinia';
    import { useClientCardStore } from '../../store/modules/clientCard/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: { EmailAccordionItem },

        props: {
            lead: {
                type: Object,
                require: true,
            },
            parent: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                loadingBody: 0,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['guest']),
            ...mapState(useClientCardStore, ['currentCommunicationId']),

            connectedCommunications() {
                let communications = [];

                if (this.lead && this.lead.communications && this.lead.communications.length > 0) {
                    communications = this.lead.communications.filter(communication => {
                        return (
                            (communication.connected &&
                                communication.service_id == 4 &&
                                !['interrupted', 'error', 'invalid', 'pending'].includes(communication.status)) ||
                            communication.kind == 'auto'
                        );
                    });
                }

                return communications;
            },

            orderedConnectedCommunications() {
                const communications = this.connectedCommunications.map(communication => {
                    return {
                        ...communication,
                        sort_created_at: communication.original_created_at || communication.created_at,
                    };
                });

                return orderBy(communications, 'sort_created_at', 'desc');
            },

            communicationId() {
                if (!empty(this.currentCommunicationId)) {
                    return this.currentCommunicationId;
                }

                return null;
            },
        },

        watch: {
            communicationId(newValue) {
                if (!empty(newValue)) {
                    this.setDataAccordion();
                }
            },

            lead: {
                deep: true,
                handler(newValue) {
                    if (!empty(newValue)) {
                        this.setDataAccordion();
                    }
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['appendNewError']),

            loadEmailBody(communication) {
                let index = null;

                if (this.loadingBody != 0 || typeof communication.email_body !== 'undefined') return;

                for (let i = this.connectedCommunications.length - 1; i >= 0; i--) {
                    if (this.connectedCommunications[i].id == communication.id) {
                        index = i;
                    }
                }

                this.loadingBody = communication.id;

                this.$axios
                    .get(`v1/communications/${communication.id}`, {
                        params: {
                            from: 'clientCard',
                        },
                    })
                    .then(response => {
                        if (response.status == 200 && this.connectedCommunications[index]) {
                            this.connectedCommunications[index].email_body = response.data.data.email_body;
                            this.connectedCommunications[index].attachments = response.data.data.attachments;
                        }

                        this.loadingBody = 0;
                    })
                    .catch(error => {
                        if (!error.request) {
                            throw error;
                        }

                        if (error.response && error.response.status === 403) {
                            return;
                        }

                        this.appendNewError({
                            code: '0025',
                            display: true,
                            error,
                        });
                    });
            },

            setDataAccordion() {
                if (this.communicationId && !empty(this.connectedCommunications)) {
                    const communication = this.connectedCommunications.find(c => c.id == this.communicationId);
                    if (communication) {
                        this.loadEmailBody(communication);
                        $(`#collapse-${this.parent}-${communication.id}`).addClass('current-collapse');
                        $(`#collapse-${this.parent}-${communication.id}`).collapse('show');
                    }
                }
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.setDataAccordion();

                if (this.$route && this.$route.query.communicationId) {
                    const communicationId = this.$route.query.communicationId;
                    const communication = this.connectedCommunications.find(
                        communication => communication.id == communicationId,
                    );

                    useClientCardStore().currentCommunicationId = communicationId;

                    if (communication) {
                        this.loadEmailBody(communication);
                    }
                }
            });
        },
    };
</script>
