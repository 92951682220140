import { camelCase } from 'lodash-es';
import i18n from '../plugins/vue-i18n.js';

export default class Enum {
    constructor() {
        throw new Error("Enum class, don't instanciate");
    }

    static get keys() {
        return Object.keys(this.entries);
    }

    static get values() {
        return Object.values(this.entries);
    }

    static get list() {
        return Object.entries(this.entries).map(([a, b]) => ({ id: b, name: a }));
    }

    static exists(property) {
        if (typeof this.entries === 'undefined' || typeof property === 'undefined') {
            return false;
        }

        return this.entries.hasOwnProperty(property);
    }

    static getKey(value) {
        return this.keys.find(key => this.entries[key] == value);
    }

    static getTranslation(value) {
        const key = this.getKey(value) || '';
        const camelCaseKey = camelCase(key);

        if (!key || !this.translationGroup) {
            return key;
        }

        const translationKey = `${this.translationGroup}.${key}`;
        const translationCamelCaseKey = `${this.translationGroup}.${camelCaseKey}`;

        return i18n.te(translationCamelCaseKey) ? i18n.tc(translationCamelCaseKey, 1) : i18n.t(translationKey);
    }

    static selectOptions(idName = '', labelName = '', sorted = true) {
        const options = this.values
            .map(value => {
                return {
                    [idName || 'id']: value,
                    [labelName || 'label']: this.getTranslation(value),
                };
            });

        if (sorted) {
            options.sort((a, b) => a[labelName || 'label'].localeCompare(b[labelName || 'label']));
        }

        return options;
    }

    static uniqueOptions(idName = '', labelName = '', sorted = false, selected = '') {
        const options = Object.keys(this.entries).map(item => {
            return {
                [idName || 'id']: item,
                [labelName || 'label']: this.getTranslation(item),
            };
        }).filter((option, index, items) => {
            if (items.filter(item => item[labelName || 'label'] === option[labelName || 'label']).length > 1 &&
                items.filter(item => item[labelName || 'label'] === option[labelName || 'label']).find(item => item[idName || 'id'] === selected)) {
                return option[idName || 'id'] === selected;
            }

            return items.findIndex(item => item[labelName || 'label'] === option[labelName || 'label']) === index;
        });

        if (sorted) {
            options.sort((a, b) => a[labelName || 'label'].localeCompare(b[labelName || 'label']));
        }

        return options;
    }

    static getSelectOption(option, idName = '', labelName = '') {
        for (const id of Object.values(this.entries)) {
            if (id == option) {
                return {
                    [idName || 'id']: id,
                    [labelName || 'label']: this.getTranslation(id),
                };
            }
        }

        return null;
    }
}
