<!-- eslint-disable vue/valid-v-bind-sync -->
<template>
    <div class="flex flex-col">
        <div class="w-full mb-2" v-if="!child">
            <div class="flex w-full mb-4">
                <img :src="authUser.profile_picture" class="rounded-full w-12 h-12 shadow-inner" v-if="!mdLayout" />

                <comment-form class="md:ml-2" :active.sync="active" :lead-id="leadId" />
            </div>
            <div class="flex justify-end w-full mb-1">
                <button
                    class="cursor-pointer"
                    :class="{ 'text-blue-500 font-bold': isNoteFilter }"
                    @click="updateFilter(CommentType.STRING)"
                >
                    {{ $t('clientCard.comment') }}
                </button>
                <button
                    class="ml-2 cursor-pointer"
                    :class="{ 'text-blue-500 font-bold': isAttachmentFilter }"
                    @click="updateFilter(CommentType.ATTACHMENT)"
                >
                    {{ $t('clientCard.files') }}
                </button>
                <button
                    class="ml-2 cursor-pointer"
                    :class="{ 'text-blue-500 font-bold': isCaseFilter }"
                    @click="updateFilter(CommentType.CASE)"
                    v-if="caseEnabled"
                >
                    {{ $t('clientCard.cases') }}
                </button>
                <button
                    class="ml-2 cursor-pointer"
                    :class="{ 'text-blue-500 font-bold': isAudioFilter }"
                    @click="updateFilter( CommentType.AUDIO)"
                >
                    {{ $t('clientCard.audio') }}
                </button>
                <button
                    class=" ml-2 cursor-pointer"
                    :class="{ 'text-blue-500 font-bold': commentsFilter === null }"
                    @click="updateFilter(CommentType.ALL)"
                >
                    {{ $t('clientCard.all') }}
                </button>
            </div>
        </div>
        <div class="flex-1" :class="{ 'xs:overflow-auto': !loading && !child }">
            <div class="flex items-center justify-center h-full w-full" v-if="loading">
                <activix-spinner :size="40" />
            </div>

            <template v-else>
                <div class="w-full text-center" v-if="empty(comments) && !child">
                    {{ isAttachmentFilter ? $t('clientCard.noAttachments') : $t('clientCard.noComment') }}
                </div>
                <div
                    class="pt-2 mb-2"
                    :class="{ 'border-0 border-grey-300 border-t': child || index !== 0 }"
                    :key="index"
                    v-for="(comment, index) in comments"
                >
                    <div class="flex items-start">
                        <img
                            class="rounded-full mt-1 shadow-inner"
                            :class="{ 'w-12 h-12': !child, 'w-10 h-10': child }"
                            :src="getProfilePicture(comment)"
                        />
                        <sub-comment
                            :comment.sync="comment"
                            :depth="depth + 1"
                            :comments-filter="commentsFilter"
                            :lead-id="leadId"
                        />
                    </div>

                    <div
                        class="my-2 border-0 pt-2"
                        :class="{ 'pl-12': depth < maxDepth }"
                        v-if="!empty(comment.children)"
                    >
                        <activix-comment
                            :comments="comment.children"
                            :child="true"
                            :depth="depth + 1"
                            :comments-filter="commentsFilter"
                            :lead-id="leadId"
                        />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    // Utils
    import { get } from 'lodash-es';
    // Components
    import { mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store.js';
    import CommentType from '../../entities/CommentType.js';
    import SubComment from './SubComment.vue';
    import CommentForm from './CommentForm.vue';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'ActivixComment',

        components: {
            SubComment,
            CommentForm,
        },

        props: {
            comments: {
                type: Array,
                default: () => [],
            },
            child: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            commentsFilter: {
                type: Number,
                default: CommentType.STRING,
            },
            depth: {
                type: Number,
                required: true,
            },
            leadId: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                active: false,
                adding: false,
                editing: false,
                CommentType,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'activeUsers']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            maxDepth() {
                return this.xsLayout ? 1 : 3;
            },

            isNoteFilter() {
                return this.commentsFilter === CommentType.STRING;
            },

            isAttachmentFilter() {
                return this.commentsFilter === CommentType.ATTACHMENT;
            },

            isCaseFilter() {
                return this.commentsFilter === CommentType.CASE;
            },

            isAudioFilter() {
                return this.commentsFilter === CommentType.AUDIO;
            },

            caseEnabled() {
                return this.contextAccount.isPolestar();
            },
        },

        methods: {
            updateFilter(type) {
                this.$emit('update:commentsFilter', type);
            },

            getProfilePicture(comment) {
                return get(comment, 'user.profile_picture', 'img/nobody.jpg');
            },
        },
    };
</script>
