import accountGuestGroup from './accountGuestGroup.js';
import accounts from './accounts.js';
import activixExp from './activixExp.js';
import authUser from './authUser.js';
import automationJobs from './automationJobs.js';
import automations from './automations.js';
import calendarViews from './calendarViews.js';
import comments from './comments.js';
import customers from './customers.js';
import dashboard from './dashboard.js';
import flows from './flows.js';
import groups from './groups.js';
import leads from './leads.js';
import leadVehicles from './leadVehicles.js';
import leadTrash from './leadTrash.js';
import notifications from './notifications.js';
import taskEvents from './taskEvents.js';
import users from './users.js';
import stock from './stock.js';
import validatedEmails from './validatedEmails.js';
import niotextTemplates from './niotextTemplates.js';

export default {
    accountGuestGroup,
    accounts,
    activixExp,
    authUser,
    automationJobs,
    automations,
    calendarViews,
    comments,
    customers,
    dashboard,
    flows,
    groups,
    leads,
    leadVehicles,
    leadTrash,
    notifications,
    taskEvents,
    users,
    stock,
    validatedEmails,
    niotextTemplates,
};
