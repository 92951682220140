<template>
    <div class="h-full">
        <activix-comment
            :class="classes"
            :comments="formatedComments"
            :depth="0"
            :comments-filter.sync="commentsFilter"
            :loading="$wait.is('fetching.comments')"
            :lead-id="leadId"
        />
        <el-pagination
            class="mt-6"
            layout="total, ->, prev, pager, next"
            :background="true"
            :current-page.sync="pagination.currentPage"
            :page-size="pagination.perPage"
            :total="pagination.total"
            v-if="!isSinglePage"
        />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    import Comment from '@/entities/Comment.js';
    import CommentType from '../../entities/CommentType.js';

    import ActivixComment from './ActivixComment.vue';

    export default {
        name: 'Comment',

        components: {
            ActivixComment,
        },

        props: {
            classes: {
                type: String,
                default: '',
            },
            leadId: {
                type: Number,
                default: null,
            },
            showLoading: {
                type: Boolean,
                default: true,
            },
        },

        provide() {
            return {
                commentAdded: this.commentAdded,
                commentUpdated: this.commentUpdated,
                commentDeleted: this.commentDeleted,
            };
        },

        data() {
            return {
                commentsFilter: CommentType.STRING,
                comments: [],
                pagination: {
                    currentPage: 1,
                    perPage: 25,
                    total: 0,
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'activeUsers']),

            formatedComments() {
                return this.comments
                    .map(comment => {
                        return new Comment({
                            ...comment,
                            children: this.getChildren(comment),
                        });
                    });
            },

            isSinglePage() {
                return this.pagination.total <= this.pagination.perPage;
            },
        },

        watch: {
            leadId: {
                immediate: true,
                handler() {
                    this.loadComments();
                },
            },

            'pagination.currentPage'(newPage, oldPage) {
                if (newPage === oldPage) {
                    return;
                }

                this.fetchCommentPage();
            },

            commentsFilter(newValue) {
                this.setCommentFilter(newValue);
                this.resetPagination();
                this.fetchCommentPage(true);
            },

            activeUsers(newValue, oldValue) {
                if (empty(newValue) && empty(oldValue)) {
                    return;
                }

                this.loadComments();
            },
        },

        methods: {
            commentAdded(isChild = false) {
                if (this.pagination.currentPage === 1 || isChild) {
                    this.fetchCommentPage();
                    return;
                }

                this.pagination.currentPage = 1;
            },

            commentUpdated(updatedComment) {
                const index = this.comments.findIndex(c => c.id == updatedComment.id);
                const comment = this.comments.find(c => c.id == updatedComment.id);

                this.comments.splice(index, 1, { ...comment, ...updatedComment });

                this.fetchCommentPage();
            },

            commentDeleted(id) {
                const index = this.comments.findIndex(c => c.id == id);

                if (index !== -1) {
                    this.comments.splice(index, 1);
                }

                this.fetchCommentPage();
            },

            getChildren(parent) {
                return (parent.children || []).map(child => {
                    return {
                        ...child,
                        children: this.getChildren(child),
                        parent_full_name: parent.fullname,
                    };
                });
            },

            async fetchCommentPage(refresh = false) {
                if (!this.leadId) {
                    return;
                }

                if (refresh) {
                    this.$wait.start('fetching.comments');
                }

                const skip = (this.pagination.currentPage - 1) * this.pagination.perPage;

                const response = await this.$axios.get(`v1/comments/${this.leadId}`, {
                    params: {
                        skip,
                        take: this.pagination.perPage,
                        filter: this.commentsFilter || null,
                    },
                });

                this.comments = Object.values(response.data.comments).map(comment => {
                    return { ...comment };
                });

                this.pagination.total = response.data.total;

                if (refresh) {
                    this.$wait.end('fetching.comments');
                }

                this.$wait.end('creating.comment');
            },

            async loadComments() {
                if (!this.leadId) {
                    return;
                }

                await this.$nextTick();

                if (this.showLoading) {
                    this.$wait.start('fetching.comments');
                }

                await this.fetchCommentPage();

                this.$wait.end('fetching.comments');
            },

            setCommentFilter(filter) {
                this.$ls.set('commentsFilter', filter);
            },

            resetPagination() {
                this.pagination.currentPage = 1;
            },

            getCommentFilter() {
                let filterTypeId = this.$ls.get('commentsFilter', null);

                if (CommentType.exists(filterTypeId)) {
                    this.commentsFilter = filterTypeId;

                    return;
                }

                switch (filterTypeId) {
                    case 'all': filterTypeId = null; break;
                    case 'attachment': filterTypeId = CommentType.ATTACHMENT; break;
                    case 'audio': filterTypeId = CommentType.AUDIO; break;
                    case 'comment': filterTypeId = CommentType.STRING; break;
                }

                this.commentsFilter = filterTypeId;
            },
        },

        mounted() {
            this.$eventBus.$on('update-comment', this.loadComments);
            this.getCommentFilter();
        },

        beforeDestroy() {
            this.$eventBus.$off('update-comment', this.loadComments);
        },
    };
</script>
