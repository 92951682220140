import DashboardType from '../../../entities/DashboardType.js';
import DashboardView from '../../../entities/DashboardView.js';
import ActivixDate from '../../../value-objects/ActivixDate.js';
import { useContextStore } from '../context/store.js';
import { useGlobalStore } from '../../store.js';

export default {
    division() {
        const dashboardType = this.dashboardType;
        let tmpDivision;

        if (empty(dashboardType)) {
            return [];
        }

        if (dashboardType == 'service') {
            return ['service'];
        }

        if (!empty(this.configs.filters)) {
            tmpDivision = this.configs.filters['division:name'];
        }

        if (empty(tmpDivision)) {
            return [];
        }

        return tmpDivision;
    },

    dashboardDisabledForGroups() {
        const groupIsSelected = !!useContextStore().group.id;
        const parentAccountIsSelected = useContextStore().account.account_manager;

        if (!groupIsSelected && !parentAccountIsSelected) {
            return false;
        }

        if ([DashboardType.SALE, DashboardType.EVENT].includes(this.dashboardType)) {
            return true;
        }

        if ([DashboardType.ACTIVITY].includes(this.dashboardType) && groupIsSelected) {
            return true;
        }

        return false;
    },

    dashboardView() {
        if (
            this.selectedDashboardView &&
            this.selectedDashboardView.dashboard == this.dashboardType &&
            this.selectedDashboardView.user_id == useGlobalStore().parentAuthUser.id
        ) {
            if (this.notificationDate) {
                this.selectedDashboardView.filter.renewal_alert_date = new ActivixDate(
                    this.notificationDate,
                ).toHumanShort(false);
            }

            return new DashboardView(this.selectedDashboardView);
        }

        return new DashboardView();
    },

    dashboardCriteria() {
        return {
            accountId: useContextStore().accountId,
            dashboard: this.dashboardType,
            divisions: this.division,
            startDate: new ActivixDate(this.startDate).startOfDay().toDateTimeString(),
            endDate: new ActivixDate(this.endDate).endOfDay().toDateTimeString(),
            filteredCampaigns: this.filteredCampaigns,
            filteredDates: this.filteredDates,
            filters: this.configs.filters,
            groupId: useContextStore().groupId || undefined,
            options: this.configs.options,
            orderBy: this.pagination.orderBy,
            sortBy: this.pagination.sortBy,
            userId: useContextStore().userId || undefined,
            teamId: useContextStore().teamId || undefined,
        };
    },

    isMassMailing() {
        if (this.allLeadsInTableSelected) {
            return true;
        }

        return this.bulkSelect.filter(lead => !!lead.emails.length).length > 1;
    },
};
