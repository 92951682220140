<template>
    <div class="font-semibold items-center justify-center" :style="style">
        <span v-if="!isImage">{{ userInitial }}</span>
    </div>
</template>

<script>
    import colors from '../../../tailwind.colors.js';

    export default {
        name: 'ActivixAvatar',

        props: {
            username: {
                type: String,
            },
            initials: {
                type: String,
            },
            initialsLength: {
                type: Number,
                default: 2,
            },
            backgroundColor: {
                type: String,
                default: colors['grey-200'],
            },
            color: {
                type: String,
                default: colors['grey-600'],
            },
            inline: {
                type: Boolean,
            },
            size: {
                type: Number,
                default: 50,
            },
            src: {
                type: String,
            },
        },

        computed: {
            isImage() {
                return !!this.src;
            },

            style() {
                const imgBackgroundAndFontStyle = {
                    background: `transparent url('${this.src}') no-repeat scroll 0% 0% / ${this.size}px ${this.size}px content-box border-box`,
                };

                const initialBackgroundAndFontStyle = {
                    backgroundColor: this.backgroundColor,
                    fontSize: `${Math.floor(this.size / 2)}px`,
                    color: this.color,
                };

                const backgroundAndFontStyle = this.isImage ? imgBackgroundAndFontStyle : initialBackgroundAndFontStyle;

                return {
                    ...{
                        display: this.inline ? 'inline-flex' : 'flex',
                        width: `${this.size}px`,
                        height: `${this.size}px`,
                    },
                    ...backgroundAndFontStyle,
                };
            },

            userInitial() {
                if (this.isImage) {
                    return '';
                }

                return this.initials || this.initial(this.username) || '?';
            },
        },

        methods: {
            initial(username) {
                if (!username) {
                    return '';
                }

                const parts = username.split(/[ -]/);
                let initials = '';

                for (let i = 0; i < parts.length; i++) {
                    initials += parts[i].charAt(0);
                }

                if (initials.length > this.initialsLength && initials.search(/[A-Z]/) !== -1) {
                    initials = initials.replace(/[a-z]+/g, '');
                }

                return initials.substr(0, this.initialsLength).toUpperCase();
            },
        },
    };
</script>
