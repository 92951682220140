<template>
    <transition
        enter-class="-translate-x-full"
        enter-to-class="translate-x-0"
        leave-class="translate-x-0"
        leave-to-class="-translate-x-full"
    >
        <div
            class="z-40 fixed inset-y-0 w-64 flex flex-col bg-gradient-to-r from-gray-750 to-gray-700 shadow-r-lg | transition-all duration-300 transform | print:hidden"
            :style="{ paddingTop: `${bodySpacingTop}px` }"
            v-on-clickaway="onClickaway"
            v-if="opened"
        >
            <div class="relative flex items-center justify-center shadow-lg h-18">
                <div class="changelog-selector | absolute top-0 right-0 mt-2 mr-3">
                    <transition name="fade">
                        <div class="absolute top-0 right-0 h-3 w-3 mt-1.5 mr-1.5 bg-gray-300 rounded-full" v-if="!hasBeamerNotifications" />
                    </transition>
                </div>
                <router-link class="p-8" to="/">
                    <img class="h-auto w-full" src="../assets/img/logo_activix_crm.png" alt="Logo" />
                </router-link>
            </div>

            <vue-scroll class="flex-1 flex flex-col">
                <slot>
                    <main-nav />
                </slot>
            </vue-scroll>
        </div>
    </transition>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useLayoutStore } from '../store/modules/layout/store.js';
    import MainNav from './nav/MainNav.vue';

    export default {
        components: {
            MainNav,
        },

        computed: {
            ...mapState(useLayoutStore, {
                opened: 'navOpened',
                bodySpacingTop: 'bodySpacingTop',
            }),

            shouldHide() {
                return this.lgLayout || !this.includeInLayout('nav');
            },

            hasBeamerNotifications() {
                return this.$beamer.hasNotifications;
            },
        },

        watch: {
            '$route.name'() {
                this.onClickaway();
            },

            '$i18n.locale'(locale) {
                this.$beamer.changeLocale(locale);
            },

            shouldHide: {
                immediate: true,
                handler(shouldHide) {
                    if (shouldHide) {
                        this.setNavOpened(false);
                        return;
                    }

                    this.setNavOpened(this.$ls.get('navOpened', true));
                },
            },
        },

        methods: {
            ...mapActions(useLayoutStore, ['setNavOpened']),

            onClickaway(event) {
                if (this.lgLayout && this.opened) {
                    const sidebarToggle = document.querySelector('.sidebar-toggle');

                    if (
                        !event ||
                        (!event.target.classList.contains('sidebar-toggle') && !sidebarToggle.contains(event.target))
                    ) {
                        this.setNavOpened(false);
                    }
                }
            },

            hide() {
                if (this.shouldHide) {
                    return;
                }

                const navOpened = this.$ls.get('navOpened', true);

                if (navOpened) {
                    this.setNavOpened(false);
                }
            },

            show() {
                if (this.shouldHide) {
                    return;
                }

                const navOpened = this.$ls.get('navOpened', true);

                if (navOpened) {
                    this.setNavOpened(true);
                }
            },

            toggle() {
                if (this.shouldHide) {
                    this.setNavOpened(!this.opened);
                    return;
                }

                const newState = !this.opened;

                this.setNavOpened(newState);
                this.$ls.set('navOpened', newState);
            },
        },

        created() {
            this.$eventBus.$on('show-nav', this.show);
            this.$eventBus.$on('hide-nav', this.hide);
            this.$eventBus.$on('toggle-nav', this.toggle);

            // @TODO delete after 2022-08-01
            const oldState = this.$ls.get('sidebarVisible', null);
            if (oldState !== null) {
                this.$ls.set('navOpened', oldState);
                this.$ls.remove('sidebarVisible');
            }

            if (!this.$ls.get('navOpened', true)) {
                this.setNavOpened(false);
            }
        },

        async mounted() {
            await this.$nextTick();

            this.$beamer.init(this.$auth.user());
        },

        beforeDestroy() {
            this.$eventBus.$off('show-nav', this.show);
            this.$eventBus.$off('hide-nav', this.hide);
            this.$eventBus.$off('toggle-nav', this.toggle);
        },
    };
</script>
