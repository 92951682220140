export default {
    alerts: {
        show: {
            error: 'Échec de la récupération du tour de rôle',
        },
        update: {
            error: 'Échec de la mise à jour du tour de rôle',
            success: 'Tour de rôle mis à jour avec succès',
        },
        destroy: {
            error: 'Échec de la suppression du conseiller dans le tour de rôle',
        },
        gunmiss: {
            error: 'Échec the la mise à jour du Gun miss pour le conseiller',
        },
    },
    modals: {
        update: {
            title: '',
        },
        delete: {
            title: '',
        },
    },
    appointmentCounter: 'Nombre de rencontres par jour',
    break_date: 'Pause',
    delivery_date: 'Livraison',
    deskManagerAdvisor: 'Desk manager',
    deskManagerVehicle: 'Desk manager',
    financialAdvisor: 'Directeurs commerciaux',
    financialVehicle: 'Directeurs commerciaux',
    gunMiss: 'Gun miss',
    inBreak: 'En pause',
    inDelivery: 'En livraison',
    inMeeting: 'En rencontre',
    inPlannedMeeting: 'En rencontre planifiée',
    meeting_date: 'Rencontre',
    message_over_ten: 'Un maximum de 10 utilisateurs seront visibles dans la présentation du tour de rôle',
    newAdvisor: 'Conseiller neuf',
    newVehicle: 'Véhicule neuf',
    noDeskManager: 'Aucun desk manager disponible',
    noDirector: 'Aucun directeur disponible',
    noPlannedMeeting: 'Aucun rendez-vous planifié au calendrier dans les 4 prochaines heures',
    noUser: 'Aucun conseiller disponible',
    onlyDirectorCanEdit:
        'Selon les options de votre compte, seuls les directeurs peuvent changer les statuts (en rencontre, livraison, pause)',
    relevantLeads: 'Voici vos clients du jour',
    resetGunMiss: 'Remettre à zéro le gun miss',
    usedAdvisor: "Conseiller d'occasion",
    usedVehicle: "Véhicule d'occasion",
};
