export default {
    advancedStats: {
        boxTitles: {
            phoneUp: 'Phone-up',
            renewal: '@.capitalize:lexicon.renewal.singular',
            total: 'Total des activités',
            walkIn: '@.capitalize:lexicon.walkIn.singular non @:lexicon.sold.singular',
            web: 'Lead Web',
        },
        objectiveTooltip: 'Vos objectifs sont calculés au prorata du nombre de jours sélectionnés',
    },
    boxTitles: {
        resultsByAdvisor: 'Résultats par conseiller',
        resultsByAgent: 'Résultats par agent',
    },
    objective: 'Objectif',
    objectives: {
        agent: 'Agent',
    },
    ratio: 'Ratio',
    resultsByUser: {
        advisors: 'Conseillers',
        agents: 'Agents',
        noResults: 'Aucun résultat',
        outgoingTextMessagesTooltip: 'Le calcul est basé sur chaque série de SMS envoyés à toutes les 2h',
        total: 'Total',
    },
    stats: {
        activities: 'Activités',
        appointments: 'Rendez-vous | Rendez-vous',
        beBacks: 'B-back | B-backs',
        calls: 'Appels',
        deliveries: 'Livraison | Livraisons',
        emails: 'Courriels',
        incoming: 'Entrant',
        incomingActivities: 'Activités entrantes',
        incomingCalls: 'Phone-up',
        incomingCommunications: 'Communication entrante | Communications entrantes',
        incomingEmails: 'Courriel entrant | Courriels entrants',
        incomingTextMessages: 'SMS entrant | SMS entrants',
        leads: 'Leads',
        leadsWorked: 'Lead travaillé | Leads travaillés',
        outgoing: 'Sortant',
        outgoingActivities: 'Activités sortantes',
        outgoingCalls: 'Appel sortant | Appels sortants',
        outgoingCommunications: 'Communication sortante | Communications sortantes',
        outgoingEmails: 'Courriel sortant | Courriels sortants',
        outgoingEmailsAndTextMessages: 'Courriel & SMS sortant | Courriels & SMS sortants',
        outgoingTextMessages: 'SMS sortant | SMS sortants',
        performances: 'Performances',
        sales: '@.capitalize:lexicon.sale.singular | @.capitalize:lexicon.sale.plural',
        textMessages: 'SMS',
        visits: 'Visite | Visites',
        worked: 'Travaillés',
    },
    total: 'Total',
    variation: 'Variation',
};
