import { getFullName } from '@/utils';
import CommunicationMethod from '@/entities/CommunicationMethod.js';

export default {
    methods: {
        getLeadCommunicatingMessage(communicatingLead) {
            const userName = getFullName(communicatingLead?.communicating_user);

            return {
                [CommunicationMethod.PHONE]: this.$t('screenPop.leadCommunicating.phone.description', { userName }),
                [CommunicationMethod.SMS]: this.$t('screenPop.leadCommunicating.sms.description', { userName }),
                [CommunicationMethod.VIDEO]: this.$t('screenPop.leadCommunicating.video.description', { userName }),
                [CommunicationMethod.EMAIL]: this.$t('screenPop.leadCommunicating.email.description', { userName }),
            }[communicatingLead?.communication_method_id] || '';
        },

        getLeadCommunicatingIcon(communicatingLead) {
            return {
                [CommunicationMethod.PHONE]: 'regular/phone',
                [CommunicationMethod.SMS]: 'regular/messages-bubble-typing-1',
                [CommunicationMethod.VIDEO]: 'regular/meeting-camera',
                [CommunicationMethod.EMAIL]: 'regular/email-action-unread',
            }[communicatingLead?.communication_method_id] || '';
        },
    },
};
