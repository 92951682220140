import { clone } from 'lodash-es';
import Model from './Model.js';
import DashboardType from './DashboardType.js';

export default class Session extends Model {
    static get defaults() {
        return {
            dashboard_column: Session.dashboardTypes(),
            dashboard_dates: Session.dashboardTypes([]),
            dashboard_expand: Session.dashboardTypes(),
            dashboard_filter: Session.dashboardTypes(),
            dashboard_option: Session.dashboardTypes(),
            order: Session.dashboardTypes(),
        };
    }

    static dashboardTypes(value = {}) {
        return DashboardType.values.reduce((result, type) => {
            result[type] = clone(value);
            return result;
        }, {});
    }
}
