export default {
    minutes: 'minute | minutes',
    hours: 'heure | heures',
    days: 'jours | jours',
    weeks: 'semaine | semaines',
    months: 'mois | mois',
    years: 'année | années',

    abbreviations: {
        seconds: 's',
        minutes: 'm',
        hours: 'h',
        days: 'j',
    },
};
